import React, { FC, useEffect } from 'react'
import { ReactSelectStyles } from '../../modules/global/ReactSelectStyle';
import Select from 'react-select';
import { useQuery } from 'react-query';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URLL
const TAGS_URL = `${API_URL}`

type Props = {
    projectId: any
    setAgingTasks:(p:any)=>void

}
const TaskRange: FC<Props> = ({ projectId,setAgingTasks }) => {
    const [companyId, setCompanyId] = React.useState<any>()
    const [query, setQuery] = React.useState<any>(null)
    useEffect(() => {
        if (companyId) {
            setQuery(`companyId=${companyId}`)
        } else {
            setQuery('')
        }
    }, [companyId])

    const { data: tasks, refetch: OpenTaskAgingSummary } = useQuery(
        `OpenTaskAgingSummary`,
        async () => {
            return await axios.get(`${TAGS_URL}/Dashboard/OpenTaskAgingSummary/${projectId && projectId}?${query}`)
        },
        {
            refetchOnWindowFocus: false,
        }
    )
    useEffect(() => {
        if (tasks !== null) {
            setAgingTasks(tasks?.data)
        }
    }, [tasks])
    useEffect(() => {
        if (query !== null) {
            OpenTaskAgingSummary()
        }
    }, [query])
    useEffect(() => {
        setCompanyId(null)
    }, [projectId])



    const handleBageColor = (timeRange: any) => {
        if (timeRange === '0-7') {
            return 'success'
        } else if (timeRange === '8-14') {
            return 'light-success'
        } else if (timeRange === '15-30') {
            return 'warning'
        } else if (timeRange === '31-60') {
            return 'light-warning'
        }
        else if (timeRange === '61-90') {
            return 'info'
        } else if (timeRange === '91-120') {
            return 'light-info'
        }
        else if (timeRange === '121+') {
            return 'danger'
        }
    }

    const { data: companies, refetch: GetCompanies } = useQuery(
        `GetCompanies`,
        async () => {
            return await axios.get(`${TAGS_URL}/Dashboard/GetCompanies/${projectId && projectId}`)
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        if (projectId) {
            GetCompanies()
        }
    }, [projectId])

    const companiesOptions = companies?.data?.data?.companies?.map((project: any) => ({ value: project.id, label: project.name }))

    return (
        <div className='card card-flush h-xl-100 p-3'>
            <div className='card-header p-0 px-2'>
                <h3 className='card-title'>
                    <span className='card-label fw-bold fs-3 mb-1'>Aging Tasks</span>
                </h3>

            </div>
            <div className='card-body p-3'>
                <div className='col-12 mb-5'>
                    <Select
                        isClearable={true}
                        options={companiesOptions}
                        value={
                            companyId ? companiesOptions?.find((obj: any) => obj.value === companyId) : null
                        }
                        onChange={(e: any) => setCompanyId(e?.value)}
                        styles={ReactSelectStyles}
                        placeholder='All Companies'
                    />
                </div>
                <div className='d-flex mb-5'>
                    <span className="fs-8 fw-bold col-6 me-2">Task Age</span>
                    <span className="fs-8 fw-bold col-6">Number Of Tasks</span>
                </div>
                {
                    tasks?.data?.map((task: any) => {
                        return (
                            <div className='d-flex justify-content-between mb-5 '>
                                <span className={`badge badge-${handleBageColor(task?.timeRange)} fs-8 fw-bold col-6 me-2 justify-content-center`}>{task?.timeRange}</span>
                                <span className="badge badge-secondary fs-8 fw-bold col-6 justify-content-center">{task.taskCount}</span>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    )
}

export default TaskRange