import React, { FC } from 'react'

type Props = {
    setIsOpen: (isOpen: boolean) => void
    data: any
}
const BillingAdrress: FC<Props> = ({ setIsOpen, data }) => {
    return (
        <div className="card  mb-5 mb-xl-10 card-flush h-xl-100">
            <div className="card-header">
                <div className="card-title">
                    <h3>Billing Address</h3>
                </div>
            </div>
            <div className="card-body">
                <div className="row gx-9 gy-6">
                    <div className="col-12">
                        <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                            <div className="d-flex flex-column py-2">
                                <div className="d-flex align-items-center fs-5 fw-bold mb-5">
                                    Address 1
                                    <span className="badge badge-light-success fs-7 ms-2">
                                        Primary
                                    </span>
                                </div>
                                <div className="fs-6 fw-semibold text-gray-600">
                                    {data?.subscription?.addressLine1}
                                    <br />
                                    {data?.subscription?.addressLine2 && data?.subscription?.addressLine2}
                                    {data?.subscription?.addressLine2 && <br />}
                                    {data?.subscription?.addressCity} {data?.subscription?.addressState} {data?.subscription?.addressZip}
                                    <br />
                                    {data?.subscription?.addressCountry}
                                </div>
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <button
                                    className="btn btn-sm btn-light btn-active-light-primary"
                                    onClick={() => {
                                        setIsOpen(true)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BillingAdrress