import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import {FC} from 'react'
import { BillingModal } from '../Billing/billing-edit-modal/BillingModal'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const STRIPE_PROMISE = process.env.REACT_APP_STRIPE_PROMISE
const stripePromise = loadStripe(STRIPE_PROMISE as string)

type Props = {
  handleSubmit: () => void
  setCrdToken: (cardToken: string) => void
  formik: any
  setCardNumber: (cardNumber: any) => void
}
const Payment: FC<Props> = ({handleSubmit, setCrdToken ,formik , setCardNumber}) => {

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm handleSubmit2={handleSubmit} setCrdToken={setCrdToken} formik={formik} setCardNumber={setCardNumber}/>
        </Elements>
      )}
    </>
  )
}
export default Payment
