import { useQueryClient, useMutation } from 'react-query'
import { KTIcon, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteSelectedUsers } from '../../core/_requests'
import Swal from 'sweetalert2'
import { handleApiErrors } from '../../../../../global/HandleApiErrors'
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react'
import axios from 'axios'
import { DueDateModal } from '../../DueDateModal/EmailModal'
import { TagsModal } from '../../TagsModal/TagsModal'
import { LocationModal } from '../../LocationModal/LocationModal'
import { useParams } from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URLL


const TeamsListGrouping = () => {
  const params = useParams<any>()
  const { id } = params
  const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles
  const { selected, clearSelected } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const [isTagsOpen, SetIsTagsOpen] = useState<boolean>(false)
  const [type, setType] = useState('')
  const [isDueDateOpen, setIsDueDateOpen] = useState(false)
  const [isLocationOpen, isetIsLocationOpen] = useState(false)
  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
      clearSelected()
    },
    onError: (error: any) => {
      console.log('error', error.response.data)
      handleApiErrors(error.response.data)
    },
  })

  const handleChangeStatus = async (data: any) => {
    try {
      await axios.put(`${API_URL}/Tasks/BulkChangeStatus`, data)
        .then((res) => {
          queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
          clearSelected()
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Status has been changed successfully.',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
        })
    } catch (error: any) {
      handleApiErrors(error.response.data)
    }
  }
  const handleDueDate = async (data: any) => {
    try {
      await axios.put(`${API_URL}/Tasks/BulkDueDate`, data)
        .then((res) => {
          queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
          clearSelected()
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Due Date has been changed successfully.',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
        })
    } catch (error: any) {
      handleApiErrors(error.response.data)
    }
  }

  return (
    <div className='d-flex justify-content-start align-items-center flex-wrap px-5'>
      {(role[0] === "Inspector Admin" || role[0] === "Inspector") && <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>}

      <Dropdown className='mb-4'>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='me-3'>
          Change Status
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: `You want to change status of selected tasks to Draft!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, change it!`
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleChangeStatus({ actionId: 6, Ids: selected })
                }
              })
            }}
            href="#/action-1">
            <i className="fa-regular fa-circle-dot me-3 text-warning"></i>To Draft
          </Dropdown.Item>
          <Dropdown.Item
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: `You want to change status of selected tasks to Open!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, change it!`
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleChangeStatus({ actionId: 2, Ids: selected })
                }
              })
            }}
            href="#/action-2">
            <i className="fa-regular fa-circle-dot me-3 text-sencodary"></i> To Open
          </Dropdown.Item>
          <Dropdown.Item
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: `You want to change status of selected tasks to Completed!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, change it!`
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleChangeStatus({ actionId: 4, Ids: selected })
                }
              })
            }}
            href="#/action-3">
            <i className="fa-regular fa-circle-dot me-3 text-success"></i>To Completed
          </Dropdown.Item>
          <Dropdown.Item
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: `You want to change status of selected tasks to Closed!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, change it!`
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleChangeStatus({ actionId: 5, Ids: selected })
                }
              })
            }}
            href="#/action-3">
            <i className="fa-regular fa-circle-dot me-3 text-danger"></i> To Closed
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className='mb-4'>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='me-3'>
          Change Due Date
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: `You want to remove due date to the selected tasks!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, remove it!`
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleDueDate({ dueDate: null, Ids: selected })
                }
              })
            }}
            href="#/action-1">
            <i className="fa-sharp fa-solid fa-xmark me-3"></i>Remove Due Date
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setIsDueDateOpen(true)}
            href="#/action-2">
            <KTIcon iconName='calendar-add' className='me-3' />
            Update Due Date
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {id && <Dropdown className='mb-4'>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='me-3'>
          Change Tags
        </Dropdown.Toggle>

         <Dropdown.Menu>
          <Dropdown.Item
            onClick={async () => {
              SetIsTagsOpen(true)
              setType('remove')
            }}
            href="#/action-1">
            <i className="fa-sharp fa-solid fa-xmark me-3"></i>Remove Tags
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              SetIsTagsOpen(true)
              setType('')
            }}
            href="#/action-2">
            <i className="fa-sharp fa-solid fa-plus me-3"></i> Add Tags
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>}

      {id &&
        <button
          type='button'
          className='btn btn-secondary me-3 mb-4'
          onClick={async () => {
            isetIsLocationOpen(true)
          }}
        >
          Change Location
        </button>
      }

      {(role[0] === "Inspector Admin" || role[0] === "Inspector") && <button
        type='button'
        className='btn btn-danger me-3 mb-4'
        onClick={async () => {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
              await deleteSelectedItems.mutateAsync().then(() => {
                Swal.fire({
                  text: 'Tasks has been duplicated.',
                  icon: 'success',
                  showConfirmButton: true,
                  timer: 2000,
                  width: '25rem',
                })
              })
            }
          })
        }}
      >
        Delete selected
      </button>}

      {isDueDateOpen &&
        <DueDateModal
          clearSelected={clearSelected}
          setIsDueDateOpen={setIsDueDateOpen}
          selected={selected} />}

      {isLocationOpen &&
        <LocationModal
          clearSelected={clearSelected}
          setIsDueDateOpen={isetIsLocationOpen}
          selected={selected} />}

      {isTagsOpen &&
        <TagsModal
          type={type}
          clearSelected={clearSelected}
          SetIsTagsOpen={SetIsTagsOpen}
          selected={selected} />}
    </div>
  )
}

export { TeamsListGrouping }
