import {FC, useMemo} from 'react'
import {ID, KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
const cuurentUser: any = localStorage.getItem('User')
const role: any = JSON.parse(cuurentUser)?.roles
type Props = {
  id: ID
}

const TaskSelectionCell: FC<Props> = ({id}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  return (
    <>{role[0] === 'Contractor' || role[0] === 'Contractor Admin' ? "" :  <div className='form-check form-check-custom form-check-solid'>
  <input
      className='form-check-input'
      type='checkbox'
      data-kt-check={isSelected}
      data-kt-check-target='#kt_table_users .form-check-input'
      checked={isSelected}
      onChange={() => onSelect(id)}
    />
  </div>}</>
  
  )
}

export {TaskSelectionCell}
