/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
import GuidedTour from '../global/IntroDriver/IntroDriver';

interface Model {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  address1: string,
  address2: string,
  city: string,
  country: string,
  profilePicture: null,
  role: string,
  site: string,
  state: string,
  zipCode: string,
}
interface SettingsProps {
  profile: Model | undefined;
}
const ProfileHeader = (props: SettingsProps) => {
  const location = useLocation()

  useEffect(() => {
    console.log(props.profile)
  }, [])
  const AUTH_LOCAL_STORAGE_KEY = 'User'
  const authData = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
  const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles
  return (
    <div className='card mb-5 mb-xl-10'>
      <GuidedTour/>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>

              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{ backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)' }}
              >
                {props.profile?.profilePicture ? (

                  <img alt='Logo' className='image-input-wrapper w-150px h-150px' src={toAbsoluteUrl(`${props.profile?.profilePicture}`)} />

                ) : (<div
                  className='image-input-wrapper w-150px h-150px'
                  style={{ backgroundImage: 'url(/assets/media/avatars/300-1.jpg)' }}
                />
                )}
              </div>
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {props.profile?.firstName} {props.profile?.lastName}
                  </a>
                
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {props.profile?.companyName}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {props.profile?.country}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {authData.username}
                  </a>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>

                  {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>60</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Completed</div>
                  </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='d-flex  h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li  className='nav-item'>
              <Link
              id="Overview"
                className={
                  `nav-link text-active-primary text-hover-primary me-6 ` +
                  (location.pathname === '/profile/overview' && 'active')
                }
                to='overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
              id="BasicInformation" 
                className={
                  `nav-link text-active-primary text-hover-primary me-6 ` +
                  (location.pathname === '/profile/settings' && 'active')
                }
                to='settings'
              >
                Basic Information
              </Link>
            </li>
            <li  className='nav-item'>
              <Link
              id="PasswordSecurity"
                className={
                  `nav-link text-active-primary text-hover-primary me-6 ` +
                  (location.pathname === '/profile/password-and-security' && 'active')
                }
                to='password-and-security'
              >
                Password & Security
              </Link>
            </li>
            {role[0] === 'Inspector Admin' && <li className='nav-item'>
              <Link
              id="billing"
                className={
                  `nav-link text-active-primary text-hover-primary me-6 ` +
                  (location.pathname === '/profile/billing' && 'active')
                }
                to='billing'
              >
                Billing
              </Link>
            </li>}

          </ul>
        </div>
      </div>
    </div>
  )
}

export { ProfileHeader }
