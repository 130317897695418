/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'

type Props = {
  user: any
}
const TaskInfoName: FC<Props> = ({ user }) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a href='#' className='text-primary-600 text-hover-primary mb-1' onClick={() => {
          (window as any).taskId = user.id;
          var element = document.getElementById("taskiD");
          if (element) {
            element.click();
          }
        }}>
          {
            user.name
          }
        </a>
      </div>
    </div>
  )
}

export default TaskInfoName