import {useEffect, useRef, useState} from 'react'
import Swal from 'sweetalert2'
//import { ListTwo } from '../List/ListTwo'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import axios from 'axios'
// import {ModelStateMessage} from '../../../global'
import {handleApiErrors} from '../../../global/HandleApiErrors'
import {Carousel} from 'react-responsive-carousel'
import ImageGallery from 'react-image-gallery'
import 'react-photo-feed/library/style.css'
import {Document, Page, pdfjs} from 'react-pdf'
import 'react-image-gallery/styles/css/image-gallery.css'
import MyComponent from './PDF'
import {ProgressBar} from 'react-bootstrap'
import ModalImage from 'react-modal-image'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const AUTH_LOCAL_STORAGE_KEY = 'User'
const BASE_URL = process.env.REACT_APP_BASE_URL
interface Location {
  location: any
  buildingPlan: any
  id: number
  locations: Array<any>
  name: any
  parent: boolean
}
interface LocationDetails {
  location: any
  buildingPlan: any
  id: number
  name: any
}
interface Picture {
  src: string
  thumbnail: string
  thumbnailWidth: number
  thumbnailHeight: number
  isSelected: boolean
  caption: string
}
const Step2 = (props: any) => {
  const [image, setImage] = useState<any>('')
  const [location, setLocation] = useState<Location>()
  const [loading, setLoading] = useState(false)
  const [Idloading, setIdLoading] = useState<number | null>()
  const [editshow, seteditShow] = useState(false)
  const [edittitleshow, setedittitleshow] = useState(false)
  const [IdParent, setIdParent] = useState<number | null>()
  const [IdChiled, setIdChiled] = useState<number | null>()
  const [title, setTitle] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const [myArray, setMyArray] = useState<any>([])
  const [newString, setNewString] = useState<string>()
  const [idloc, setidloc] = useState<number | null>()
  const [topRightModal, setTopRightModal] = useState(false)
  const [show, setShow] = useState(false)

  const toggleShow = () => setTopRightModal(!topRightModal)
  // const [imageSrc, setImageSrc] = useState<string | null>(null)
  const addToMyArray = () => {
    if (newString && idloc) {
      setMyArray((prevArray: any) => [...prevArray, {idloc, newString}])
    }
    console.log(myArray)
    setNewString('')
    setidloc(null)
  }
  const removeFromMyArray = (idToRemove: string) => {
    console.log(idToRemove)
    setMyArray((prevArray: any) => prevArray.filter((item: any) => item.idloc !== idToRemove))
  }
  const user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')
  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0]
    if (e.target.files && e.target.files.length > 0) {
      console.log()
      setSelectedFile(e.target.files[0])
    }
    if (selectedImage) {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result?.toString())
        // const dataURL = reader.result as string
        // setImageSrc(dataURL)
      }
      reader.readAsDataURL(selectedImage)
    }
  }
  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }
  const id = window.location.href && extractIdFromUrl(window.location.href)
  const handelCreateLocation = async () => {
    setLoading(true)
    const formData = new FormData()
    if (selectedFile) {
      formData.append('file', selectedFile)
    }

    if (location?.parent === true) {
      try {
        await axios
          .post(
            `${BASE_URL}/api/Projects/Locations/${id}?Name=${encodeURIComponent(title)}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setLoading(false)
              handleClose()
              Swal.fire({
                text: 'Location created successfully',
                icon: 'success',
                showConfirmButton: true,
                timer: 2000,
                width: '25rem',
              })
              Getlocations()
            }
          })
      } catch (error: any) {
        setLoading(false)
        handleApiErrors(error?.response?.data)
      }
    } else {
      try {
        await axios
          .post(
            `${BASE_URL}/api/Projects/Locations/${id}?parentId=${IdParent}&Name=${title}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setLoading(false)
              handleClose()
              Swal.fire({
                text: 'Location created successfully',
                icon: 'success',
                showConfirmButton: true,
                timer: 2000,
                width: '25rem',
              })
              Getlocations()
            }
          })
      } catch (error: any) {
        setLoading(false)
        handleApiErrors(error?.response?.data)
      }
    }
  }
  const [imageApi, setimageApi] = useState<string | null>()

  function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    var selectedImage = event.target.files?.[0]
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
    if (selectedImage) {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result?.toString())
      }
      reader.readAsDataURL(selectedImage)
    }
  }
  const removePic = () => {
    setImage(null)
    setimageApi(undefined)
    const inputElement = document.getElementById('profile_avatar') as HTMLInputElement
    if (inputElement) {
      inputElement.value = ''
    }
  }

  const handleEditeLocation = async () => {
    setLoading(true)
    if (!image) {
      try {
        await axios
          .put(
            `${BASE_URL}/api/Projects/Locations/${
              location?.location?.id
            }/?Name=${encodeURIComponent(title)}&WithFile=false`,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setLoading(false)
              seteditShow(false)
              Swal.fire({
                text: 'Location updated successfully',
                icon: 'success',
                showConfirmButton: true,
                timer: 2000,
                width: '25rem',
              })

              Getlocations()
            }
          })
      } catch (error: any) {
        setLoading(false)
        handleApiErrors(error?.response?.data)
      }
    }
    if (!selectedFile) {
      return
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    try {
      await axios
        .put(
          `${BASE_URL}/api/Projects/Locations/${location?.location?.id}/?Name=${encodeURIComponent(
            title
          )}&WithFile=true`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            seteditShow(false)
            Swal.fire({
              text: 'Location updated successfully',
              icon: 'success',
              showConfirmButton: true,
              timer: 2000,
              width: '25rem',
            })
            Getlocations()
          } else {
          }
        })
    } catch (error: any) {
      setLoading(false)
      handleApiErrors(error?.response?.data)
    }
  }
  const handleEditetitleLocation = async () => {
    setLoading(true)
    try {
      await axios
        .put(
          `${BASE_URL}/api/Projects/Locations/EditChildName/${IdChiled}?Name=${encodeURIComponent(
            title
          )}`,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false)
            setedittitleshow(false)
            setIdChiled(null)
            seteditShow(false)
            Swal.fire({
              text: 'Title updated successfully',
              icon: 'success',
              showConfirmButton: true,
              timer: 2000,
              width: '25rem',
            })

            Getlocations()
          } else {
          }
        })
    } catch (error: any) {
      setLoading(false)
      handleApiErrors(error?.response?.data)
    }
  }
  async function Deletlocation(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to delete this location!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios
            .delete(`${BASE_URL}/api/Projects/Locations/${id}`, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                Getlocations()
                Swal.fire({
                  text: 'Location deleted successfully',
                  icon: 'success',
                  showConfirmButton: true,
                  timer: 2000,
                  width: '25rem',
                })
                Getlocations()
              }
            })
        } catch (error: any) {
          setLoading(false)
          handleApiErrors(error?.response?.data)
        }
      }
    })
  }

  async function Getlocations() {
    try {
      if (IdParent) {
        const response = await axios.get(
          `${BASE_URL}/api/Projects/Locations/Details?id=${IdParent}`
        )
        if (response.status === 200) {
          setLoading(false)
          setIdLoading(null)
          if (response?.data.parent === true) {
            setIdParent(null)
          }
        }
        setLocation(response?.data)
        console.log(response?.data.parent)
        console.log(location?.parent)
      } else {
        const response = await axios.get(
          `${BASE_URL}/api/Projects/Locations/Details?projectId=${id}`
        )
        if (response.status === 200) {
          setLoading(false)
          setIdLoading(null)
          if (response?.data.parent === true) {
            setIdParent(null)
          }
          console.log(response?.data.parent)
          console.log(location?.location?.parent)
        }
        setLocation(response?.data)
      }
    } catch (error) {}
  }

  async function BackToParent() {
    try {
      if (IdParent) {
        const response = await axios.get(
          `${BASE_URL}/api/Projects/Locations/BackToParent/${location?.location?.id}`
        )
        if (response.status === 200) {
          if (response?.data.parent === true) {
            setIdParent(null)
          } else {
            setIdParent(response?.data.location.id)
          }
          setLocation(response?.data)
        }
      }
    } catch (error) {}
  }
  function extractEditFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('edit') === 'true'
  }
  function extractDetailsFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('details') === 'true'
  }
  //the first line in code of pdf
  const [numPages, setNumPages] = useState<number | null>(null)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)
  const [imageList, setImageList] = useState<{original: string; thumbnail: string}[]>([])

  // const fetchData = async () => {
  //   try {
  //     const responset = await axios.get(`${BASE_URL}/api/Projects/GetProjectDrawing/${id}`)
  //     const pdfUrl = responset.data
  //     const response = await fetch(pdfUrl);
  //     const data = await response.blob();
  //     extractImagesFromPDF(data);
  //   } catch (error) {
  //     console.error('Error while fetching the PDF:', error);
  //   }
  // };
  // const extractImagesFromPDF = async (pdfFile: Blob) => {
  //   try {
  //     const arrayBuffer = await pdfFile.arrayBuffer();
  //     const uint8Array = new Uint8Array(arrayBuffer);
  //     const pdf = await pdfjs.getDocument(uint8Array).promise;
  //     const images: { original: string; thumbnail: string }[] = [];

  //     for (let i = 1; i <= pdf.numPages; i++) {
  //       const page = await pdf.getPage(i);
  //       const viewport = page.getViewport({ scale: 1 });
  //       const canvas = document.createElement('canvas');
  //       const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  //       canvas.width = viewport.width;
  //       canvas.height = viewport.height;

  //       await page.render({ canvasContext: context, viewport }).promise;
  //       const imageUrl = canvas.toDataURL();
  //       images.push({ original: imageUrl, thumbnail: imageUrl });
  //     }

  //     setImageList(images);
  //   } catch (error) {
  //     console.error('Error while loading or extracting images from PDF:', error);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  const dataURLtoFile = (dataURL: string, filename: string): File | null => {
    const arr = dataURL.split(',')
    const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/png'
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    try {
      return new File([u8arr], filename, {type: mime})
    } catch (error) {
      console.error('Error creating File object:', error)
      return null
    }
  }

  useEffect(() => {
    console.log('in',currentSlideIndex)
    setSelectedFile(null)
    if (props.galleryImages[currentSlideIndex]?.original.length > 0) {
      const file = dataURLtoFile(
        props.galleryImages[currentSlideIndex]?.original || '',
        'image.jpg'
      )
      console.log(file)
      setSelectedFile(file)
      setSelectedImage(props.galleryImages[currentSlideIndex]?.original || null)
    }
  }, [currentSlideIndex])

  const handleSlideChange = (currentIndex: number) => {
    setImage(props.galleryImages[currentIndex]?.original)
    setCurrentSlideIndex(currentIndex)
  }

  //the end line
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)
  const isEdit = window.location.href && extractEditFromUrl(window.location.href)
  useEffect(() => {
    Getlocations()
  }, [IdParent])
  useEffect(() => {
    addToMyArray()
  }, [newString])
  useEffect(() => {
    if (!show) {
      setImage(undefined)
      setTitle('')
    }
    if (!editshow) {
      setImage(undefined)
      setTitle('')
    }
  }, [show])
  const [selectshow, setselectshow] = useState(false)

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <div className='row'>
          <div className='col-xxl-6'>
            <div className={`card card-xxl-stretch mb-5 mb-xxl-8 shadow-none`}>
              {/* begin::Header */}
              <div className='card-header border-0 px-0'>
                <h3 className='card-title fw-bold text-dark'>Locations</h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  {isDetails ? (
                    ''
                  ) : (
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      onClick={handleShow}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button>
                  )}

                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}

              {location?.parent === false ? (
                <>
                  <div className='card-body py-0 px-0'>
                    <div className='cursor-pointer fw-bold bg-light-primary rounded py-3 mb-3 d-flex align-items-center pe-2'>
                      <div className='d-flex cursor-pointer align-items-center'>
                        <span
                          onClick={() => {
                            removeFromMyArray(location?.location?.id)
                            BackToParent()
                          }}
                        >
                          <KTIcon
                            iconName='arrow-left'
                            className='fs-2 text-primary mx-3 mr-5 pt-1'
                          />
                        </span>
                        <span className='text-primary ml-15'>Back</span>
                      </div>
                      <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
                      <div
                        className='d-flex  w-100 scrol-y'
                        style={{width: '200px', overflowX: 'auto', whiteSpace: 'nowrap'}}
                      >
                        {myArray?.map((item: any, index: number) => {
                          return (
                            <>
                              {item !== '' ? (
                                <>
                                  {index === 0 ? (
                                    ''
                                  ) : (
                                    <KTIcon iconName='arrow-right' className='fs-2 text-primary' />
                                  )}

                                  <span className='text-dark'> {item.newString} </span>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        })}
                      </div>
                      {isDetails ? (
                        ''
                      ) : (
                        <span
                          className='ms-10 mb-1 cursor-pointer '
                          onClick={() => {
                            setTitle(location?.location?.name)
                            setimageApi(location?.location?.buildingPlan)
                            seteditShow(true)
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon svg-icon-primary svg-icon-3 '
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='card-body px-0'>
                {location?.location?.locations?.map((location: LocationDetails) => {
                  return (
                    <div className='d-flex flex-stack mb-8' key={location?.id}>
                      <div className='d-flex'>
                        <Link to='#' className='text-primary fw-semibold fs-6 me-2'>
                          {location?.name}
                        </Link>
                        {isDetails ? (
                          ''
                        ) : (
                          <>
                            <span
                              className='ms-5 mb-1 cursor-pointer '
                              onClick={() => {
                                setIdChiled(location?.id)
                                setTitle(location?.name)
                                setedittitleshow(true)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon svg-icon-primary svg-icon-3 '
                              />
                            </span>
                            <span
                              className='cursor-pointer ms-2'
                              onClick={() => {
                                Deletlocation(location?.id)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon svg-icon-danger svg-icon-3 '
                              />
                            </span>
                          </>
                        )}
                      </div>

                      <button
                        type='button'
                        className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                        onClick={() => {
                          setNewString(location?.name)
                          setidloc(location?.id)
                          setIdLoading(location?.id)
                          setLoading(true)
                          if (location?.id === IdParent) {
                            Getlocations()
                          } else {
                            setIdParent(location?.id)
                          }
                        }}
                      >
                        {Idloading !== location?.id ? (
                          <KTIcon iconName='arrow-right' className='fs-2 text-primary' />
                        ) : (
                          ''
                        )}
                        {loading && Idloading === location?.id ? (
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        ) : (
                          ''
                        )}
                      </button>
                    </div>
                  )
                })}
              </div>
              {/* end::Body */}
            </div>
          </div>
          {location?.location?.buildingPlan ? (
            <div className={`card card-xxl-stretch mb-5 mb-xxl-8 col-xxl-6 shadow-none`}>
              <div className='pt-10'>
                <ModalImage
                  small={toAbsoluteUrl(`${location?.location?.buildingPlan}`)}
                  large={toAbsoluteUrl(`${location?.location?.buildingPlan}`)}
                  alt='Image Description'
                />
              </div>
            </div>
          ) : (
            <div className={`card card-xxl-stretch mb-5 mb-xxl-8 col-xxl-6 shadow-none`}>
              <div className='justify-content-center'>
                <MyComponent
                  pic={props.pic}
                  setshow={props.setshow}
                  show={props.show}
                  setedittitleshow={props.setedittitleshow}
                  fetchingImages={props.fetchingImages}
                  fetchProgress={props.fetchProgress}
                  setLoad={props.setLoad}
                  load={props.load}
                  images={props.images}
                  galleryImages={props.galleryImages}
                  fetchData={props.fetchData}
                  pdf={props.pdf}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={selectshow}
        onHide={() => {
          setselectshow(false)
        }}
        centered
        className='modal-right'
        size='lg'
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>Project plans</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
            <button
              type='button'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary mb-5'
              onClick={() => {
                if(image===undefined){
                
                  setImage(props.pic)

                  const file = dataURLtoFile(
                    props.pic || '',
                    'image.jpg'
                  )
                  setSelectedFile(file)
                }
                setselectshow(false)
                setSelectedImage(null)
              }}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='indicator-label'>Save</span>
              </span>
            </button>
          </div>
          <div>
            {numPages && (
              <Document file='PDF_FILE_URL' onLoadSuccess={({numPages}) => setNumPages(numPages)}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} width={400} />
                ))}
              </Document>
            )}
            <>
              {props.fetchingImages && (
                <ProgressBar
                  now={props.fetchProgress}
                  label={`${props.fetchProgress}%`}
                  className='mt-2 w-100'
                />
              )}
            </>{' '}
            <ImageGallery
              items={props.galleryImages}
              onSlide={(currentIndex: number) => handleSlideChange(currentIndex)}
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets={true}
            />
          </div>
        </Modal.Body>
      </Modal>

      {selectshow === false ? (
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName='modal-dialog modal-fixed-right'
          contentClassName='modal-content'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-lg'>New location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Title</label>{' '}
              <span className='text-danger'> *</span>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder='Title'
                type='text'
                autoComplete='off'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
                value={title}
              />
              <div className='fv-row mb-5 mt-4'>
                <label className='fw-bold fs-6 mb-2'>Upload photos</label>{' '}
                {image ? (
                  <>
                    <div className={`card card-xxl-stretch mb-5 mb-xxl-8 shadow-none `}>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                      >
                        <div
                          className='image-input-wrapper w-100 h-300px'
                          style={{backgroundImage: `url(${image})`}}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='card-body py-0'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <span
                        className='btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1'
                        onClick={() => {
                          // fetchData()
                          setselectshow(true)
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-2' /> From file
                      </span>
                      <span
                        className='btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1'
                        onClick={handleUploadClick}
                      >
                        <KTIcon iconName='plus' className='fs-2' />From computer
                      </span>
                    </div>
                    <input
                      ref={inputRef}
                      type='file'
                      id="profile_avatar"
                      accept='image/*'
                      className='d-none'
                      onChange={handleFileSelect}
                    />
                  </div>
                )}
              </div>
              {/* end::Input */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='text-centre'>
              <button
                type='reset'
                onClick={handleClose}
                className='btn btn-light'
                data-kt-users-modal-action='cancel'
              >
                Discard
              </button>

              <button
                type='button'
                className='btn btn-primary ms-10'
                disabled={loading === true ? true : false}
                onClick={handelCreateLocation}
              >
                {!loading && <span className='indicator-label'>Create</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        ''
      )}

      <Modal
        show={editshow}
        onHide={() => {
          seteditShow(false)
        }}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>Edit location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Title</label>{' '}
            <span className='text-danger'> *</span>
            {/* end::Label */}
            {/* begin::Input */}
            <input
              placeholder='Title'
              type='text'
              autoComplete='off'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              value={title}
            />
            <div className='row mb-6 mt-10'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Edit photo <span className='text-danger'> *</span>
              </label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                >
                  {/*begin::Image preview wrapper*/}
                  <>
                    {imageApi || image ? (
                      <>
                        {image ? (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{backgroundImage: `url(${image})`}}
                          />
                        ) : (
                          <>
                            {imageApi ? (
                              <img
                                className='image-input-wrapper w-125px h-125px'
                                src={toAbsoluteUrl(`${imageApi}`)}
                                alt='Metornic'
                              />
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: 'url(/assets/media/avatars/300-1.jpg)'}}
                      />
                    )}
                  </>

                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Cancel avatar'
                  >
                    <i className='ki-outline ki-cross fs-3' />
                  </span>
                  {/*end::Cancel button*/}
                  {/*begin::Remove button*/}
                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Remove avatar'
                    onClick={removePic}
                  >
                    <i className='ki-outline ki-cross fs-3' />
                  </span>
                  {/*end::Remove button*/}
                </div>
              </div>
              <div className='card-body pt-0 mt-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <span
                    className='btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1'
                    onClick={() => {
                      // fetchData()

                      setselectshow(true)
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-2' /> From file
                  </span>

                  <span
                    className='btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1'
                    onClick={handleUploadClick}
                  >
                    <KTIcon iconName='plus' className='fs-2' />From computer
                  </span>
                </div>
                <input
                  ref={inputRef}
                  id="profile_avatar"
                  type='file'
                  accept='image/*'
                  className='d-none'
                  onChange={handleFileSelect}
                />
              </div>
            </div>
            {/* end::Input */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-centre'>
            <button
              type='reset'
              onClick={() => {
                seteditShow(false)
              }}
              className='btn btn-light'
              data-kt-users-modal-action='cancel'
            >
              Discard
            </button>
            <button
              type='button'
              className='btn btn-primary ms-10'
              disabled={(title && image) || (title && imageApi) ? false : true}
              onClick={handleEditeLocation}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={edittitleshow}
        onHide={() => {
          setedittitleshow(false)
        }}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>Edit Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Title</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Title'
              type='text'
              autoComplete='off'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              value={title}
            />
            {/* end::Input */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-centre'>
            <button
              type='reset'
              onClick={() => {
                setedittitleshow(false)
              }}
              className='btn btn-light'
              data-kt-users-modal-action='cancel'
            >
              Discard
            </button>
            <button
              type='button'
              className='btn btn-primary ms-10'
              onClick={handleEditetitleLocation}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {isEdit === false ? (
        <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
          <button
            type='reset'
            onClick={() => props.setSteps(props.Step - 1)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Back
          </button>
          {user.roles[0] === 'Building Owner' ? (
            ''
          ) : (
            <button
              type='button'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary'
              onClick={() => props.setSteps(props.Step + 1)}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='indicator-label'>Continue</span>
                <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
              </span>
            </button>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export {Step2}
