import { KTIcon } from '../../../../../../_metronic/helpers'
import { FC } from 'react'


type Props = {
  setIsOpen: (isOpen: boolean) => void
}
const BillingCardModalHeader: FC<Props> = ({ setIsOpen }) => {

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>
        Update Card
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          setIsOpen(false)
        }}
        style={{ cursor: 'pointer' }}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export { BillingCardModalHeader }
