import { FC, useEffect, useState } from 'react'

import { BillingModalHeader } from './BillingModalHeader'
import { BillingModalForm } from './BillingModalForm'

interface BillingModal {
  planId: string | null,
  cardName: string,
  cardToken: string,
  address1: string,
  address2: string,
  zipcode: string,
  city: string,
  state: string | null,
  country: string | null,
  coupon: string,
  isCoupon?: boolean
  planName?: string
  planPrice?: number
  countryId?: number | null
  sameAddress?: boolean
  isPrevStep?: boolean
}

type Props = {
  setIsOpen: (isOpen: boolean) => void
  refetch: () => void
}


const BillingModal: FC<Props> = ({ setIsOpen, refetch }) => {
  const [sameAddress, setSameAddress] = useState<boolean>(false)
  const [cardToken, setCrdToken] = useState<string>('')
  const [cardNumber, setCardNumber] = useState<any>('')
  const [formData, setFormData] = useState<BillingModal>({
    planId: null,
    cardName: "",
    cardToken: '',
    address1: "",
    address2: "",
    zipcode: "",
    city: "",
    state: "",
    country: "United States",
    countryId: 240,
    coupon: "",
    isCoupon: false
  })
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState<any>(null)
  const [discount, setDiscount] = useState(null)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    console.log('formData', formData)
    console.log('discount', discount)

  }, [formData])


  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >

        <div className='modal-dialog modal-dialog-centered mw-950px'>
          <div className='modal-content'>
            <BillingModalHeader setIsOpen={setIsOpen} />
            <div className='modal-body my-7'>
              <BillingModalForm
                setFormData={setFormData}
                formData={formData}
                setIsOpen={setIsOpen}
                setDiscount={setDiscount}
                discount={discount}
                setCurrentSelectedPlan={setCurrentSelectedPlan}
                currentSelectedPlan={currentSelectedPlan}
                refetch={refetch}
                sameAddress={sameAddress}
                setSameAddress={setSameAddress}
                cardToken={cardToken}
                setCrdToken={setCrdToken}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { BillingModal }
