// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'

type Props = {
  row: Row<User>
}
function extractDetailsFromUrl(url: string) {
  const searchParams = new URLSearchParams(new URL(url).search)
  return searchParams.get('details') === 'true'
}
const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)
const CustomRow: FC<Props> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell, index) => {
      return (
        <>
          {extractDetailsFromUrl(window.location.href) === true && index === 0 ? (
            ''
          ) : (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )}
        </>
      )
    })}
  </tr>
)

export {CustomRow}
