import { FC, useEffect } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import ImagesViewer from './ImagesViewer'

type Props = {
    imageId: any
    setImageId: (id: undefined) => void
    setImageSrc: (src: string) => void
    attachments: any[]
}
const TaskImageViewerModal: FC<Props> = ({ setImageId, setImageSrc, attachments, imageId }) => {
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-750px'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>
                                Image viewer
                            </h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => {
                                    setImageId(undefined)
                                    setImageSrc('')
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                        </div>
                        <div className='modal-body scroll-y mx-5 mx-xl-5 my-7 mh-800px'>
                            <ImagesViewer attachments={attachments} clickedimageId={imageId} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export { TaskImageViewerModal }
