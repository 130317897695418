import { FC } from 'react'
import { User } from '../../core/_models'
import clsx from 'clsx'

type Props = {
  user: User
}
const TaskInfoName: FC<Props> = ({ user }) => {
  return (
    // <div className='d-flex align-items-center'>
    //   <div className='d-flex flex-column'>
    //     <a href='#' className='text-primary-600 text-hover-primary mb-1' onClick={() => {
    //       (window as any).taskId = user.id;
    //       var element = document.getElementById("taskiD");
    //       if (element) {
    //         element.click();
    //       }
    //     }}>
    //       {
    //         user.name
    //       }
    //     </a>
    //   </div>
    // </div>
    <div className={clsx('app-navbar-item')}>
      <div id='kt_activities_toggle'
        onClick={() => {
          (window as any).taskId = user.id;
          var element = document.getElementById("taskiD");
          if (element) {
            element.click();
          }
        }}
      >
        {user.name}
      </div>
    </div>
  )
}

export default TaskInfoName