import { toAbsoluteUrl } from "../../../_metronic/helpers";

export function ImageCardNumber(value: any) {
    function creditCardTypeFromNumber(number: any) {
        try {
            // visa
            var re = new RegExp("^4");
            if (number.match(re) != null) return "Visa";

            // Mastercard
            // Updated for Mastercard 2017 BINs expansion
            if (
                /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
                    number
                )
            )
                return "MasterCard";

            // AMEX
            re = new RegExp("^3[47]");
            if (number.match(re) != null) return "Amex";

            // Discover
            re = new RegExp(
                "^(6011|622(12[6-9]|1[3-9][0-9]{12}|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
            );
            if (number.match(re) != null) return "Discover";

            // Diners
            re = new RegExp("^36");
            if (number.match(re) != null) return "Diners";

            // Diners - Carte Blanche
            re = new RegExp("^30[0-5]");
            if (number.match(re) != null) return "Diners - Carte Blanche";

            // JCB
            re = new RegExp("^35(2[89]|[3-8][0-9])");
            if (number.match(re) != null) return "JCB";

            // Visa Electron
            re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
            if (number.match(re) != null) return "Visa Electron";

            // Union Pay
            if (number.match(/^(62[0-9]{14,17})$/)) return "Union Pay";
            return "";
        } catch (err) {
            console.log("~err", err);
        }
    }

    const cardType = creditCardTypeFromNumber(value);

    let imageUrl = "";
    switch (cardType) {
        case "Visa":
            imageUrl = "/media/svg/card-logos/visa.svg";
            break;
        case "MasterCard":
            imageUrl = "/media/svg/card-logos/mastercard.svg";
            break;
        case "Amex":
            imageUrl = "/media/svg/card-logos/american-express.svg";
            break;
        case "Discover":
            imageUrl = "/media/svg/card-logos/discover.png";
            break;
        case "Diners":
        case "Diners - Carte Blanche":
            imageUrl = "/media/svg/card-logos/diners-club.png";
            break;
        case "JCB":
            imageUrl = "/media/svg/card-logos/jcb.png";
            break;
        case "Visa Electron":
            imageUrl = "/media/svg/card-logos/visa-electron.png";
            break;
        case "Union Pay":
            imageUrl = "/media/svg/card-logos/union-pay.png";
            break;
        default:
            imageUrl = "";
            break;
    }

    return <img src={toAbsoluteUrl(imageUrl)} alt="" className="h-25px" />;
}
export function ImageCardNumber2(cardType: any) {
    let imageUrl = "";
    switch (cardType) {
        case "Visa":
            imageUrl = "/media/svg/card-logos/visa.svg";
            break;
        case "MasterCard":
            imageUrl = "/media/svg/card-logos/mastercard.svg";
            break;
        case "Amex":
            imageUrl = "/media/svg/card-logos/american-express.svg";
            break;
        case "Discover":
            imageUrl = "/media/svg/card-logos/discover.png";
            break;
        case "Diners":
        case "Diners - Carte Blanche":
            imageUrl = "/media/svg/card-logos/diners-club.png";
            break;
        case "JCB":
            imageUrl = "/media/svg/card-logos/jcb.png";
            break;
        case "Visa Electron":
            imageUrl = "/media/svg/card-logos/visa-electron.png";
            break;
        case "Union Pay":
            imageUrl = "/media/svg/card-logos/union-pay.png";
            break;
        default:
            imageUrl = "";
            break;
    }

    return <img src={toAbsoluteUrl(imageUrl)} alt="" className="me-4" />;
}
