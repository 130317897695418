import React, { FC, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import JSpdf from "jspdf";
import h2c from "html2canvas";
import { saveAs } from 'file-saver';
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URLL

type Props = {
    data: any | undefined
    selectedProject: any | undefined
    groupBy: any | undefined
    Withpic:any|undefined
}
const Card: FC<Props> = ({ data, selectedProject, groupBy ,Withpic}) => {
    useEffect(() => {
        fetchPDF()
    }, [])
    const handleLbaleColor = (statusName: any) => {
        if (statusName === 'Draft') {
            return 'warning'
        } else if (statusName === 'Open') {
            return 'secondary'
        }
        else if (statusName === 'Completed') {
            return 'success'
        }
        else if (statusName === 'Closed') {
            return 'danger'
        }
    }
    const [check, setcheck] = useState<boolean>(true)
    const [Getdata, setGetdata] = useState<any>()
    const fetchPDF = async () => {
        try {
            const response = await axios.get(`${API_URL}/Tasks/pdf/${selectedProject}?withPic=${Withpic}&groupBy=${groupBy}`, {
                responseType: 'blob',
            });
            if (response.status === 200) {
                setGetdata(response.data)
                setcheck(false)
            }

        } catch (error) {
            // Handle error
            setcheck(false)
            console.error('Error fetching PDF:', error);
        }
    };
    const exportToPdf = (e: any) => {
        e.preventDefault();
        const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
        const fileName = `${(window as any).projectName}_${currentDate}.pdf`; 
        saveAs(Getdata,fileName);
    };

    useEffect(() => {
        console.log((window as any).projectName)
        fetchPDF()
    }, [])

    return (
        <>
            <div
                id="toRender"
                className="d-flex h-auto"
            >

                <div className="content flex-row-fluid" id="kt_content" >

                    <div className="card">
                        <div className="card-body">
                            <div className="mw-lg-950px mx-auto w-100 text-center justify-content-center ">
                                {/* <div className="d-flex justify-content-between flex-column flex-sm-row mb-10">
                                    <h2 className="fw-bolder text-gray-800 fs-5 pe-5 pb-7">
                                        212 W 93RD ST, 10025, NEW YORK, NY, USA
                                    </h2>
                                    <div className="text-sm-end">
                                        <img
                                            alt="Logo"
                                            src={toAbsoluteUrl('/media/logos/PunshList.svg')}
                                        />
                                        <div className="text-sm-end fs-4 mt-2">
                                            <div>
                                                Exported on: {new Date().toLocaleDateString()}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="text-center">
                                    {check === true ?
                                        <img
                                            alt='Logo'
                                            src={toAbsoluteUrl('/media/icons/report-downloa.png')}
                                            className='rounded mx-auto d-block w-150px h-150px mb-5'
                                        /> : <img
                                            alt='Logo'
                                            src={toAbsoluteUrl('/media/icons/done.png')}
                                            className='rounded mx-auto d-block w-150px h-150px mb-5'
                                        />}

                                </div>
                                <div className="justify-content-center">

                                    <strong className='fs-5'>{check === true ? "Your report is generating!" : "Your report has been generated"}</strong>
                                    {check === true ? <div className="spinner-border ml-auto ms-5" role="status" aria-hidden="true"></div> : ""}

                                </div>
                                <div className="justify-content-center ">

                                    <button className=" btn btn-primary mt-5" onClick={exportToPdf} disabled={check}>
                                        <div className='d-flex'>
                                            <span className='me-3'><img
                                                alt='Logo'
                                                src={toAbsoluteUrl('/media/icons/cloud-download.png')}
                                                className='d-flex w-20px h-20px '
                                            /></span>
                                            
                                            <span>   Export to pdf</span>
                                        </div>


                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Card