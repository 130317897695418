import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'
import { useNavigate } from 'react-router-dom'

import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ModelStateMessage, QueryFetchPost} from '../../global'
import {Spinner} from 'react-bootstrap'

interface formModel {
  email: string
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [isRequested, setIsRequested] = useState(false)
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [isLoader, setIsLoader] = useState(false)
  const Navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (formData: formModel) => {
    setLoading(true)
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Account/ForgetPassword`,
        data: formData,
        method: 'POST',
        key: 'forgotPassword',
        signal: undefined,
        isFormData: false,
      }).then((response) => {
        if (response[0] === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'We have sent you an email with a link to reset your password',
          })
          setTimeout(() => {
            Navigate('/auth/login', { replace: true })
          }, 1500);
          // setLoading(false);
          setLoading(false)
        } else {
          ModelStateMessage(response)
          setLoading(false)
        }
      })
    } catch (error) {
      Swal.fire('Error', 'An error occurred. please try again later.', 'error')
    }
  }

  return (
    <>
      <Link to='#' className='ms-17'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/Frame 13.svg')} className='h-45px' />
      </Link>
      <div className='ms-17 fs-4 w-60 mt-5'>
        Enter your email address and we will send you instructions to reset your password{' '}
      </div>
      <h1 className='ms-17 mt-5 mb-0'>Reset your password</h1>
      <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
        {/*begin::Wrapper*/}

        <div className='w-lg-500px bg-body rounded p-10 p-lg-8 mx-auto'>
          <Formik<formModel>
            validationSchema={forgotPasswordSchema}
            initialValues={{
              email: '',
            }}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
              isValid,
              touched,
              errors,
            }) => {
              return (
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_password_reset_form'
                  onSubmit={handleSubmit}
                >
                  {/* end::Title */}

                  {/* begin::Form group */}
                  <div className='fv-row mb-8'>
                    <div>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                    <input
                      name='email'
                      id='email'
                      type='email'
                      placeholder=''
                      autoComplete='off'
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': touched.email && errors.email},
                        {
                          'is-valid': touched.email && !errors.email,
                        }
                      )}
                    />
                    {touched.email && errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.email}</span>
                        </div>
                      </div>
                    )}</div>
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                      type='submit'
                      id='kt_password_reset_submit'
                      className='btn btn-primary me-4'
                      disabled={ !isValid}
                    >
                      {} <span className='indicator-label'>Submit</span>
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          className='mb-1 ml-5'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </button>
                    <Link to='/auth/login'>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        
                      >
                        Cancel
                      </button>
                    </Link>{' '}
                  </div>
                  {/* end::Form group */}
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}
