/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Resetpassword} from './components/Reset-password'
import {ConfrmEmail} from './components/ConfirmEmail'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/*begin::Logo*/}

        {/*end::Logo*/}
        {/*begin::Aside*/}
        <div
          className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/auth/pexels-charles-parker-5847357.jpg')})`,
          }}
        >
          <div className='d-flex  fixed-bottom ms-20 mb-1'>
            {/* start:: Aside header */}

            {/* end:: Aside header */}

            {/* start:: Aside content */}
            {/* <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Welcome to Metronic!
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  The ultimate Bootstrap & React 16 admin theme framework for
                  next generation web apps.
                </p>
              </div> */}
            {/* end:: Aside content */}

            {/* start:: Aside footer for desktop */}

            {/* end:: Aside footer for desktop */}
          </div>
        </div>

        <div
          className={`d-flex flex-column flex-column-fluid ${
            window.location.pathname !== '/auth/privacy-policy' ? ' flex-center ' : ''
          } w-lg-50 `}
        >
          {/*begin::Wrapper*/}

          {/* <img src={toAbsoluteUrl('/media/auth/city.jpg')} alt=""/> */}
          <div
            className={`${
              window.location.pathname !== '/auth/privacy-policy' ? 'w-lg-600px ' : ''
            } bg-body rounded p-10 p-lg-15 mx-auto`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<Resetpassword />} />
      <Route path='/activateaccount' element={<ConfrmEmail />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
