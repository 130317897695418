import React, { FC } from 'react'
import { handleRemoveImage } from '../core/_requests'
import Swal from 'sweetalert2'
import { useQueryClient } from 'react-query'

type Props = {
    attachments: any
    currentUser: any
}
const Images: FC<Props> = ({ attachments, currentUser }) => {
    const queryClient = useQueryClient()

    const DeleteImage = (id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await handleRemoveImage(id).then(() => {
                    queryClient.invalidateQueries([`tasks-list-task-${currentUser}`])
                    Swal.fire({
                        text: 'Images has been deleted.',
                        icon: 'success',
                        showConfirmButton: true,
                        timer: 2000,
                        width: '25rem',
                    })
                })
            }
        })
    }
    return (
        attachments && attachments.map((attachment: any) => {
            return (
                <div
                    className="image-input image-input-empty image-input-outline image-input-placeholder m-5"
                    data-kt-image-input="true"
                >
                    <img src={attachment?.path} alt={attachment?.name} className="image-input-wrapper w-125px h-125px" />
                    <label
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Delete image"
                    >
                        <i className="ki-duotone ki-cross fs-2" onClick={() => DeleteImage(attachment?.id)} >
                            <span className="path1" />
                            <span className="path2" />
                        </i>
                    </label>
                </div>
            )
        })

    )
}

export default Images