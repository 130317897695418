import {ID, Response} from '../../../../../../_metronic/helpers'
export type Tag = {
  id?: ID
  name?: string
  description?: string
  inDocument?: number
  inHighlight?: number
  projectId: number | "" | null
}

export type UsersQueryResponse = Response<Array<Tag>>

export const initialUser: Tag = {
  name: '',
  description: '',
  projectId:null
}
