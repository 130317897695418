
import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Overview } from './components/Overview';
import { ProfileHeader } from './ProfileHeader';
import { Settings } from './components/Settings';
import { PasswordAndSecurity } from './components/PasswordAndSecurity';
import { QueryFetchPost } from '../global';
import {useQuery} from 'react-query'
import {BillingWraper } from './components/Billing/BillingWraper';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: 'overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const profileBreadCrumbs1: Array<PageLink> = [
  {
    title: 'Profile',
    path: 'settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];
const profileBreadCrumbs2: Array<PageLink> = [
  {
    title: 'Billing',
    path: 'billing',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];
interface Model{
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  address1: string ,
  address2: string ,
  city: string ,
  country: string ,
  profilePicture: null ,
  role: string ,
  site: string ,
  state: string ,
  zipCode: string ,
}

const ProfilePage = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [profile, Setprofile] = useState<Model|undefined>()
  useEffect(()=>{
    GetProfile();
  },[])
  const GetProfile = async () => {
     QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
    
       Setprofile(response[1].profile)
       console.log(response[1].profile)
      } 
    })}
  
  
  return (
    <>

    <Routes>
      <Route
        element={
          <>
            <ProfileHeader  
            profile={profile} />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <Overview  profile={profile}  setProfile={Setprofile}/>
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs1}>Basic Informations</PageTitle>
              <Settings 
              GetProfiles={GetProfile}
              profile={profile} 
              />
            </>
          }
        />
        <Route
          path='password-and-security'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs1}>Password & Security</PageTitle>
              <PasswordAndSecurity />
            </>
          }
        />
        <Route
          path='billing'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs2}>Billing</PageTitle>
              <BillingWraper />
            </>
          }
        />
        <Route index element={<Navigate to='overview' />} />
      </Route>
    </Routes>
    </>
    
  );
};

export default ProfilePage;