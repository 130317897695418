import React, { FC } from 'react'
import { DateFormat } from '../../../global/DateFormat'
import { TimeConversion } from '../../../global'

type Props = {
    data?: any
}
const BillingTable: FC<Props> = ({ data }) => {
     const convertDateFormat = (dateString: string) => {
        const dateObj = new Date(dateString);
      
        // Subtract one hour from the date
        dateObj.setHours(dateObj.getHours() - 1);
      
        // Get the date components
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
      
        // Get the time components
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      
        // Combine the date and time components
        const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}`;
      
        return formattedDate;
      };
      
  
    return (
        <div className="card">
            <div className="card-header card-header-stretch border-bottom border-gray-200">
                <div className="card-title">
                    <h3 className="fw-bold m-0">Billing History</h3>
                </div>
            </div>
            <div className="tab-content">
                <div
                    className="card-body p-0 tab-pane fade show active"
                    role="tabpanel"
                >
                    <div className="table-responsive">
                        <table className="table table-row-bordered align-middle gy-4 gs-9">
                            <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
                                <tr>
                                    <td className="min-w-150px">Date</td>
                                    <td className="min-w-250px">Description</td>
                                    <td className="min-w-150px">Amount</td>
                                    <td className="min-w-150px">Invoice</td>
                                    <td className="min-w-150px">Status</td>
                                    <td className="min-w-150px"></td>
                                    <td />
                                </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                                {
                                    data?.map((item: any) => {
                                        return (
                                            <tr>
                                                <td className="min-w-150px">
                                                    {
                                                        TimeConversion(convertDateFormat(item.date), localStorage?.getItem("TimeZone"))
                                                    }
                                                </td>
                                                <td className="min-w-250px">{item.description}</td>
                                                <td className="min-w-150px">
                                                    {
                                                        new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                        }).format(item.amount)
                                                    }
                                                </td>
                                                <td>
                                                    <span
                                                        className="btn btn-sm btn-light btn-active-light-primary"
                                                        onClick={() => {
                                                            window.open(item.pdf, '_blank')
                                                        }}
                                                    >
                                                        PDF
                                                    </span>
                                                </td>
                                                <td className="min-w-150px">
                                                    <span className="badge badge-light-success fs-7 ms-2">{item.status}</span>
                                                </td>
                                                <td />
                                                <td>
                                                    <span
                                                        className="btn btn-sm btn-light btn-active-light-primary"
                                                        onClick={() => {
                                                            window.open(item.view, '_blank')
                                                        }}
                                                    >
                                                        View
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BillingTable