import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, initialQueryState, KTIcon } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQuery, useQueryClient } from 'react-query'
import Select from 'react-select'
import { ReactSelectStyles } from '../../../../../global/ReactSelectStyle'
import { getProjects } from '../../core/_requests'
interface Project {
  id: number;
  name: string;
  // Add other properties if available
}
const TagsListFilter = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const queryClient = useQueryClient()
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [ProjectId, setProjectId] = useState<ID | undefined>()
  // const [role, setRole] = useState<string | undefined>()
  // const [lastLogin, setLastLogin] = useState<string | undefined>()

  // const projects: any = queryClient.getQueryData('ProjectsViewModel');
  // const projectsOptions = projects?.data?.map((project: any) => ({ value: project.id, label: project.name }))
  const { data: projects } = useQuery('ProjectsViewModel', getProjects, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })
  const projectsOptions = projects?.data?.map((project: any) => ({ value: project.id, label: project.name }))

  useEffect(() => {
    MenuComponent.reinitialization()
  console.log(projectsOptions)
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
    setProjectId(null);
  }

  const filterData = () => {
    updateState({
      filter: {ProjectId},
      ...initialQueryState,
    })
    closeMenu();
    setMenuOpen(true);
  }
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleSelectClick = (e: any) => {
    e.stopPropagation();
  };
  return (
    <>

      {/* <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>*/}
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px'  ${isMenuOpen ? 'show' : ''}`}
       data-kt-menu='true' 
       onClick={handleSelectClick}
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Project:</label>
            <Select
              className='w-100'
              isClearable={true}
              options={projectsOptions}
              value={projectsOptions?.find((project: any) => project.value === ProjectId)}
              onChange={(e: any) => setProjectId(e?.value)}
              styles={ReactSelectStyles}
            />
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>  
      {/* begin::SubMenu */}

    </>
  )
}

export { TagsListFilter }
