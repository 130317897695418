import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Tag, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URLL
const TAGS_URL = `${API_URL}/Dashboard/OpenTasks`


const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${TAGS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Tag | undefined> => {
  return axios
    .get(`${TAGS_URL}/${id}`)
    .then((response: AxiosResponse<Response<Tag>>) => response.data)
    .then((response: Response<Tag>) => response.data)
}

const createUser = (user: Tag): Promise<Tag | undefined> => {
  return axios
    .post(TAGS_URL, user)
    .then((response: AxiosResponse<Response<Tag>>) => response.data)
    .then((response: Response<Tag>) => response.data)
}

const updateUser = (user: Tag): Promise<Tag | undefined> => {
  return axios
    .put(`${TAGS_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Tag>>) => response.data)
    .then((response: Response<Tag>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${TAGS_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${TAGS_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
