import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTIcon, isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { initialUser, User } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { Modal } from 'react-bootstrap'
import {
  createUser,
  getLocationPlan,
  getProjectsLocations,
  getTags,
  postAttachments,
  updateUser,
} from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { TeamsListLoading } from '../components/loading/TeamsListLoading'
import CustomDropzone from '../../../../global/CustomDropzone'
import CustomTree from '../../../../global/MyTree'
import { Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { ReactSelectStyles } from '../../../../global/ReactSelectStyle'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { handleApiErrors } from '../../../../global/HandleApiErrors'
import Images from './Images'
import SelectAsyncPaginate from '../../../../global/SelectAsyncPaginate'
import SelectAsyncPaginate2 from '../../../../global/SelectAsyncPaginate2'
import { convertToGMT } from '../../../../global'
import axios from 'axios'
import moment from 'moment'
import { useParams } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URLL
interface typ {
  id: any
  lastName: string
  firstName: string
  email: string
  isInspectors: boolean
}
const BASE_URL = process.env.REACT_APP_BASE_URL

type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object().shape({
  value: Yup.number().nullable().moreThan(-1, 'Value should not be negative'),
  name: Yup.string().min(3, 'Minimum 3 characters').required('Task Name is required').nullable(),
  projectId: Yup.string().required('Project Name is required'),
  subContractorId: Yup.string().required('SubContractor is required'),
  locationId: Yup.string().required('location is required')
})

const TasksEditForm: FC<Props> = ({ user, isUserLoading }) => {
  const params = useParams<any>()
  const { id } = params
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [showForm, setShowForm] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [plan, setPlan] = useState<string>('')
  const [show, setShow] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [companyCheck, setcompanyCheck] = useState<any>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [locations, setLocations] = useState<any>([])
  const [tagss, settagss] = useState<any>([])
  const [locationName, setLocationName] = useState<any>('')
  const [treeInfo, setTreeInfo] = useState<any>([])
  const [tagIds, setTagIds] = useState<any>([])
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [selectedSubContractorInTeam, setSelectedSubContractorInTeam] = useState<any>(null)
  const [close, setclose] = useState<any>([])
  const [path, setpath] = useState<any>([])
  const handleClose = () => setShow(false)
  const [ListTeams, setListTeams] = useState<any>()
  const [Scount, setScount] = useState<number | null>()
  const [ids, setids] = useState<any>()
  const [DetailCheck, setDetailCheck] = useState<typ[]>([])
  const [Icount, setIcount] = useState<number | null>()
  const [checkedValues, setCheckedValues] = useState<string[]>([])
  const [checkval, setcheckval] = useState<any>(null)
  const [detailval, setdetailval] = useState<any>(null)
  const [idpro, setidpro] = useState<any>(null)
  const [loadingteam, setloadingteam] = useState(false)
  const [items, setItems] = useState<{ id: string; isInspectors: boolean }[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  useEffect(() => {
    if (selectedProject?.value) {
      geteam(selectedProject?.value)
    }
  }, [selectedProject])


  useEffect(() => {
    if (!show) {
      setcompanyCheck(detailval)
      setcheckval(formik.values.subContractorId)
    }
  }, [show])
  var itemlist: Array<any> = []
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, isInspectors: boolean) => {
    itemlist.push(items)
    const id = event.target.value
    const isChecked = event.target.checked

    if (isChecked) {
      setCheckedValues((prevValues) => [...prevValues, id])
    } else {
      setCheckedValues((prevValues) => prevValues.filter((v) => v !== id))
      const tt = items.filter((item: any) => item.id !== id)
      const bb = DetailCheck.filter((item: any) => item.id !== id)
      setItems(tt)
      setDetailCheck(bb)
    }
    const newItem = { id, isInspectors: isInspectors }
    const itemIndex = items.findIndex((item) => item.id === id)
    if (itemIndex === -1) {
      setItems([...items, newItem])
    }
  }
  // useEffect(() => {
  //   geteam(idpro)
  // }, [idpro])
  useEffect(() => {
    if (user?.subContractorId) {
      setcheckval(user?.subContractorId)
    }
    if (user?.projectId) {

      geteam(user?.projectId)
    }
  }, [])
  async function geteam(idp: any) {
    setScount(null)
    setIcount(null)
    try {
      const response = await axios.get(`${BASE_URL}/api/Tasks/GetCompaniesDropDown/${idp}?search=${searchTerm}`)
      if (response.status === 200) {

        const totalCount = response?.data?.data?.contractingCompanies?.reduce(
          (sum: any, obj: any) => sum + obj.subContractor.length,
          0
        )
        setScount(totalCount)
        setIcount(response?.data?.data?.inspectionCompanies?.ispectorsTeam.length)
        setListTeams(response.data.data)
        if (user?.subContractorId) {
          response?.data?.data?.users?.map((company: any) => {
            company?.users?.map((e: any) => {
              if (e.id === user?.subContractorId) {
                if (ids?.includes(company?.companyId)) {
                  for (var i = 0; i < ids.length; i++) {
                    setids(ids.filter((item: any) => item !== company?.companyId))
                  }
                  setids(ListId)
                } else {
                  ListId.push(company?.companyId)
                  setids(ListId)
                }
                if(companyCheck === null){
                  setdetailval(e)
                }
                setcompanyCheck(e)
              }
            })
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  var ListId: Array<number> = []

  const Call = () => {
    if (selectedProject?.value) {
      axios.get(`${API_URL}/Tasks/Tags?ProjectId=${selectedProject.value}`).then((response) => {
        const tags = response.data?.data?.map((tag: any) => ({ value: tag.id, label: tag.name }))
        settagss(tags)
      })
    }
  }

  useEffect(() => {
    setpath(user.locationPath)
  }, [showForm])
  useEffect(() => {
    Call()
  }, [selectedProject])

  useEffect(() => {
    if (id) {
      setSelectedProject({
        value: id,
        label: (window as any)?.projectName,
      })
      setIsLoading(true)
      getProjectsLocations(id).then((res) => {
        setLocations(res.locations)
        setIsLoading(false)
      })

      formik.setFieldValue('projectId', id)
    }
  }, [id])

  const [userForEdit] = useState<User>({
    ...user,
    name: user.name || initialUser.name,
    priority: user.priority || initialUser.priority,
    description: user.description || initialUser.description,
    projectId: user.projectId || initialUser.projectId,
    locationId: user.locationId || initialUser.locationId,
    value: user.value || initialUser.value,
    tagIds: user.tagIds || initialUser.tagIds,
    subContractorId: user.subContractorId || initialUser.subContractorId,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    if (user) {
      setLocationName(user?.locationName || user?.importingLoaction)
    }
  }, [user])

  useEffect(() => {
    if (user.projectId) {
      setIsLoading(true)
      getProjectsLocations(user.projectId).then((res) => {
        setLocations(res.locations)
        setIsLoading(false)
      })
      setSelectedProject({
        value: user?.projectId,
        label: user?.projectName,
      })
    }

    if (user.subContractorId) {
      setSelectedSubContractorInTeam({
        value: user?.subContractorId,
        label: user?.firstName + ' ' + user?.lastName,
      })
    }

    if (user.tagIds) {
      const tags = user?.tagIds.map((tag: any) => tag?.tagId)
      setTagIds(tags)
    }
  }, [user])

  useEffect(() => {
    if (tagIds) {
      formik.setFieldValue('tagIds', tagIds)
    }
  }, [tagIds])
  const currentDate = new Date()
  useEffect(() => {
    const timeZone = 'GMT-5'
  }, [])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // formik.setFieldValue("dueDate",)
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateUser(values).then(async (data: any) => {
            const formData = new (FormData as any)()
            selectedFiles.forEach((file: any, i: any) => {
              formData.append('files', file)
            })
            if (formData.getAll('files').length > 0) {
              await postAttachments(formData, values.id).then(() => {
                Swal.fire({
                  text: 'Task has been updated successfully.',
                  icon: 'success',
                  width: '25rem',
                  showConfirmButton: true,
                  timer: 2000,
                })
                cancel(true)
              })
            } else {
              Swal.fire({
                text: 'Task has been updated successfully.',
                icon: 'success',
                width: '25rem',
                showConfirmButton: true,
                timer: 2000,
              })
              cancel(true)
            }
          })
        } else {
          // if (treeInfo) {
          //   values.locationId = parseInt(treeInfo.node.key)
          // }
          await createUser(values).then(async (data: any) => {
            const formData = new (FormData as any)()
            selectedFiles.forEach((file: any, i: any) => {
              formData.append('files', file)
            })
            if (formData.getAll('files').length > 0) {
              await postAttachments(formData, data.id).then(() => {
                Swal.fire({
                  text: 'Task has been created successfully.',
                  icon: 'success',
                  width: '25rem',
                  showConfirmButton: true,
                  timer: 2000,
                })
                cancel(true)
              })
            } else {
              Swal.fire({
                text: 'Task has been created successfully.',
                icon: 'success',
                width: '25rem',
                showConfirmButton: true,
                timer: 2000,
              })
              cancel(true)
            }
          })
          setLocationName('')
        }
      } catch (error: any) {
        handleApiErrors(error?.response?.data)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleIconClick = () => {
    setShowForm(!showForm)
  }

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setShowForm(true)
  }

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setTreeInfo(info)
  }

  function convertData(data: any) {
    return data.map((item: any) => {
      const newItem: any = {
        key: item.id,
        title: item.name,
      }

      if (item.locations && item.locations.length > 0) {
        newItem.children = convertData(item.locations)
      }

      return newItem
    })
  }

  useEffect(() => {
    if (treeInfo.node) {
      setLocationName(treeInfo.node.title)
      formik.setFieldValue('locationId', parseInt(treeInfo.node.key))
      getLocationPlan(treeInfo.node.key).then((res: any) => {
        setPlan(res?.data?.location?.buildingPlan)
      })
    }
  }, [treeInfo])


  return (
    <>
      {showForm ? (
        <form onSubmit={handleSave}>
          {/* form input fields here */}
          <h4 className=' mb-8'>Select Location</h4>
          <div className='col-xl-12'>
            <Row>
              <Col xl='5' lg='5' md='6' className='w-100'>
                {
                    loading ? (
                      <div className='text-center'>
                        <div className='pt-10 pb-10'>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                        </div>

                        <div className='pb-15 fw-bold'>
                          <h3 className='text-gray-600 fs-5 mb-2'>Loading...</h3>
                          <div className='text-muted fs-7'>
                            Please wait...
                          </div>
                        </div>
                      </div>
                    ) : (
                      <CustomTree data={locations && convertData(locations)} onSelect={onSelect} />
                    )
                  }
              </Col>
              <Col xl='7' lg='7' md='6' className='plan-image text-center'>
                <span className='  mb-5 '>{path}</span>
                {plan && (
                  <img
                    alt='Logo'
                    src={plan}
                    className='w-100 h-400px object-cover object-center rounded border border-gray-300 border-dashed p-5 bg-white shadow-sm mb-5 mb-xl-0 card-img-top mt-3'
                  />
                )}
              </Col>
            </Row>
          </div>
          <div className='position-relative mt-20 pt-20'>
            <button
              type='button'
              className='btn btn-light-primary btn-sm me-4'
              onClick={() => {
                setShowForm(false)
                setLocationName('')

                formik.setFieldValue('locationId', null)
              }}
            >
              Back
            </button>
            <button
              disabled={formik.values.locationId === null ? true : false}
              type='submit'
              className='btn btn-primary btn-sm'
              onClick={handleIconClick}
            >
              Save
            </button>
          </div>
        </form>
      ) : (
        <>
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_user_scroll'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 required'>Task Name</label>
                <input
                  placeholder='Task Name'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />

                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>




              {/*  <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 required'>Project Name </label>
                 <Select
                  name="projectId"
                  options={projectsOptions}
                  value={projectsOptions?.find((item: any) => item.value === formik.values.projectId) || { value: null, label: 'Select...' }}
                  onChange={async (e: any) => {
                    formik.setFieldValue('projectId', e.value)
                    formik.setFieldValue('locationId', null)
                    locationName && setLocationName('')
                    await getProjectsLocations(e.value).then((res) => {
                      setLocations(res.locations)
                    }
                    )
                  }}
                  styles={ReactSelectStyles}
                /> 

                <SelectAsyncPaginate
                  apiLink={`${API_URL}/Tasks/Projects`}
                  value={selectedProject}
                  isDisabled={!itemIdForUpdate && id ? true : false}
                  onChange={async (e: any) => {
                    setdetailval(null)
                    setcheckval(null)
                    setListTeams(null)
                    setPlan('')
                    setidpro(e.value)
                    setSelectedProject(e)
                    formik.setFieldValue('projectId', e.value)
                    formik.setFieldValue('locationId', null)
                    locationName && setLocationName('')
                    document.getElementById('refreshDataSelect')?.click()
                    await getProjectsLocations(e.value).then((res) => {
                      setLocations(res.locations)
                    })
                  }}
                />
                {formik.touched.projectId && formik.errors.projectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.projectId}</span>
                    </div>
                  </div>
                )}
              </div> 
              */}

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 required'>Location</label>
                <div
                  className='input-group input-group-solid mb-5 cursor-pointer'
                  onClick={() => {
                    setSelectedFiles([])
                    if (formik.values.projectId) {
                      setclose(selectedFiles)
                      setShowForm(true)
                    }
                  }}
                >
                  <input
                    type='text'
                    name='locationId'
                    className='form-control border-0 bg-white'
                    value={locationName}
                    disabled
                    placeholder='Add location here'
                  />
                  <span className='input-group-text bg-white border-0'>
                    <i className='ki-duotone ki-abstract-10 text-primary fs-1'>
                      <i className='path1'></i>
                      <i className='path2'></i>
                    </i>
                  </span>
                </div>

                {formik.touched.locationId && formik.errors.locationId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.locationId}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 required'>Assign to</label>
                <div
                  className='input-group input-group-solid mb-5 cursor-pointer'
                  onClick={() => {
                    if (formik.values.projectId !== null)
                      setShow(true)

                  }}
                >
                  <input
                    type='text'
                    name='locationId'
                    className='form-control border-0 bg-white'
                    value={detailval?.firstName !== undefined ? detailval?.firstName + " " + detailval?.lastName : ""}
                    disabled
                    placeholder='Assign to here'
                  />
                  <span className='input-group-text bg-white border-0'>
                    <i className='ki-duotone ki-abstract-10 text-primary fs-1'>
                      <i className='path1'></i>
                      <i className='path2'></i>
                    </i>
                  </span>
                </div>
                {/* 
                {formik.touched.locationId && formik.errors.locationId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.locationId}</span>
                    </div>
                  </div>
                )} */}
              </div>

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 '>Add Photos</label>
                <CustomDropzone setSelectedFiles={setSelectedFiles} />
                {itemIdForUpdate && (
                  <Images attachments={user.attachments} currentUser={user?.id} />
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Task Description</label>
                <textarea
                  placeholder='Task Description'
                  {...formik.getFieldProps('description')}
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  name='description'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
              </div>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Tags</label>
                <Select
                  isMulti
                  name='tags'
                  options={tagss}
                  value={
                    tagss?.filter((item: any) => formik?.values?.tagIds?.includes(item.value)) || {
                      value: null,
                      label: 'Select...',
                    }
                  }
                  onChange={(e: any) =>
                    formik.setFieldValue(
                      'tagIds',
                      e.map((item: any) => item.value)
                    )
                  }
                  styles={ReactSelectStyles}
                />
              </div>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Due Date</label>
                <input
                  placeholder='Due Date'
                  {...formik.getFieldProps('dueDate')}
                  type='date'
                  name='dueDate'
                  value={formik.values.dueDate?.split('T')[0] || ''}
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  readOnly={formik.isSubmitting || isUserLoading}
                />
              </div>

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Value</label>
                <input
                  placeholder='Value'
                  {...formik.getFieldProps('value')}
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  type='number'
                  name='value'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.value && formik.errors.value && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.value}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='text-center pt-15 pb-5'>
              <button
                type='reset'
                onClick={() => cancel()}
                id='modal2_close'
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                Discard
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'

              >
                <span className='indicator-label'>{itemIdForUpdate ? 'Update' : 'Create'}</span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
          {(formik.isSubmitting || isUserLoading) && <TeamsListLoading />}
          <Modal show={show} onHide={handleClose} aria-labelledby='example-modal-sizes-title-lg' size='lg'>
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-lg'>Assign to</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='d-grid gap-2 d-md-flex justify-content-between'>
                <div className='card-title'>
                  {/* begin::Search */}

                  <div className='d-flex align-items-center position-relative my-1'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid w-250px ps-14 h-100'
                      placeholder='Search Project'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  {/* end::Search */}
                </div>
                <div>
                </div>
              </div>
              <h3 className=' mb-2 mt-5 fs-3'>Select Team</h3>
              <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='row w-100 h-300px '>

                  {ListTeams?.users.length === 0 ?
                    <div className='text-center'>
                      <div className='pt-10 pb-10'>
                        <KTIcon iconName='search-list' className='fs-4x opacity-50' />
                      </div>

                      <div className='pb-15 fw-bold'>
                        <h3 className='text-gray-600 fs-5 mb-2'>No matching records found</h3>
                        <div className='text-muted fs-7'>
                          Please try again with a different key
                        </div>
                      </div>
                    </div>
                    : ""}



                  <div className='col w-50'>



                    {ListTeams?.users?.map((team: any) => {
                      return (
                        <>
                          <>  <div className='d-flex space-between-center'>
                            <div className='form-check'>
                              <label className='cursor-pointer' onClick={() => {
                                if (ids?.includes(team?.companyId)) {
                                  for (var i = 0; i < ids.length; i++) {
                                    setids(ids.filter((item: any) => item !== team?.companyId))
                                  }
                                  setids(ListId)
                                } else {
                                  ListId.push(team?.companyId)
                                  setids(ListId)
                                }

                              }}>{team.companyName}</label>
                            </div>
                            <span
                              className='ant-tree-switcher ant-tree-switcher_close'
                              onClick={() => {


                                if (ids?.includes(team?.companyId)) {
                                  for (var i = 0; i < ids.length; i++) {
                                    setids(ids.filter((item: any) => item !== team?.companyId))
                                  }
                                  setids(ListId)
                                } else {
                                  ListId.push(team?.companyId)
                                  setids(ListId)
                                }

                              }}
                            >
                              {' '}
                              {ids?.includes(team?.companyId) ? (
                                <div className='pt-2 ms-6'>
                                  <img
                                    alt='Plan'
                                    src={toAbsoluteUrl('/media/Plan/down (1).png')}
                                    className='w-10px cursor-pointer'
                                  />
                                </div>
                              ) : (
                                <div className='pt-2 ms-6'>
                                  <img
                                    alt='Plan'
                                    src={toAbsoluteUrl('/media/Plan/upup.png')}
                                    className='w-10px cursor-pointer'
                                  />
                                </div>
                              )}
                            </span>
                          </div>
                            {ids?.includes(team?.companyId) ? (
                              <>
                                {team?.users?.map((SC: any) => {

                                  return (
                                    <div className='ms-18 mt-5'>
                                      <div className='d-flex space-between-center'>
                                        <div className='form-check'>
                                          <input
                                          id={SC.id} 
                                            className='form-check-input'
                                            type='checkbox'
                                            value={SC.id}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setcheckval(SC.id)
                                                setDetailCheck((prevValues) => [...prevValues, SC])
                                                setcompanyCheck(SC)
                                              } else {
                                                setcompanyCheck(null)
                                                setcheckval(null)
                                              }
                                              handleInputChange(e, SC.isInspectors)
                                            }}
                                            checked={checkval === SC.id}
                                          />
                                          <label className='fs-6 cursor-pointer' htmlFor={SC.id}>
                                            {SC?.firstName} {SC?.lastName}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </>
                            ) : (
                              ''
                            )}</>

                        </>
                      )
                    })}
                  </div>
                  <div className='col w-50'>
                    <>{companyCheck ?
                      <div className='card border border-grey border-2 rounded-3 mt-3'>
                        <div className='card-body'>
                          <h4>
                            {companyCheck?.firstName} {companyCheck?.lastName}
                          </h4>
                          <h4 className='mt-1'>{companyCheck?.email}</h4>
                          <h4 className='mt-1'>{companyCheck?.isInspector ? 'Inspector' : 'subContractor'}</h4>
                        </div>
                      </div> : ""}

                    </>
                  </div>
                </div>

              </div>
              <div className='d-flex  justify-content-center text-center pt-15 w-100'>
                <button
                  type='reset'
                  onClick={() => {
                    setdetailval(companyCheck)
                    formik.setFieldValue('subContractorId', companyCheck?.id)
                    handleClose()
                  }}
                  id='modal2_close'
                  className='btn btn-primary me-3'

                >
                  Save
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}

export { TasksEditForm }
