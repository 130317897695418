import { useEffect, useState } from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { TagsListFilter } from './TagsListFilter'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const BASE_URL = process.env.REACT_APP_BASE_URL

const TagListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  function extractDetailsFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('details') === 'true'
  }
  const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Tag name', key: 'name' },
    { label: 'Description', key: 'description' },
    // Additional headers...
  ];
  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }
  const id = window.location.href && extractIdFromUrl(window.location.href)

  const [data, setdata] = useState<any>()
  async function Getdata() {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/tags?ProjectId=${id}&pagenumber=1&pagesize=100000000`
      )
      if (response.status === 200) {
        setdata(response.data.data)
      }
    } catch (error) { }
  }
  useEffect(() => {
    Getdata()
  }, [])
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <TagsListFilter />
      <DropdownButton
        as={ButtonGroup}
        className='me-3'
        key={"primary"}
        id={`dropdown-variants`}

        title={
          'Export'
        }
      >
        <Dropdown.Item eventKey="1" onClick={() => {
        const worksheetData = [
          headers.map(h => h.label),
          ...data.map((item:any) => headers.map(h => item[h.key])),
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
        const fileName = `Tags_${currentDate}.xlsx`;
        saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName);
        }}>EXCEL</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={() => {
        const exportData = data.map((item:any) => {
          const exportItem: any = {}; // Explicitly set the type to 'any' or customize a specific type for the exportItem object
          headers.forEach(header => {
            exportItem[header.label] = item[header.key];
          });
          return exportItem;
        });
    
        const csvData = [
          headers.map(h => h.label),
          ...exportData.map((item:any) => headers.map(h => item[h.label])),
        ];
        const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
        const fileName = `Tags_${currentDate}.csv`;
        saveAs(new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' }), fileName);
        }}>CSV</Dropdown.Item>
        {/* <Dropdown.Item eventKey="2" onClick={() => {
         const exportData = data.map((item:any) => {
          const exportItem: any = {}; // Explicitly set the type to 'any' or customize a specific type for the exportItem object
          headers.forEach(header => {
            exportItem[header.label] = item[header.key];
          });
          return exportItem;
        });
    
        const jsonData = JSON.stringify(exportData, null, 2);
        saveAs(new Blob([jsonData], { type: 'application/json;charset=utf-8;' }), 'Tags.json');
        }}>JSON</Dropdown.Item> */}
      </DropdownButton>
      {isDetails?"":<>
         {/* begin::Export */}
       
      {/* end::Export */}
      
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Tag
      </button>
      </>}

   
      {/* end::Add user */}
    </div>
  )
}

export {TagListToolbar}
