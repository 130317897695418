/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { PieChart, Stacked } from '../../../_metronic/partials/widgets'
import { KTCard } from '../../../_metronic/helpers'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListViewProvider } from './core/ListViewProvider'
import { TasksTable } from './table/TasksTable'
import { TagsListHeader } from './components/header/TagsListHeader'
import { ReactSelectStyles } from '../../modules/global/ReactSelectStyle'
import Select from 'react-select'
import { useQuery } from 'react-query'
import TaskCompanyTable from './company-task-table/CompanyTable'
import axios from 'axios'
import TaskRange from './TaskRange'
import * as XLSX from 'xlsx'
import { WorkSheet, WritingOptions } from 'xlsx';
import { PieChartContractor } from '../../../_metronic/partials/widgets/Dashboardcharts/PieChartContractor'
import { StackedContracotr } from '../../../_metronic/partials/widgets/Dashboardcharts/StackedContractor'
import { saveAs } from 'file-saver'
import GuidedTour from '../../modules/global/IntroDriver/IntroDriver'


const API_URL = process.env.REACT_APP_API_URLL
const TAGS_URL = `${API_URL}`
type Props = {
  projectId: any
  role: any
  projectName: any
  check: any
  settasksummry: (p: any) => void
  setCompanyTask: (p: any) => void
  setAgingTasks: (p: any) => void
  settcheck: (p: any) => void
}

const DashboardPage: FC<Props> = ({ projectId, role, projectName, settasksummry, setCompanyTask, setAgingTasks, check }) => {

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10' id="divToPrint" >

        <div className={`${!!projectId === false ? 'col-4' : 'col-12'}`}>
          {(role[0] === 'Inspector Admin' || role[0] === 'Inspector') && (
            <PieChart className='h-md-100' projectId={projectId} settasksummry={settasksummry} />
          )}
          {(role[0] === 'Contractor Admin' || role[0] === 'Contractor') && (
            <PieChartContractor className='h-md-100' projectId={projectId} />
          )}
        </div>
        {!!projectId === false && (
          <div className='col-8'>
            {(role[0] === 'Inspector Admin' || role[0] === 'Inspector') && (
              <>{!!projectId === false ? <Stacked className='h-md-100' /> : ''}</>
            )}
            {(role[0] === 'Contractor Admin' || role[0] === 'Contractor') && (
              <StackedContracotr className='h-md-100' />
            )}
          </div>
        )}
        {(role[0] === 'Inspector Admin' || role[0] === 'Inspector') && projectId && (
          <>
            <div className='col-xxl-3 col-lg-3 col-md-6'>
              <TaskRange projectId={projectId} setAgingTasks={setAgingTasks} />
            </div>
            <div className='col-xxl-9 col-lg-9 col-md-6'>
              <KTCard className='card-flush h-xl-100'>
                <h3 className='card-title align-items-start flex-column p-5 m-2'>
                  <span className='card-label fw-bold fs-3 mb-1'>Company Task Status Breakdown</span>
                  <div className='col-3'>
                    <button hidden={true} id='kt_table_users_refressshTasks'>
                      <i className='bi bi-download'></i>
                    </button>
                  </div>
                </h3>
                <TaskCompanyTable projectId={projectId} setCompanyTask={setCompanyTask} />
              </KTCard>
            </div>
          </>
        )}
        {projectId && (
          <div className='col-xxl-12' hidden={check}>
            <KTCard>
              <TagsListHeader projectName={projectName} />
              <TasksTable />
            </KTCard>
          </div>
        )}
      </div>



      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles
  const [projectId, setProjectId] = useState<any>(null)
  const [projectName, setProjectName] = useState<any>(null)
  const { data: projects } = useQuery(
    `ProjectOvervieww`,
    async () => {
      return await axios.get(`${TAGS_URL}/Dashboard/ProjectOverView`)
    },
    {
      refetchOnWindowFocus: false,
      enabled: role[0] === 'Inspector Admin' || role[0] === 'Inspector',
    }
  )
  const projectsOptions = projects?.data?.data?.projects?.map((project: any) => ({
    value: project.id,
    label: project.name,
  }))
  const intl = useIntl()
  const [taskSummary, settasksummry] = useState<any>()
  const [agingTasks, setAgingTasks] = useState<any>()
  const [companyTask, setCompanyTask] = useState<any>()



  const Headers = [
    {
      label: 'Closed Tasks',
      key: 'closedTasks',
    },
    {
      label: 'Completed Tasks',
      key: 'completedTasks',
    },
    {
      label: 'draft Tasks',
      key: 'draftTasks',
    },
    {
      label: 'Open Tasks',
      key: 'openTasks',
    },
  ]
  async function Getdataexcel(value: any, Headers1: any) {
    console.log(value.data, Headers1)
    const worksheetData = [
      Headers1?.map((h: any) => h.label),
      ...[value?.data].map((item: any) => Headers1?.map((h: any) => item[h.key])),
    ]
    console.log(worksheetData)
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const currentDate = new Date().toISOString().split('T')[0] // Get the current date in YYYY-MM-DD format
    const fileName = `Tasks Summary_${currentDate}.xlsx`
    saveAs(
      new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      fileName
    )
  }
  const HeadersAging = [
    {
      label: 'Tasks',
      key: 'taskCount',
    },
    {
      label: 'Time Range',
      key: 'timeRange',
    },
  ]
  const HeadersStatu = [
    {
      label: 'company Name',
      key: 'companyName',
    },
    {
      label: 'Open Tasks',
      key: 'openTasks',
    },
    {
      label: 'Completed Tasks',
      key: 'completedTasks',
    },
    {
      label: 'Closed Tasks',
      key: 'closedTasks',
    },
    {
      label: 'Total Tasks',
      key: 'totalTasks',
    },
  ]
  async function Getdataexceldata(value: any, Headers1: any, title: any) {
    console.log(value, Headers1)
    const worksheetData = [
      Headers1?.map((h: any) => h.label),
      ...value?.map((item: any) => Headers1?.map((h: any) => item[h.key])),
    ]
    console.log(worksheetData)
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const currentDate = new Date().toISOString().split('T')[0] // Get the current date in YYYY-MM-DD format
    const fileName = `${title}_${currentDate}.xlsx`
    saveAs(
      new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      fileName
    )
  }
  const componentRef = useRef(null);

  const [check, settcheck] = useState<any>(false)

  const handlePrint = () => {
    // Add a class to the target div to hide everything else
    const targetDiv = document.getElementById('divToPrint');
    if (targetDiv) {
      targetDiv.classList.add('print-only');
    }

    // Print the page
    window.print();

    // Remove the class to show everything again
    if (targetDiv) {
      targetDiv.classList.remove('print-only');
    }
    setTimeout(() => {
      settcheck(false)
    }, 500)

  };
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            {(role[0] === 'Inspector Admin' || role[0] === 'Inspector') && (
              <div className='card mb-5 mb-xl-10' >
                <div className='card-body py-3'>
                  <div className='row d-flex justify-content-end'>
                    {!!projectId === true ? <div className='d-flex justify-content-end col-3'>

                      <button

                        type='button'
                        className='btn btn-primary '
                        onClick={() => {
                          settcheck(true)
                          setTimeout(() => {
                            handlePrint()
                          }, 500)
                        }}
                      >
                        <span className='indicator-label'> <i className="fas fa-print text-white fs-2 me-2"></i> Print</span>

                      </button>
                    </div> : ""}
                    <div className='col-3' id="test-2">
                      <Select
                        className='w-100'
                        isClearable={true}
                        options={projectsOptions}
                        onChange={(e: any) => {
                          ; (window as any).projectId = e?.value
                          setProjectId(e?.value)
                          setProjectName(e?.label)
                          document.getElementById('kt_table_users_refressshTasks')?.click()
                        }}
                        value={projectsOptions?.find((project: any) => project.value === projectId)}
                        styles={ReactSelectStyles}
                        placeholder='Filter By Top Projects'
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div >
            <DashboardPage projectId={projectId} role={role} projectName={projectName} settasksummry={settasksummry} setAgingTasks={setAgingTasks} setCompanyTask={setCompanyTask} settcheck={settcheck} check={check} />
            </div>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { DashboardWrapper }
