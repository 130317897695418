import {FC, useMemo} from 'react'
import {ID, KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
const cuurentUser: any = localStorage.getItem('User')
const role: any = JSON.parse(cuurentUser)?.roles
type Props = {
  otherAttachmentCount:number
}

const Attachmentfun: FC<Props> = ({otherAttachmentCount}) => {
console.log("otherAttachmentCount",otherAttachmentCount)
  return (
    <>
{otherAttachmentCount ? 
<div className="btn btn-sm bg-transparant position-relative">
  <KTSVG path="/media/icons/duotune/communication/com008.svg" className="svg-icon-danger svg-icon-2hx" /> 
  <span className="position-absolute top-0 start-100 translate-middle px-2 bg-danger text-white border border-light rounded">{otherAttachmentCount}</span>
  </div>
  :''} 

  </>
  
  )
}

export {Attachmentfun}