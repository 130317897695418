/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {Link} from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router-dom'
import {ModelStateMessage, QueryFetchPost} from '../../global'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  phoneNumber: '',
  Site: '',
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Business Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company Name is required'),
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone number is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowPassword2, setShowPassword2] = useState(false)
  const navigate = useNavigate()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const ChangePassword = async (credentials: typeof initialValues) => {
    setLoading(true)

    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Register`,
      data: credentials,
      method: 'POST',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        navigate('/auth/activateaccount', {replace: true})
        setLoading(false)
      } else {
        ModelStateMessage(response)
        setLoading(false)
      }
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      ChangePassword(values)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      {/*begin::Wrapper*/}

      <div className='w-lg-500px bg-body rounded '>
          <a href='#'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/Frame 13.svg')} className='h-45px' />
          </a>
          <div className='fs-3 w-80 mt-5'>
            Punchlist brings all your building tasks into one simple view to get them done faster
          </div>
          <h1 className=' mt-8'>Sign up</h1>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='w-100 h-100  mt-5'>
            <div className='row '>
              <div className='col-lg-6'>
                <div className='fv-row mb-7'>
                  <label className='form-label fw-bolder text-dark fs-6'>First name</label>
                  <span className='text-danger'> *</span>
                  <input
                    placeholder='First name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.firstName && formik.errors.firstName,
                      },
                      {
                        'is-valid': formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.firstName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='mb-2 w-45'>
                  {/* begin::Form group Lastname */}
                  <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                  <span className='text-danger'> *</span>
                  <input
                    placeholder='Last name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.lastName && formik.errors.lastName,
                      },
                      {
                        'is-valid': formik.touched.lastName && !formik.errors.lastName,
                      }
                    )}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lastName}</span>
                      </div>
                    </div>
                  )}
                  {/* end::Form group */}
                </div>
              </div>
            </div>
            {/* begin::Form group Firstname */}

            {/* end::Form group */}

            <div className='fv-row mb-5'>
              {/* begin::Form group Lastname */}
              <label className='form-label fw-bolder text-dark fs-6'>Phone</label>{' '}
              <span className='text-danger'> *</span>
              <PhoneInput
                preferredCountries={['us', 'ca', 'ma']}
                inputStyle={{
                  width: '100%',
                  padding: '1.7rem',
                  paddingLeft: '4rem',
                  backgroundColor: '#fff',
                  borderColor: '#e1e3ea',
                }}
                country={'us'}
                onChange={(value) => formik.setFieldValue('phoneNumber', value)}
              />
              {/* <input
                placeholder='Phone'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('phoneNumber')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                  },
                  {
                    'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                  }
                )}
              /> */}
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phoneNumber}</span>
                  </div>
                </div>
              )}
              {/* end::Form group */}
            </div>
            <div className='fv-row mb-5'>
              {/* begin::Form group Lastname */}
              <label className='form-label fw-bolder text-dark fs-6'>Company Name</label>
              <span className='text-danger'> *</span>
              <input
                placeholder='Company Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('companyName')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.companyName && formik.errors.companyName,
                  },
                  {
                    'is-valid': formik.touched.companyName && !formik.errors.companyName,
                  }
                )}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyName}</span>
                  </div>
                </div>
              )}
              {/* end::Form group */}
            </div>
            <div className='fv-row mb-5'>
              {/* begin::Form group Lastname */}
              <label className='form-label fw-bolder text-dark fs-6'>Company Website</label>
              <input
                placeholder='Company Website'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('Site')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.Site && formik.errors.Site,
                  },
                  {
                    'is-valid': formik.touched.Site && !formik.errors.Site,
                  }
                )}
              />

              {/* end::Form group */}
            </div>

            {/* begin::Form group Email */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Business Email</label>
              <span className='text-danger'> *</span>
              <input
                placeholder='Business Email'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='fv-row mb-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <span className='text-danger'> *</span>
                <div className='input-group'>
                  <input
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder={'New Password'}
                    className={`form-control h-auto py-3 px-6`}
                    {...formik.getFieldProps('password')}
                  />
                  <span
                    className='input-group-text align-self-center cursor-pointer bg-white'
                    onClick={(e) => setShowPassword(!isShowPassword)}
                  >
                    {!isShowPassword ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
              <span className='text-danger'> *</span>
              <div className='input-group'>
                <input
                  type={isShowPassword2 ? 'text' : 'password'}
                  placeholder={'Confirm Password'}
                  className={`form-control h-auto py-3 px-6`}
                  {...formik.getFieldProps('confirmPassword')}
                />
                <span
                  className='input-group-text align-self-center cursor-pointer bg-white'
                  onClick={(e) => setShowPassword2(!isShowPassword2)}
                >
                  {!isShowPassword2 ? (
                    <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                  ) : (
                    <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                  )}
                </span>
              </div>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>

          {/* begin::Form group */}
          <div className='d-flex justify-content-between align-items-center mt-7'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary w-100 me-1'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Sign up</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <Link
              to='/auth'
              className='btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1'
            >
              Login
            </Link>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}
