import { FC, useEffect } from 'react'

import { BillingCardModalForm } from './BillingCardModalForm'
import { BillingCardModalHeader } from './BillingCardModalHeader'

type Props = {
  setIsOpen: (isOpen: boolean) => void
}


const BillingCardModal: FC<Props> = ({ setIsOpen }) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >

        <div className='modal-dialog modal-dialog-centered mw-750px'>
          <div className='modal-content'>
            <BillingCardModalHeader setIsOpen={setIsOpen} />
            <div className='modal-body mx-5 mx-xl-5 my-7'>
              <BillingCardModalForm setIsOpen={setIsOpen} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { BillingCardModal }
