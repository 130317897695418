import { useListView } from '../../core/ListViewProvider'
import { TeamsListToolbar } from './TeamsListToolbar'
import { TeamsListGrouping } from './TeamsListGrouping'
import { TeamsListSearchComponent } from './TeamsListSearchComponent'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URLL

const TeamsListHeader = () => {
  const [showSearchComponent, setShowSearchComponent] = useState(false)
  const params = useParams<any>()
  const { id } = params
  const { selected } = useListView()
  return (
    <div className='border-0 pt-6'>
      <div className='card-header' style={{alignItems: "baseline"}}>
        {id && (
          <h5 className='card-title fw-bold'>Project name : <span className='fw-bold text-gray-600 fs-6 ms-1'>{(window as any).projectName}</span></h5>
        )}
        <div className='card-toolbar pb-9 justify-content-end '>
          {selected.length > 0 ? (
            <TeamsListGrouping />
          ) : (
            <TeamsListToolbar
              setShowSearchComponent={setShowSearchComponent}
              showSearchComponent={showSearchComponent}
            />
          )}
        </div>
      </div>

      <TeamsListSearchComponent showSearchComponent={showSearchComponent} />
    </div>
  )
}

export { TeamsListHeader }
