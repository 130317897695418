import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import SearchPage from './DetailSearch'

const teamsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Global Search',
    path: '/global-search/search',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SearchePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='search'
          element={
            <>
              <PageTitle breadcrumbs={teamsBreadcrumbs}>Global Search</PageTitle>
              <SearchPage />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/project-management/projects' />} />
    </Routes>
  )
}

export default SearchePage
