/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
const BASE_URL = process.env.REACT_APP_BASE_URL

const SidebarMenuMain = () => {
  // const intl = useIntl()
  const [menu, setmenu] = useState<any>()
  async function getmenu() {
    try {
      const response = await axios.get(`${BASE_URL}/api/Generic/Menu`)
      if (response.status === 200) {
        setmenu(response?.data.permissions)
      }
    } catch (error) { }
  }
  useEffect(() => {
    getmenu()
  }, [])

  const handleCheckBuilVersion = async () => {
    const version = process.env.REACT_APP_VERSION
    const API_URL = process.env.REACT_APP_API_URLL
    const res = await axios.get(`${API_URL}/Generic/AppVersion`)
    console.log(window.location.hostname === 'localhost')
    if (window.location.hostname !== 'localhost') {
      if (res.data !== version) {
        Swal.fire({
          text: 'New build version released. clearing cache now ...',
          icon: 'info',
          showConfirmButton: true,
          timer: 3000,
          width: '25rem',
        })
        setTimeout(function () {
          if (caches) {
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name);
              }
            });
          }
          window.location.reload();
        }, 3000);
      }
    }
  }

  return (
    <>
      {menu?.map((item: any) => {
        return (
          <div
          id={`${item.name}`}
            key={item.id}
            onClick={handleCheckBuilVersion}
          >
            <SidebarMenuItem to={item?.link} icon={item?.icon} title={item?.name} />
          </div>
        )
      })}
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      <SidebarMenuItem to='/project-management/projects' icon='element-10' title='Projects' />
      <SidebarMenuItem to='/team-management/teams' icon='profile-user' title='Teams' />
      <SidebarMenuItem to='/task-management/tasks' icon='code' title='Tasks' />
      <SidebarMenuItem
        to='/subcontractor-management/subcontractors'
        icon='profile-user'
        title='Subcontractor'
      />

      <SidebarMenuItem to='/tag-management/tags' icon='code' title='Tags' /> */}
    </>
  )
}

export { SidebarMenuMain }
