import {UsersListWrapper} from '../teams-list/TeamsList'
import {KTIcon} from '../../../../../_metronic/helpers'

const Step3 = (props: any) => {
  function extractEditFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('edit') === 'true'
  }
  const isEdit = window.location.href && extractEditFromUrl(window.location.href)
  return (
    <div className='w-100'>
      <UsersListWrapper />
      <div className='d-grid gap-2 d-md-flex justify-content-md-end mt-10'>
        {isEdit === false ? (
          <div className='d-grid gap-2 d-md-flex justify-content-md-end mt-10'>
            <button
              type='reset'
              onClick={() => props.setSteps(props.Step - 1)}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1' />
              Back
            </button>
            <button
              type='button'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary'
              onClick={() => props.setSteps(props.Step + 1)}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='indicator-label'>Continue</span>
                <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
              </span>
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export {Step3}
