import React, {FC} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {BillingModal} from './billing-edit-modal/BillingModal'
import {useQuery} from 'react-query'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URLL

const getPlans = (): Promise<any> => {
  return axios.get(`${API_URL}/Billing/plans`)
}
const BillingPlanSchema = Yup.object().shape({
  planId: Yup.string().required('Please select a plan'),
})

type Props = {
  nextStep: () => void
  formData: BillingModal
  setFormData: (formData: any) => void
  discount: any
  setDiscount: (discount: any) => void
  currentSelectedPlan: any
  setCurrentSelectedPlan: (currentSelectedPlan: any) => void
}

const BillingPlanForm: FC<Props> = ({
  nextStep,
  formData,
  setFormData,
  discount,
  setDiscount,
  currentSelectedPlan,
  setCurrentSelectedPlan,
}) => {
  const [errorCoupon, setErrorCoupon] = React.useState(false)
  const [Commercial, setCommercial] = React.useState('Commercial')
  const [Type, setType] = React.useState('Monthly')
  const [CommercialMonthly, setCommercialMonthly] = React.useState([])
  const [CommercialYearly, setCommercialYearly] = React.useState([])
  const [ResidentialMonthly, setResidentialMonthly] = React.useState([])
  const [ResidentialYearly, setResidentialYearly] = React.useState([])
  const formik = useFormik({
    initialValues: formData,
    validationSchema: BillingPlanSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (values.isCoupon && !values.coupon) {
        setErrorCoupon(true)
        return
      }
      nextStep()
      discount
        ? setFormData({
            ...formData,
            isCoupon: values.isCoupon,
            planId: values.planId,
            coupon: values.coupon,
            planName: values.planName,
            planPrice: discount,
          })
        : setFormData({
            ...formData,
            isCoupon: values.isCoupon,
            planId: values.planId,
            coupon: values.coupon,
            planName: values.planName,
            planPrice: values.planPrice,
          })
    },
  })

  const {data: plans, isLoading} = useQuery('BillingPlan', getPlans, {
    refetchOnWindowFocus: false,
  })

  React.useEffect(() => {
    if (plans?.data?.plans) {
      const CommercialMonthly = plans?.data?.plans?.filter(
        (plan: any) =>
          plan?.reccuring === 'month' &&
          (plan?.name === 'Gold Monthly' || plan?.name === 'Platinum Monthly')
      )
      setCommercialMonthly(CommercialMonthly)
      const CommercialYearly = plans?.data?.plans?.filter(
        (plan: any) =>
          plan?.reccuring === 'year' &&
          (plan?.name === 'Gold Annual' || plan?.name === 'Platinum Annual')
      )
      setCommercialYearly(CommercialYearly)
      const ResidentialMonthly = plans?.data?.plans?.filter(
        (plan: any) => plan?.reccuring === 'month' && plan?.name === 'Residential Monthly'
      )
      setResidentialMonthly(ResidentialMonthly)
      const ResidentialYearly = plans?.data?.plans?.filter(
        (plan: any) => plan?.reccuring === 'year' && plan?.name === 'Residential Annual'
      )
      setResidentialYearly(ResidentialYearly)
    }
  }, [plans])
  React.useEffect(() => {
    console.log('CommercialMonthly', CommercialMonthly)
  }, [CommercialMonthly])

  const handleApplyCoupon = async () => {
    try {
      await axios
        .get(
          `${API_URL}/Billing/ValidateCoupon?planId=${formik.values.planId}&coupon=${formik.values.coupon}`
        )
        .then((res) => {
          setDiscount(res.data)
          Swal.fire({
            text: `Coupon applied successfully`,
            icon: 'success',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
        })
    } catch (error: any) {
      Swal.fire({
        text: `${error.response.data.message}`,
        icon: 'error',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
    }
  }

  return (
    <>
      <form className='form'>
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='row mb-10 w-100'>
            <div className='text-danger my-2'>
              {formik.errors.planId && formik.touched.planId && (
                <span className='text-danger'>* {formik.errors.planId}</span>
              )}
            </div>
            {isLoading && <Skeleton count={4} height={90} style={{marginBottom: '13px'}} />}
            {!isLoading && (
              <div className='d-flex flex-column'>
                {/*begin::Heading*/}
                <div className='mb-13 text-center'>
                  <h1 className='fs-2hx fw-bold mb-5'>Choose Your Plan</h1>
                  <div className='text-gray-600 fw-semibold fs-5'>
                    If you need more info about our pricing, please check{' '}
                    <span
                      className='link-primary fw-bold cursor-pointer'
                      onClick={() => {
                        window.open('https://mypunchlists.com/#pricing', '_blank')
                      }}
                    >
                      Pricing Guidelines
                    </span>
                    .
                  </div>
                </div>
                {/*end::Heading*/}
                {/*begin::Nav group*/}
                <div
                  className='nav-group nav-group-outline mx-auto mb-2'
                  data-kt-buttons='true'
                  data-kt-initialized={1}
                >
                  <button
                    className={`btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 ${
                      Commercial === 'Commercial' && 'active'
                    }`}
                    type='button'
                    onClick={() => {
                      setCommercial('Commercial')
                    }}
                  >
                    Commercial
                  </button>
                  <button
                    className={`btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 ${
                      Commercial === 'Residential' && 'active'
                    }`}
                    type='button'
                    onClick={() => {
                      setCommercial('Residential')
                    }}
                  >
                    Residential
                  </button>
                </div>
                {/*end::Nav group*/}
                {/*begin::Nav group*/}
                <div
                  className='nav-group nav-group-outline mx-auto mb-15'
                  data-kt-buttons='true'
                  data-kt-initialized={1}
                >
                  <button
                    className={`btn btn-color-gray-600 btn-active btn-active-secondary px-6 py-3 me-2 ${
                      Type === 'Monthly' && 'active'
                    }`}
                    type='button'
                    onClick={() => {
                      setType('Monthly')
                    }}
                  >
                    Monthly
                  </button>
                  <button
                    className={`btn btn-color-gray-600 btn-active btn-active-secondary px-6 py-3 me-2 ${
                      Type === 'Yearly' && 'active'
                    }`}
                    type='button'
                    onClick={() => {
                      setType('Yearly')
                    }}
                  >
                    Annual
                  </button>
                </div>
                {/*end::Nav group*/}
              </div>
            )}
            {Commercial === 'Commercial' &&
              Type === 'Monthly' &&
              CommercialMonthly.length > 0 &&
              CommercialMonthly?.map((plan: any) => (
                <label
                  className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mx-3  mb-6 ${
                    formik.values.planId === plan.id && 'active'
                  }`}
                >
                  <div className='d-flex align-items-center me-2'>
                    <div className='form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='planId'
                        checked={formik.values.planId === plan.id}
                        value={plan.id}
                        onChange={(e) => {
                          formik.setFieldValue('planId', parseInt(e.target.value))
                          formik.setFieldValue('planName', plan.name)
                          formik.setFieldValue('planPrice', plan.amount)
                          setCurrentSelectedPlan(null)
                          setDiscount(null)
                        }}
                      />
                    </div>
                    <div className='flex-grow-1'>
                      <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                        {plan?.name}
                      </div>
                    </div>
                  </div>
                  <div className='ms-5'>
                    <span className='fs-2x fw-bold'>
                      {currentSelectedPlan === plan.id && (discount || discount === 0)
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(discount)
                        : new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                    </span>
                    <span className='fs-7 opacity-50'>
                      /<span data-kt-element='period'>{plan?.reccuring}</span>
                    </span>
                    <br />
                    {currentSelectedPlan === plan.id && (discount || discount === 0) && (
                      <>
                        <span className='fs-3 fw-bolder text-warning text-decoration-line-through'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                        </span>
                      </>
                    )}
                  </div>
                </label>
              ))}
            {Commercial === 'Commercial' &&
              Type === 'Yearly' &&
              CommercialYearly?.length > 0 &&
              CommercialYearly?.map((plan: any) => (
                <label
                  className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mx-3  mb-6 ${
                    formik.values.planId === plan.id && 'active'
                  }`}
                >
                  <div className='d-flex align-items-center me-2'>
                    <div className='form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='planId'
                        checked={formik.values.planId === plan.id}
                        value={plan.id}
                        onChange={(e) => {
                          formik.setFieldValue('planId', parseInt(e.target.value))
                          formik.setFieldValue('planName', plan.name)
                          formik.setFieldValue('planPrice', plan.amount)
                          setCurrentSelectedPlan(null)
                          setDiscount(null)
                        }}
                      />
                    </div>
                    <div className='flex-grow-1'>
                      <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                        {plan?.name}
                      </div>
                    </div>
                  </div>
                  <div className='ms-5'>
                    <span className='fs-2x fw-bold'>
                      {currentSelectedPlan === plan.id && (discount || discount === 0)
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(discount)
                        : new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                    </span>
                    <span className='fs-7 opacity-50'>
                      /<span data-kt-element='period'>{plan?.reccuring}</span>
                    </span>
                    <br />
                    {currentSelectedPlan === plan.id && (discount || discount === 0) && (
                      <>
                        <span className='fs-3 fw-bolder text-warning text-decoration-line-through'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                        </span>
                      </>
                    )}
                  </div>
                </label>
              ))}
            {Commercial === 'Residential' &&
              Type === 'Monthly' &&
              ResidentialMonthly.length > 0 &&
              ResidentialMonthly?.map((plan: any) => (
                <label
                  className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mx-3  mb-6 ${
                    formik.values.planId === plan.id && 'active'
                  }`}
                >
                  <div className='d-flex align-items-center me-2'>
                    <div className='form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='planId'
                        checked={formik.values.planId === plan.id}
                        value={plan.id}
                        onChange={(e) => {
                          formik.setFieldValue('planId', parseInt(e.target.value))
                          formik.setFieldValue('planName', plan.name)
                          formik.setFieldValue('planPrice', plan.amount)
                          setCurrentSelectedPlan(null)
                          setDiscount(null)
                        }}
                      />
                    </div>
                    <div className='flex-grow-1'>
                      <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                        {plan?.name}
                      </div>
                    </div>
                  </div>
                  <div className='ms-5'>
                    <span className='fs-2x fw-bold'>
                      {currentSelectedPlan === plan.id && (discount || discount === 0)
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(discount)
                        : new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                    </span>
                    <span className='fs-7 opacity-50'>
                      /<span data-kt-element='period'>{plan?.reccuring}</span>
                    </span>
                    <br />
                    {currentSelectedPlan === plan.id && (discount || discount === 0) && (
                      <>
                        <span className='fs-3 fw-bolder text-warning text-decoration-line-through'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                        </span>
                      </>
                    )}
                  </div>
                </label>
              ))}
            {Commercial === 'Residential' &&
              Type === 'Yearly' &&
              ResidentialYearly?.length > 0 &&
              ResidentialYearly?.map((plan: any) => (
                <label
                  className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mx-3  mb-6 ${
                    formik.values.planId === plan.id && 'active'
                  }`}
                >
                  <div className='d-flex align-items-center me-2'>
                    <div className='form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='planId'
                        checked={formik.values.planId === plan.id}
                        value={plan.id}
                        onChange={(e) => {
                          formik.setFieldValue('planId', parseInt(e.target.value))
                          formik.setFieldValue('planName', plan.name)
                          formik.setFieldValue('planPrice', plan.amount)
                          setCurrentSelectedPlan(null)
                          setDiscount(null)
                        }}
                      />
                    </div>
                    <div className='flex-grow-1'>
                      <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                        {plan?.name}
                      </div>
                    </div>
                  </div>
                  <div className='ms-5'>
                    <span className='fs-2x fw-bold'>
                      {currentSelectedPlan === plan.id && (discount || discount === 0)
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(discount)
                        : new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                    </span>
                    <span className='fs-7 opacity-50'>
                      /<span data-kt-element='period'>{plan?.reccuring}</span>
                    </span>
                    <br />
                    {currentSelectedPlan === plan.id && (discount || discount === 0) && (
                      <>
                        <span className='fs-3 fw-bolder text-warning text-decoration-line-through'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(plan.amount)}
                        </span>
                      </>
                    )}
                  </div>
                </label>
              ))}

            {!isLoading && (
              <div className='d-flex align-items-center my-8'>
                <div className='form-check form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={formik.values.isCoupon}
                    onChange={() => {
                      formik.setFieldValue('isCoupon', !formik.values.isCoupon)
                    }}
                    id='isCouponFor'
                  />
                </div>
                <div className='flex-grow-1'>
                  <label
                    className='text-gray-800 text-hover-primary fw-bold fs-6 cursor-pointer'
                    htmlFor='isCouponFor'
                  >
                    I have a discount code
                  </label>
                </div>
              </div>
            )}

            {formik.values.isCoupon && (
              <div className='col mb-5'>
                <div className='form-group'>
                  <div className='d-flex'>
                    <input
                      type='text'
                      className='form-control form-control-solid me-3'
                      placeholder='Enter discount code'
                      name='coupon'
                      value={formik.values.coupon}
                      onChange={(e) => {
                        formik.setFieldValue('coupon', e.target.value)
                        setErrorCoupon(false)
                      }}
                    />
                    <button
                      disabled={!formik.values.coupon}
                      className='btn btn-sm btn-primary me-3'
                      type='button'
                      onClick={() => {
                        if (formik.values.planId && formik.values.coupon) {
                          handleApplyCoupon()
                          setCurrentSelectedPlan(formik.values.planId)
                        } else {
                          Swal.fire({
                            text: `Please select a plan`,
                            icon: 'warning',
                            showConfirmButton: true,
                            timer: 2000,
                            width: '25rem',
                          })
                        }
                      }}
                    >
                      Apply
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        formik.setFieldValue('coupon', '')
                        setErrorCoupon(false)
                        setDiscount(null)
                        setCurrentSelectedPlan(null)
                      }}
                    >
                      Reset
                    </button>
                  </div>
                  {errorCoupon && <span className='text-danger'>* Please Enter Discount Code</span>}
                </div>
              </div>
            )}

            <div className='col-12 d-flex justify-content-end my-5'>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  formik.handleSubmit()
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {BillingPlanForm}
