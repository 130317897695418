import { FC } from "react"
import { KTIcon } from "../../../../_metronic/helpers"

type Props = {
  setActivitiesId: any
}
const ActivitiesModalHeader: FC<Props> = ({setActivitiesId}) => {

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>
        Activities
      </h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setActivitiesId(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export { ActivitiesModalHeader }
