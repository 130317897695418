import React, {FC, useEffect} from 'react'
import {useFormik} from 'formik'
import {BillingModal} from './billing-edit-modal/BillingModal'
import axios from 'axios'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URLL

type Props = {
  prevStep: () => void
  formData: BillingModal
  setIsOpen: (isOpen: boolean) => void
  refetch: () => void
  cardToken: string
  cardNumber: any
  discount: any
}
const BillingSummary: FC<Props> = ({
  prevStep,
  formData,
  setIsOpen,
  refetch,
  cardToken,
  cardNumber,
  discount,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)

    const data = {
      planId: formData.planId,
      cardToken: cardToken,
      coupon: formData.coupon,
      address1: formData.address1,
      address2: formData.address2,
      zipcode: formData.zipcode,
      city: formData.city,
      state: formData.state,
      country: formData.country,
    }

    try {
      await axios.post(`${API_URL}/Billing/Subscribe`, data).then((res) => {
        refetch()
        Swal.fire({
          text: `Your subscription is active now!`,
          icon: 'success',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
        setIsOpen(false)
      })
    } catch (error: any) {
      Swal.fire({
        text: error.response.data || error.response.data.message,
        icon: 'error',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, {setSubmitting}) => {
      handleSubmit()
    },
  })

  return (
    <>
      <form className='form'>
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='row mb-5'>
            <div className='col-12 mb-3'>
              <div className='alert alert-primary fw-bold' role='alert'>
                Billing information
              </div>
            </div>
            {/* <div className="col-12 mb-3">
                            <span className="fs-6 fw-bold form-label">Full name: </span>
                            <span className="text-muted">{formData.cardName}</span>
                        </div> */}
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>Address Line 1: </span>
              <span className='text-muted'>{formData.address1}</span>
            </div>
            {formData.address2 && (
              <div className='col-12 mb-3'>
                <span className='fs-6 fw-bold form-label'>Address Line 2: </span>
                <span className='text-muted'>{formData.address2}</span>
              </div>
            )}
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>City: </span>
              <span className='text-muted'>{formData.city}</span>
            </div>
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>State: </span>
              <span className='text-muted'>{formData.state}</span>
            </div>
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>Zip code: </span>
              <span className='text-muted'>{formData.zipcode}</span>
            </div>
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>Country: </span>
              <span className='text-muted'>{formData.country}</span>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-12'>
              <div className='alert alert-primary fw-bold' role='alert'>
                Plan information
              </div>
            </div>
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>Current plan: </span>
              <span className='text-muted'>{formData.planName}</span>
            </div>
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>Price: </span>
              <span className='text-muted'>
                {discount || discount === 0
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(discount || 0)
                  : new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(formData.planPrice || 0)}
              </span>
            </div>
            <div className='col-12 mb-3'>
              <span className='fs-6 fw-bold form-label'>Credit card: </span>
              <span className='text-muted'>
                ************
                {cardNumber}
              </span>
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <button className='btn btn-sm btn-secondary' onClick={prevStep}>
              Previous
            </button>
            <button
              disabled={isSubmitting}
              type='button'
              className='btn btn-sm btn-primary'
              onClick={() => {
                formik.handleSubmit()
              }}
            >
              {isSubmitting ? 'Confirming...' : 'Confirm'}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export {BillingSummary}
