import React, {useState, useEffect} from 'react'
// import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ModelStateMessage, QueryFetchPost, TimeConversion} from '../../global'
import Swal from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'
// import {Formik} from 'formik'
import {CountryStateGlobal} from '../../project/components/CountryState'
import Select from 'react-select';
import {ReactSelectStyles} from '../../global/ReactSelectStyle'
// import KTImageInput from "../../../_metronic/imageInput";

// const profileDetailsSchema = Yup.object().shape({
//   firstName: Yup.string().required('First name is required'),
//   lastName: Yup.string().required('Last name is required'),
//   companyName: Yup.string().required('Company name is required'),
//   phoneNumber: Yup.string().required('Contact phone is required'),
//   site: Yup.string().required('Company site is required'),
//   city: Yup.string().required('City is required'),
//   zipCode: Yup.string().required('Zip code is required'),
//   // currency: Yup.string().required('Currency is required'),
// })
export type OptionType = {
  value: any
  label: any
}
const options: OptionType[] = [
  {value: -4, label: 'GMT-4 '},
  {value: -5, label: 'GMT-5 '},
  {value: -6, label: 'GMT-6 '},
  {value: -7, label: 'GMT-7 '},
  {value: -8, label: 'GMT-8 '},
  {value: -9, label: 'GMT-9 '},
  {value: -10, label:'GMT-10'},
]
interface Model {
  firstName: string | undefined
  lastName: string | undefined
  phoneNumber: string | undefined
  email: string | undefined
  companyName: string | undefined
  address1: string
  address2: string
  city: string | undefined
  country: any
  profilePicture: null
  role: string | undefined
  site: string | undefined
  state: any
  zipCode: string | undefined
}
const BASE_URL = process.env.REACT_APP_BASE_URL
type SettingsProps = {
  GetProfiles: () => Promise<void>
  profile: Model | undefined
}
const Settings = (props: SettingsProps) => {
  const [loading, setLoading] = useState(false)
  const AUTH_LOCAL_STORAGE_KEY = 'profilePicture'
  const [image, setImage] = useState<string | undefined>()
  const [imageApi, setimageApi] = useState<string | null>()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [PName, setPName] = useState('')
  const [lname, setlname] = useState('')
  const [company, setcompany] = useState('')
  const [companyname, setcompanyname] = useState('')
  const [companysite, setcompanysite] = useState('')
  const [email, setemail] = useState('')
  const [city, setcity] = useState('')
  const [PzipCode, setzipCode] = useState('')
  const [countryy, setcountryy] = useState('')
  const [statee, setstatee] = useState('')
  const [Address1, setAddress1] = useState('')
  const [Address2, setAddress2] = useState('')

  const [timezone, settimezone] = useState()
  const [initialValues, SetinitialValues] = useState<Model>({
    ...props.profile,
    firstName: props.profile?.firstName,
    lastName: props.profile?.lastName,
    phoneNumber: props.profile?.phoneNumber,
    email: '',
    companyName: props.profile?.companyName,
    address1: '',
    address2: '',
    city: props.profile?.city,
    country: props?.profile?.country,
    profilePicture: null,
    role: props?.profile?.role,
    site: props?.profile?.site,
    state: props?.profile?.state,
    zipCode: props?.profile?.zipCode,
  })
  const GetProfile = async () => {
    setimageApi(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY))
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        SetinitialValues(response[1].profile)
        setimageApi(response[1].profile.profilePicture)
        localStorage.removeItem('profilePicture')
        localStorage.setItem('profilePicture', response[1].profile.profilePicture)
      } else {
        ModelStateMessage(response)
        setLoading(false)
      }
    })
  }
  useEffect(() => {
    GetProfile()
    setimageApi(undefined)
  }, [])
  const EditeProfile = async (credentials: typeof initialValues) => {
    handleChangeProfilePic()
    var time :number
    if(timezone){
      time=timezone
    }else{
      time=-5
    }
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: {
        phoneNumber: company,
        firstName: PName,
        lastName: lname,
        companyName: companyname,
        site: companysite,
        address1: Address1,
        address2: Address2,
        email: email,
        city: city,
        state: statee,
        zipCode: PzipCode,
        country: countryy,
        timeZone:time,
      },
      method: 'PUT',
      key: 'EditeProfile',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        document?.getElementById('testtest')?.click();
        if(timezone){
          localStorage.removeItem('TimeZone')
          localStorage.setItem("TimeZone", `GMT${timezone}`)
        }else{
          localStorage.removeItem('TimeZone')
          localStorage.setItem("TimeZone", `GMT-5`)
        }
        setTimeout(() => {
          document?.getElementById('testtest')?.click();
        }, 600);
        props.GetProfiles()
        GetProfile()
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: 'Profile updated successfully',
        })
        setLoading(false)
      } else {
        ModelStateMessage(response)
        setLoading(false)
      }
    })
  }
  const removePic = () => {
    setImage(undefined)
    setimageApi(undefined)
  }
  const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles[0]

  function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedImage = event.target.files?.[0]
    console.log(event.target.files?.[0])
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
    if (selectedImage) {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result?.toString())
      }
      reader.readAsDataURL(selectedImage)
    }
  }
  const handleChangeProfilePic = async () => {
    if (!selectedFile) {
      return
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    await axios
      .post(`${BASE_URL}/api/Account/ProfilePicture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 200) {
        
          QueryFetchPost({
            link: `${BASE_URL}/api/Account/Profile`,
            data: undefined,
            method: 'GET',
            key: 'RegisterEmail',
            signal: undefined,
            isFormData: false,
          }).then((response) => {
            if (response[0] === 200) {
              localStorage.removeItem('profilePicture')
              localStorage.setItem('profilePicture', response[1].profile.profilePicture)
              console.log('hi', response[1].profile)
              SetinitialValues(response[1].profile)
              setimageApi(response[1].profile.profilePicture)
            } else {
              ModelStateMessage(response)
              setLoading(false)
            }
          })
        }
        // Handle other status codes
      })
    props.GetProfiles()
    GetProfile()
  }
  function getprofile() {
    QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        console.log(response[1].profile.timeZone)
        setPName(response[1].profile.firstName)
        setlname(response[1].profile.lastName)
        setcompany(response[1].profile.phoneNumber)
        setcompanyname(response[1].profile.companyName)
        setcompanysite(response[1].profile.site)
        setemail(response[1].profile.email)
        setcity(response[1].profile.city)
        setzipCode(response[1].profile.zipCode)
        setcountryy(response[1].profile.country)
        setstatee(response[1].profile.state)
        settimezone(response[1].profile.timeZone)
        setAddress2(response[1].profile.address2)
        setAddress1(response[1].profile.address1)
       

      } else {
        ModelStateMessage(response)
        setLoading(false)
      }
    })
  }
  // const currentDate = new Date();
  useEffect(() => {
    getprofile()
  }, [])
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setLoading(true)
      EditeProfile(values)
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Settings </h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                >
                  {/*begin::Image preview wrapper*/}
                  {imageApi || image ? (
                    <>
                      {image ? (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${image})`}}
                        />
                      ) : (
                        <>
                          {imageApi ? (
                            <img
                              className='image-input-wrapper w-125px h-125px'
                              src={toAbsoluteUrl(`${imageApi}`)}
                              alt='Metornic'
                            />
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: 'url(/assets/media/avatars/300-1.jpg)'}}
                    />
                  )}

                  {/*end::Image preview wrapper*/}
                  {/*begin::Edit button*/}
                  <label
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Change avatar'
                  >
                    <i className='ki-duotone ki-pencil fs-6'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                    {/*begin::Inputs*/}
                    <input
                      type='file'
                      id='profile_avatar'
                      name='profile_avatar'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleImageUpload}
                    />
                    <input type='hidden' name='avatar_remove' />
                    {/*end::Inputs*/}
                  </label>
                  {/*end::Edit button*/}
                  {/*begin::Cancel button*/}
                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Cancel avatar'
                  >
                    <i className='ki-outline ki-cross fs-3' />
                  </span>
                  {/*end::Cancel button*/}
                  {/*begin::Remove button*/}
                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Remove avatar'
                    onClick={removePic}
                  >
                    <i className='ki-outline ki-cross fs-3' />
                  </span>
                  {/*end::Remove button*/}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('firstName')}
                      value={PName}
                      onChange={(e) => {
                        setPName(e.target.value)
                      }}
                    />
                    {/* {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstName}</div>
                      </div>
                    )} */}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('lastName')}
                      value={lname}
                      onChange={(e) => {
                        setlname(e.target.value)
                      }}
                    />
                    {/* {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastName}</div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...formik.getFieldProps('companyName')}
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value)
                  }}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.companyName}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company name</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company name'
                  disabled={role=== "Contractor"|| role=== "Inspector"?true:false}
                  {...formik.getFieldProps('companyName')}
                  value={companyname}
                  onChange={(e) => {
                    setcompanyname(e.target.value)
                  }}
                />
        
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.companyName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <PhoneInput
                  preferredCountries={['us', 'ca', 'ma']}
                  inputStyle={{
                    width: '100%',
                    padding: '1.7rem',
                    paddingLeft: '4rem',
                    backgroundColor: '#f9f9f9',
                    borderColor: '#f9f9f9',
                  }}
                  country={'us'}
                  value={company}
                  onChange={(value) => {
                    setcompany(value)
                  }}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Company Site</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company website'
                  {...formik.getFieldProps('site')}
                  value={companysite}
                  onChange={(e) => {
                    setcompanysite(e.target.value)
                  }}
                  disabled={role=== "Contractor"|| role=== "Inspector"?true:false}
                />
                {formik.touched.site && formik.errors.site && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.site}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Address 1</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address 1'
                  {...formik.getFieldProps('Address1')}
                  value={Address1}
                  onChange={(e) => {
                    setAddress1(e.target.value)
                  }}
                  disabled={role=== "Contractor"|| role=== "Inspector"?true:false}
                />
        
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Address 2</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address 2'
                  {...formik.getFieldProps('Address2')}
                  value={Address2}
                  onChange={(e) => {
                    setAddress2(e.target.value)
                  }}
                  disabled={role=== "Contractor"|| role=== "Inspector"?true:false}
                />
            
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company City'
                  {...formik.getFieldProps('city')}
                  value={city}
                  onChange={(e) => {
                    setcity(e.target.value)
                  }}
                  disabled={role=== "Contractor"|| role=== "Inspector"?true:false}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Zip code</span>
              </label>
              <div className='col-lg-8 fv-row'>
              <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company Zip code'
                  {...formik.getFieldProps('zipCode')}
                  value={PzipCode}
                  onChange={(e) => {
                    setzipCode(e.target.value)
                  }}
                  disabled={role === ""}
                />
                {formik.touched.zipCode && formik.errors.zipCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.zipCode}</div>
                  </div>
                )}
              </div>
            </div>

           
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Country  State/Province</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <CountryStateGlobal
                  setstatee={setstatee}
                  statee={statee}
                  setcountryy={setcountryy}
                  countryy={countryy}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Time zone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <Select
                  options={options}
                  value={options.find((obj) => obj.value === timezone)}
                  onChange={(e: any) => {
                    console.log( e?.value)
                    settimezone(e?.value)
                  }}
                  isClearable={true}
                  styles={ReactSelectStyles}
                />
                
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {Settings}
