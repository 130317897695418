import {FC} from 'react'
// import { UsersListWrapper } from '../users-list/TagsList'
import {KTIcon} from '../../../../../_metronic/helpers'

import {UsersListWrapper} from '../../../apps/tag-management/users-list/TagsList'
const Step4 = (props: any) => {
  function extractEditFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('edit') === 'true'
  }
  const isEdit = window.location.href && extractEditFromUrl(window.location.href)
  return (
    <div className='w-100'>
      <UsersListWrapper />
      {isEdit === false ? (
        <div className='d-grid gap-2 d-md-flex justify-content-md-end mt-10'>
          <button
            type='reset'
            onClick={() => props.setSteps(props.Step - 1)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Back
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export {Step4}
