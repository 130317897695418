import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {BillingModal} from './billing-edit-modal/BillingModal'
import {useQuery} from 'react-query'
import axios from 'axios'
import {ReactSelectStyles} from '../../../global/ReactSelectStyle'
import Select from 'react-select'
import Swal from 'sweetalert2'
import Payment from '../stripe/Payment'

const API_URL = process.env.REACT_APP_API_URLL

const getCountries = (): Promise<any> => {
  return axios.get(`${API_URL}/Generic/Countries`)
}
const getProfileDetails = (): Promise<any> => {
  return axios.get(`${API_URL}/Account/Profile`)
}
const AddressSchema = Yup.object().shape({
  address1: Yup.string().required('Address Line 1 is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
  zipcode: Yup.string().required('Zip Code is required'),
  cardName: Yup.string().required('Name on card is required'),
})

type Props = {
  nextStep: () => void
  prevStep: () => void
  formData: BillingModal
  setFormData: (formData: BillingModal) => void
  sameAddress: boolean
  setSameAddress: (sameAddress: boolean) => void
  setCrdToken: (cardToken: any) => void
  setCardNumber: (cardNumber: any) => void
}
const BillingAddressForm: FC<Props> = ({
  nextStep,
  prevStep,
  formData,
  setFormData,
  setSameAddress,
  sameAddress,
  setCrdToken,
  setCardNumber
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSubmit = () => {
    !sameAddress &&
      setFormData({
        ...formData,
        address1: formik?.values.address1,
        address2: formik?.values.address2,
        city: formik?.values.city,
        state: formik?.values.state,
        country: formik?.values.country,
        zipcode: formik?.values.zipcode,
        countryId: formik?.values.countryId,
        sameAddress: formik?.values.sameAddress,
      })
    sameAddress &&
      setFormData({
        ...formData,
        address1: profile?.data?.profile.address1,
        address2: profile?.data?.profile.address2,
        city: profile?.data?.profile.city,
        state: profile?.data?.profile.state,
        country: profile?.data?.profile.country,
        zipcode: profile?.data?.profile.zipCode,
        sameAddress: formik?.values.sameAddress,
      })
    nextStep()
  }
  const formik = useFormik({
    initialValues: formData,
    validationSchema: sameAddress === true ? undefined : AddressSchema,
    onSubmit: async (values, {setSubmitting}) => {
      document.getElementById('pay_now')?.click()
    },
  })

  const {data: countries} = useQuery('countries', getCountries, {
    refetchOnWindowFocus: false,
  })
  const {data: profile} = useQuery('profileDetailes', getProfileDetails, {
    refetchOnWindowFocus: false,
    enabled: sameAddress === true,
  })

  const {data: states, refetch} = useQuery(
    'states',
    () => axios.get(`${API_URL}/Generic/States?id=${formik.values.countryId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!formik.values.countryId,
    }
  )

  useEffect(() => {
    !!formik.values.countryId && refetch()
  }, [formik.values.countryId])

  const countriesOptions = countries?.data?.countries?.map((country: any) => {
    return {
      label: country.name,
      value: country.id,
    }
  })

  const statesOptions = states?.data?.states?.map((state: any) => {
    return {
      label: state.name,
      value: state.name,
    }
  })

  //   useEffect(() => {
  //     if (formik.values.sameAddress === true) {
  //       formik.setFieldValue('address1', '')
  //       formik.setFieldValue('address2', '')
  //       formik.setFieldValue('city', '')
  //       formik.setFieldValue('state', null)
  //       formik.setFieldValue('country', 'United States')
  //       formik.setFieldValue('countryId', 240)
  //       formik.setFieldValue('zipcode', '')
  //       setFormData({
  //         ...formData,
  //         address1: '',
  //         address2: '',
  //         city: '',
  //         state: null,
  //         country: 'United States',
  //         countryId: 240,
  //         zipcode: '',
  //         sameAddress: false,
  //       })
  //     }
  //   }, [formik.values.sameAddress])

  const handleSetSameAddress = () => {
    setIsLoading(true)
    getProfileDetails().then((res) => {
      if (
        res.data.profile.address1 === '' ||
        res.data.profile.city === '' ||
        res.data.profile.state === '' ||
        res.data.profile.country === '' ||
        res.data.profile.zipCode === ''
      ) {
        Swal.fire({
          text: `Please go to basic information in your profile and fill your address first!`,
          icon: 'info',
          showConfirmButton: true,
          timer: 5000,
          width: '25rem',
        })
        setIsLoading(false)
      } else {
        setSameAddress(!sameAddress)
        formik.setFieldValue('sameAddress', !sameAddress)
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <form className='form'>
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='d-flex align-items-center my-8'>
            <div className='form-check form-check-custom form-check-solid me-5'>
              {!isLoading && (
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={formik.values.sameAddress}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      formik.setFieldValue('address1', '')
                      formik.setFieldValue('address2', '')
                      formik.setFieldValue('city', '')
                      formik.setFieldValue('state', null)
                      formik.setFieldValue('country', 'United States')
                      formik.setFieldValue('countryId', 240)
                      formik.setFieldValue('zipcode', '')
                      setFormData({
                        ...formData,
                        address1: '',
                        address2: '',
                        city: '',
                        state: null,
                        country: 'United States',
                        countryId: 240,
                        zipcode: '',
                        sameAddress: false,
                      })
                    }
                    handleSetSameAddress()
                  }}
                  id='isCouponForr'
                />
              )}
              {isLoading && (
                <div className='spinner-border text-success' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              )}
            </div>
            <div className='flex-grow-1'>
              <label className='text-gray-800 fw-bold fs-6 cursor-pointer' htmlFor='isCouponForr'>
                Use the same address
              </label>
            </div>
          </div>
          <div className='d-flex flex-column mb-7 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>Address Line 1</span>
            </label>

            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Address Line 1'
              name='address1'
              value={
                formik.values.sameAddress === true
                  ? profile?.data?.profile.address1
                  : formik.values.address1
              }
              onChange={formik.handleChange}
              disabled={formik.values.sameAddress === true}
            />
            {formik.errors.address1 && formik.touched.address1 ? (
              <div className='text-danger mt-2'>
                <span className='p-2'>{formik.errors.address1}</span>
              </div>
            ) : null}
          </div>
          <div className='d-flex flex-column mb-7 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span>Address Line 2</span>
            </label>

            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Address Line 2'
              name='address2'
              value={
                formik.values.sameAddress === true
                  ? profile?.data?.profile.address2
                  : formik.values.address2
              }
              onChange={formik.handleChange}
              disabled={formik.values.sameAddress === true}
            />
            {formik.errors.address2 && formik.touched.address2 ? (
              <div className='text-danger mt-2'>
                <span className='p-2'>{formik.errors.address2}</span>
              </div>
            ) : null}
          </div>

          <div className='row'>
            <div className='col-6'>
              <div className='d-flex flex-column mb-7 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>City</span>
                </label>

                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='City'
                  name='city'
                  value={
                    formik.values.sameAddress === true
                      ? profile?.data?.profile.city
                      : formik.values.city
                  }
                  onChange={formik.handleChange}
                  disabled={sameAddress}
                />
                {formik.errors.city && formik.touched.city ? (
                  <div className='text-danger mt-2'>
                    <span className='p-2'>{formik.errors.city}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className='col-6'>
              <div className='d-flex flex-column mb-7 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>State</span>
                </label>
                <Select
                  options={statesOptions}
                  styles={ReactSelectStyles}
                  name='state'
                  placeholder='State'
                  value={
                    formik.values.sameAddress === true
                      ? {
                          label: profile?.data?.profile.state,
                          value: profile?.data?.profile.state,
                        }
                      : statesOptions?.find((option: any) => option.value === formik.values.state)
                  }
                  onChange={(option: any) => formik.setFieldValue('state', option.value)}
                  isDisabled={formik.values.sameAddress}
                />
                {formik.errors.state && formik.touched.state ? (
                  <div className='text-danger mt-2'>
                    <span className='p-2'>{formik.errors.state}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <div className='d-flex flex-column mb-7 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>Country</span>
                </label>

                <Select
                  options={countriesOptions}
                  styles={ReactSelectStyles}
                  name='country'
                  placeholder='Country'
                  value={
                    formik.values.sameAddress === true
                      ? {
                          label: profile?.data?.profile.country,
                          value: profile?.data?.profile.country,
                        }
                      : countriesOptions?.find(
                          (option: any) => option.value === formik.values.countryId
                        )
                  }
                  onChange={(option: any) => {
                    formik.setFieldValue('country', option.label)
                    formik.setFieldValue('countryId', option.value)
                  }}
                  isDisabled={formik.values.sameAddress}
                />
                {formik.errors.country && formik.touched.country ? (
                  <div className='text-danger mt-2'>
                    <span className='p-2'>{formik.errors.country}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-6'>
              <div className='d-flex flex-column mb-7 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>Zip Code</span>
                </label>

                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Zip Code'
                  name='zipcode'
                  value={
                    formik.values.sameAddress === true
                      ? profile?.data?.profile.zipCode
                      : formik.values.zipcode
                  }
                  onChange={formik.handleChange}
                  disabled={formik.values.sameAddress}
                />
                {formik.errors.zipcode && formik.touched.zipcode ? (
                  <div className='text-danger mt-2'>
                    <span className='p-2'>{formik.errors.zipcode}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='w-100'>
              <div className='pb-5'>
                <h2 className='fw-bolder text-dark'>Payment Method</h2>
              </div>
              <div className='d-flex flex-column mb-7 fv-row'>
                <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Name On Card</span>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Name On Card'
                    name='cardName'
                    value={formik.values.cardName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.cardName && formik.touched.cardName ? (
                    <div className='text-danger mt-2'>
                      <span className='p-2'>{formik.errors.cardName}</span>
                    </div>
                  ) : null}
                </div>
                <Payment handleSubmit={handleSubmit} setCrdToken={setCrdToken} formik={formik} setCardNumber={setCardNumber} />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <button className='btn btn-sm btn-secondary' onClick={prevStep}>
              Previous
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              onClick={() => formik.handleSubmit()}
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export {BillingAddressForm}
