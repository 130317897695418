import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import Select from 'react-select'
import {OptionType} from '../../../../../global/ReactSelect'
import SelectAsyncPaginate from '../../../../../global/SelectAsyncPaginate'
import {ReactSelectStyles} from '../../../../../global/ReactSelectStyle'
import axios, {AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_API_URLL
const options: OptionType[] = [
  {value: undefined, label: 'All'},
  {value: true, label: 'Active'},
  {value: false, label: 'Inactive'},
]
const roleoptions: OptionType[] = [
  {value: null, label: 'All'},
  {value: 'Inspector', label: 'Inspector'},
  {value: 'SubContractor', label: 'SubContractor'},
]
const TeamsListFilter = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [isActive, setIsActive] = useState<string | undefined>()
  const [companiess, setcompanies] = useState<any>([])
  const [Role, setRole] = useState<string | undefined>()
  const [CompanyName, setcompani] = useState<string | undefined>()
  // const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  useEffect(() => {
    console.log(CompanyName)
  }, [CompanyName])
  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setIsActive(undefined)
    setRole(undefined)
    setcompanies(undefined)
    setcompani(undefined);
    Call()
    
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const handleSelectClick = (e: any) => {
    e.stopPropagation()
  }

  const filterData = () => {
    updateState({
      filter: {isActive, Role, CompanyName},
      ...initialQueryState,
    })
    closeMenu()
    setMenuOpen(false)
  }
  function Call() {
    axios.get(`${API_URL}/Projects/GetCompaniesDropDown`).then(async (response) => {
      const myArray: Object[] = [];
      const myArray2: Object[] = [];
      if (response.data.data.contractingCompanies) {
        response.data.data.contractingCompanies.map((company: any) => {
          myArray.push({ value: company.id, label: company.name });
        });
      }
  
      if (typeof response.data.data.inspectionCompanies === 'object') {
        const company = response.data.data.inspectionCompanies;
        myArray2.push({ value: company.inspectionCompanyId, label: company.inspectionCompanyName });
      }
  
      const combinedArray = [...myArray, ...myArray2];
      setcompanies(combinedArray)
    
    })
  }

  useEffect(() => {
    Call()
  }, [])

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => {
          setMenuOpen(true)
        }}
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px ${isMenuOpen ? 'show' : ''}`}
        data-kt-menu='true'
        onClick={handleSelectClick}
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <Select
              options={options}
              defaultValue={options[0]}
              value={
                isActive !== null
                  ? options.find((option) => option.value === isActive) || options[0]
                  : options[0]
              }
              onChange={(e: any) => {
                setIsActive(e.value)
              }}
              styles={ReactSelectStyles}
            />
          </div>
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Role:</label>
            <Select
              options={roleoptions}
              defaultValue={roleoptions[0]}
              onChange={(e: any) => {
                setRole(e.value)
                console.log(e.value)
              }}
              value={
                Role !== null ? options.find((option) => option.value === Role) : roleoptions[0]
              }
              styles={ReactSelectStyles}
            />
          </div>
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Company:</label>
            <Select
            placeholder='All'
              isClearable={true}
              options={companiess}
              value={
                companiess
                  ? companiess.find(
                      (company: any) => company?.value === companiess.value
                    )
                  : null
              }
              onChange={(e: any) => {
                setcompani(e?.label);

                console.log('cc', e?.label)
              }}
              styles={ReactSelectStyles}
            />
          </div>

          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={
                filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {TeamsListFilter}
