// @ts-nocheck
import { Column } from 'react-table'
import { User } from '../../core/_models'
import { TaskCustomHeader } from './TaskCustomHeader'
import { TaskInfoCell } from './TaskInfoCell'
import TaskInfoName from './TaskInfoName'
import TaskStatus from './TaskStatus'
import { TaskAssignCell } from './TaskAssignCell'
import { TaskLocationCell } from './TaskLocationCell'


const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <TaskInfoName user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Due Date' className='min-w-125px' />,
    id: 'dueDate',
    Cell: ({ ...props }) => <TaskInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Location' className='min-w-125px' />,
    id: 'locationNames',
    Cell: ({ ...props }) => <TaskLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Assignee' className='min-w-125px' />,
    id: 'firstName',
    Cell: ({ ...props }) => <TaskAssignCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'statusName',
    Cell: ({ ...props }) => <TaskStatus user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    accessor: 'address1',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='City' className='min-w-125px' />
    ),
    accessor: 'city',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'state',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='Zip Code' className='min-w-125px' />
    ),
    accessor: 'zipCode',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='Country' className='min-w-125px' />
    ),
    accessor: 'country',
  },

]

export { usersColumns }
