/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import preval from 'preval.macro'
import moment from "moment";

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 order-md-1 d-flex'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <p
          className='text-gray-800 text-hover-primary cursor-pointer me-5'
        >
          PunchList
        </p>
        <p
          className='text-gray-800 text-hover-primary cursor-pointer'
        >
          Build Version <strong>{process.env.REACT_APP_VERSION}</strong> / Build Date: <strong>{moment(preval`module.exports = new Date().toLocaleDateString("en-US");`).format('MM/DD/YYYY')}</strong>
        </p>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
