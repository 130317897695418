import React, {useState, useRef, useEffect} from 'react'
import {Document, Page, pdfjs} from 'react-pdf'
import ImageGallery from 'react-image-gallery'
import {Modal} from 'react-bootstrap'
import axios from 'axios'
import {handleApiErrors} from '../../../global/HandleApiErrors'
import Swal from 'sweetalert2'
import {ProgressBar} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
const BASE_URL = process.env.REACT_APP_BASE_URL

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Image {
  original: string
  thumbnail: string
  originalAlt: string
  thumbnailAlt: string
}

const MyComponent = (props: any) => {
  const [selectedImage, setSelectedImage] = useState<string>('')
  const [editTitleShow, setEditTitleShow] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const id = extractIdFromUrl(window.location.href)

  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      uploadFile(file)
    }
  }

  const uploadFile = async (file: File) => {
    props.setLoad(true)

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await axios.post(`${BASE_URL}/api/Projects/ProjectDrawing/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setUploadProgress(progress)
        },
      })

      if (response.status === 200) {
        props.fetchData()
        props.setLoad(false)
        Swal.fire({
          text: 'Plans added successfully',
          icon: 'success',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
      }
    } catch (error: any) {
      props.setLoad(false)
      handleApiErrors(error?.response?.data)
    }
  }

  const handlesetPicelect = (image: string) => {
    setSelectedImage(image)
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const [isHovered, setIsHovered] = useState(false)

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsHovered(true)
  }

  const handleDragLeave = () => {
    setIsHovered(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsHovered(false)
    const files = e.dataTransfer.files
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        uploadFile(file)
      }
    }
  }
  const handleDownload = () => {
    window.location.href = props.pdf
  }
  const handleResetClick = () => {
    setEditTitleShow(false)
  }
  useEffect(() => {
    console.log(props.pic)
  }, [props.pic])
  useEffect(() => {
    console.log(props.fetchingImages)
  }, [props.fetchingImages])
  return (
    <div className='d-grid gap-2 text-center'>
      {props.show ? (
        props.pic ? (
          <img
            alt='Plan'
            src={props.pic}
            className='w-100 cursor-pointer'
            onClick={() => setEditTitleShow(true)}
          />
        ) : (
          <>
            {props.fetchingImages && (
              <ProgressBar
                now={props.fetchProgress}
                label={`${props.fetchProgress}%`}
                className='mt-2 w-100'
              />
            )}
          </>
        )
      ) : props.load ? (
        <div className='mt-8'>
          Please wait...{' '}
          {/* <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className='mt-2 w-100' /> */}
          <span className='spinner-border spinner-border-sm align-middle ms-2 text-center'></span>
        </div>
      ) : (
        <div className='w-300px h-200px'>
          <div
            className={`zone h-300px w-400px ${isHovered ? 'hover' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div id='dropZ'>
              <div className='mt-20'>
                <KTSVG
                  path='/media/icons/duotune/files/fil022.svg'
                  className='svg-icon svg-icon-primary svg-icon-4x mt-20 pt-20'
                />
              </div>
              <div className='fs-5'>Drag and drop your file here</div>
              <span>OR</span>
              <div className='selectFile'>
                <label
                  className='btn btn-lg btn-primary'
                  htmlFor='file'
                  onClick={handleUploadClick}
                >
                  Select file
                </label>
                <input
                  ref={inputRef}
                  type='file'
                  accept='.pdf'
                  className='d-none'
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={editTitleShow}
        onHide={() => {
          setEditTitleShow(false)
        }}
        size='lg'
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>Project plans</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.galleryImages.length > 0 ? (
              <ImageGallery items={props.galleryImages} />
            ) : (
              <span>No images found.</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-centre'>
            <button
              type='button'
              className='btn btn-light text-center me-3'
              onClick={handleResetClick}
            >
              Close
            </button>
            <button type='button' className='btn btn-success text-center' onClick={handleDownload}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/icons/cloud-computing.png')}
                className='h-15px w-15px '
              />{' '}
              {'  '} Download
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MyComponent
