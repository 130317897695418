import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {Tag} from '../../core/_models'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<Tag>>
}
const TagCustomHeader: FC<Props> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const { state, updateState } = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sortField === id && state.sortOrder !== undefined
  }, [state, id])

  const sortColumn = () => {
    // Avoid sorting for these columns
    if (id === 'actions' || id === 'selection' || id === 'team') {
      return
    }


    if (!isSelectedForSorting || state.sortOrder === 'desc') {
      // Enable sort asc
      updateState({ sortField: id, sortOrder: 'asc', ...initialQueryState })
    } else if (state.sortOrder === 'asc') {
      // Enable sort desc
      updateState({ sortField: id, sortOrder: 'desc', ...initialQueryState })
    } else {
      // Disable sort
      updateState({ sortField: undefined, sortOrder: undefined, ...initialQueryState })
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && `table-sort-${state.sortOrder}`
      )}
      style={{ cursor: 'pointer' }}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export {TagCustomHeader}
