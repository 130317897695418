// import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { ID, KTIcon, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { TeamsListFilter } from './TeamsListFilter'
import { useQuery, useQueryClient } from 'react-query'
import { ModelStateMessage, QueryFetchPost } from '../../../../../global'
import Import from '../../../../../global/Import'
import { Loading } from '../../../../../global/Loading'
import Swal from 'sweetalert2'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import SelectAsyncPaginate from '../../../../../global/SelectAsyncPaginate'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URLL
const IMPORT_URL = `${API_URL}/Importing/Task`
interface PropsShow {
  setShowSearchComponent: any
  showSearchComponent: boolean
}
const TeamsListToolbar = (props: PropsShow) => {
  const cuurentUser: any = localStorage.getItem('User')
  const params = useParams<any>()
  const { id } = params
  const role: any = JSON.parse(cuurentUser)?.roles
  const queryClient = useQueryClient()
  const [ProjectId, setProjectId] = React.useState<ID | undefined>()
  const [selectedProject, setSelectedProject] = React.useState<any | undefined>()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { setItemIdForUpdate, setOpenReport } = useListView()
  const { query } = useQueryResponse()
  const [importing, setImporting] = React.useState(false)
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const { data: finishedProjects } = useQuery(
    `finishedProjects`,
    async () => {
      return await axios.get(`${API_URL}/Tasks/Finished/${id}`)
    },
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  )

  console.log('finishedProjects', finishedProjects?.data?.finished)

  const [isOpen, setIsOpen] = React.useState(false)
  function onClose() {
    setIsOpen(false)
    setSelectedProject(null)
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id))
      setSelectedProject({ label: (window as any).projectName, value: parseInt(id) })
    }
  }, [id])

  async function onSubmit(data: any) {
    setImporting(true)
    await QueryFetchPost({
      link: IMPORT_URL,
      data: {
        projectId: ProjectId,
        tasks: data.validData,
      },
      method: 'POST',
      key: 'importTask',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      setImporting(false)
      if (response[0] === 200 && response[1].messages.length === 0) {
        queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
        setImporting(false)
        setIsModalOpen(false)
        setSelectedProject(null)
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: 'Tasks imported successfully',
          width: '25rem',
        })
      } else if (response[0] === 200 && response[1].messages.length > 0) {
        setImporting(false)
        setIsModalOpen(false)
        setSelectedProject(null)
        queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
        let messages = ''
        response[1].messages.forEach((message: any) => {
          console.log(message.message)
          messages += message.message + '</br>'
        })
        Swal.fire({
          icon: 'info',
          title: 'Info',
          html: `<div class="text-center">${messages}</div>`,
        })
      } else {
        setImporting(false)
        ModelStateMessage(response)
      }
    })
  }

  const fields = [
    {
      label: 'Name',
      key: 'name',
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: 'input',
      },
      example: 'Cubic Roots',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Project Name is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Location',
      key: 'location',
      fieldType: {
        type: 'input',
      },
      example: 'Floor One > App 1 > Kitchen',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Location is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Status',
      key: 'status',
      fieldType: {
        type: 'select',
        options: [
          {
            label: 'Draft',
            value: 'Draft',
          },
          {
            label: 'Open',
            value: 'Open',
          },
          {
            label: 'Closed',
            value: 'Closed',
          },
          {
            label: 'Completed',
            value: 'Completed',
          },
        ],
      },
      example: 'Open',
    },
    {
      label: 'Assignee',
      key: 'subContractor',
      fieldType: {
        type: 'input',
      },
      example: 'John.doe@mail.com',
    },
    {
      label: 'Due Date',
      key: 'dueDate',
      fieldType: {
        type: 'input',
      },
      example: '06/09/2023',
    },
    {
      label: 'Value',
      key: 'value',
      fieldType: {
        type: 'input',
      },
      example: '1000',
    },
    {
      label: 'Priority',
      key: 'priority',
      fieldType: {
        type: 'input',
      },
      example: 'High',
    },
    {
      label: 'Description',
      key: 'description',
      fieldType: {
        type: 'input',
      },
      example: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      label: 'Approved By',
      key: 'approvedBy',
      fieldType: {
        type: 'input',
      },
      example: 'John.doe@mail.com',
    },
    {
      label: 'Approved Date',
      key: 'approvedDate',
      fieldType: {
        type: 'input',
      },
      example: '06/09/2023',
    },
    {
      label: 'Completed By',
      key: 'completedBy',
      fieldType: {
        type: 'input',
      },
      example: 'John.doe@mail.com',
    },
    {
      label: 'Completed Date',
      key: 'completedDate',
      fieldType: {
        type: 'input',
      },
      example: '06/09/2023',
    },
    {
      label: 'Updated By',
      key: 'updatedBy',
      fieldType: {
        type: 'input',
      },
      example: 'John.doe@mail.com',
    },
    {
      label: 'Last Update Date',
      key: 'lastUpdateDate',
      fieldType: {
        type: 'input',
      },
      example: '06/09/2023',
    },
    {
      label: 'Created By',
      key: 'createdBy',
      fieldType: {
        type: 'input',
      },
      example: 'John.doe@mail.com',
    },
    {
      label: 'Created Date',
      key: 'createdDate',
      fieldType: {
        type: 'input',
      },
      example: '06/09/2023',
    },
    {
      label: 'Tags',
      key: 'tags',
      fieldType: {
        type: 'input',
      },
      example: 'Kitchen, Romm, Bathroom',
    },
  ]
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button
          type='button'
          className='btn btn-light-primary me-3'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => props.setShowSearchComponent(!props.showSearchComponent)}
        >
          <KTIcon iconName='filter' className='fs-2' />
          Filter
        </button>
        <div>
          {(role[0] === 'Inspector Admin' || role[0] === 'Inspector') && (
            <>
              <button
                onClick={() => {
                  !ProjectId &&
                    Swal.fire({
                      title: 'Oops...',
                      text: 'Please choose a project',
                      icon: 'error',
                      confirmButtonText: 'Ok',
                    })
                  ProjectId && setIsOpen(true)
                }}
                type='button'
                className='btn btn-light-primary me-3'
              >
                <KTIcon iconName='exit-up' className='fs-2' />
                Import
              </button>
              <button
                onClick={() => setOpenReport(true)}
                type='button'
                className='btn btn-light-success me-3'
              >
                <KTIcon iconName='exit-up' className='fs-2' />
                Export Report
              </button>
              <button
              id="AddTask"
                type='button'
                className='btn btn-primary'
                onClick={openAddUserModal}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Add Task
              </button>
            </>
          )}
        </div>
      </div>

      {isModalOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-750px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Import Tasks</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setIsModalOpen(false)}
                    style={{ cursor: 'pointer' }}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                </div>
                <div className='modal-body mx-5 mx-xl-5 my-7'>
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Please choose the Project:</label>
                    <SelectAsyncPaginate
                      apiLink={`${API_URL}/Tasks/Projects`}
                      value={selectedProject}
                      isDisabled={id ? true : false}
                      onChange={async (e: any) => {
                        setSelectedProject(e)
                        setProjectId(e.value)
                      }}
                    />
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => {
                        !ProjectId &&
                          Swal.fire({
                            title: 'Oops...',
                            text: 'Please choose a project',
                            icon: 'error',
                            confirmButtonText: 'Ok',
                          })
                        ProjectId && setIsOpen(true)
                      }}
                    >
                      Import tasks for the selected project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}

      <Import
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        fields={fields}
        allowInvalidSubmit={false}
      />
      {importing && <Loading />}
    </>
  )
}

export { TeamsListToolbar }
