import React, { FC, useEffect, useRef, useState } from 'react'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import axios from 'axios'
import { TimeConversion } from '../../../../app/modules/global'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import MyComponent from './steps/PDF'
import { handleApiErrors } from '../../global/HandleApiErrors'
import { Document, Page, pdfjs } from 'react-pdf';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface Image {
  original: string;
  thumbnail: string;
  originalAlt: string;
  thumbnailAlt: string;
}


const AUTH_LOCAL_STORAGE_KEY = 'User'
const BASE_URL = process.env.REACT_APP_BASE_URL

const Project: FC = () => {
  const projectBreadcrumbs: Array<PageLink> = [
    {
      title: 'Project Management',
      path: '/project-management/new-project/:id',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const [show, setshow] = useState(false)
  const [edittitleshow, setedittitleshow] = useState(false)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [Step, setSteps] = useState(0)
  const [id, setId] = useState('')
  const [project, setproject] = useState<any>()

  useEffect(() => { }, [id])
  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }
  const idd = window.location.href && extractIdFromUrl(window.location.href)
  const user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')

  let arrayName: any
  async function getProject() {
    try {
      if (idd) {
        const response = await axios.get(`${BASE_URL}/api/Projects/${idd}`)
        if (response.status === 200) {
          setproject(response.data)
          arrayName = response.data
        }
      }
    } catch (error) { }
  }
  function extractDetailsFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('details') === 'true'
  }
  const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)


  const [fetchingImages, setFetchingImages] = useState<boolean>(false);
  const [showw, setShoww] = useState<boolean>(false);
  const [fetchProgress, setFetchProgress] = useState<number>(0);
  const [load, setLoad] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [images, setImages] = useState<Image[]>([]);
  const [pic, setPic] = useState<string>('');
  const [galleryImages, setGalleryImages] = useState<Image[]>([]);
  const [pdf, setpdf] = useState<string>('');
  const fetchData = async () => {
    if (idd !== null) {
      try {
        const response = await axios.get(`${BASE_URL}/api/Projects/GetProjectDrawing/${idd}`, {});

        setFetchingImages(true);

        if (response.data) {
          setedittitleshow(true); // Open the modal immediately
        }
        if (response.data) {
          setShoww(true);
        }
        setpdf(response.data);
        const pdfUrl = response.data;
        const pdfResponse = await fetch(pdfUrl);
        const pdfBlob = await pdfResponse.blob();
        const arrayBuffer = await new Response(pdfBlob).arrayBuffer();
        const pdfData = await pdfjs.getDocument(arrayBuffer).promise;
        const imgArray = [];

        for (let i = 1; i <= pdfData.numPages; i++) {
          const page = await pdfData.getPage(i);
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement('canvas');
          const canvasContext = canvas.getContext('2d', { alpha: false });

          if (!canvasContext) {
            throw new Error('Could not obtain canvas 2d context.');
          }

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          // Adjust rendering settings
          canvasContext.imageSmoothingEnabled = false;

          // Render page content
          await page.render({
            canvasContext,
            viewport,
          }).promise;

          // Convert canvas to data URL
          const imageDataUrl = canvas.toDataURL();
          const image: Image = {
            original: imageDataUrl,
            thumbnail: imageDataUrl,
            originalAlt: `Page ${i}`,
            thumbnailAlt: `Page ${i}`,
          };
          imgArray.push(image);

          // Calculate progress
          const progress = parseInt(((i / pdfData.numPages) * 100).toFixed(0));

          setFetchProgress(progress);
        }

        setGalleryImages(imgArray);
        setPic(imgArray[0].original);
        setFetchingImages(false);
      } catch (error:any) {
        setFetchingImages(false);
        handleApiErrors(error?.response?.data);
        console.error('Error:', error);
      }
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [idd]);

  useEffect(() => {
    setTimeout(() => {
      getProject()
    }, 1000)
  }, [Step])
  
  return (
    <>

      {project?.overDue === true && project?.signed === false ? (
        <div className='justify-content-center '>
          <div className='alert alert-warning d-flex align-items-center p-5 '>
            {/*begin::Icon*/}
            <i className='ki-duotone ki-shield-tick fs-2hx text-warning  me-4'>
              <span className='path1' />
              <span className='path2' />
            </i>
            {/*end::Icon*/}
            {/*begin::Wrapper*/}
            <div className='d-flex flex-column'>
              {/*begin::Title*/}
              {/*end::Title*/}
              {/*begin::Content*/}
              <span>This project has exceeded its deadline</span>
              {/*end::Content*/}
            </div>
            {/*end::Wrapper*/}
          </div>
        </div>
      ) : (
        ''
      )}
      {isDetails === true && project?.signed === true ? (
        <div className="card mb-5 mb-xl-10">

          <div className="card-body tab-content">
            <div
              className="tab-pane fade show active"
            >
              <div className="row gx-9 gy-6">
                <div className="col-12 pb-4">
                  <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                    <div className="d-flex flex-column py-2">
                      <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                        {project?.signedBy}

                        <span className="badge badge-light-success fs-7 ms-2">
                          Signed
                        </span>
                      </div>
                      <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                        Signature Date :{' '}
                        <span className="badge text-muted fs-7 ms-2">
                          {project?.signatureDate ? TimeConversion(project?.signatureDate, localStorage?.getItem('TimeZone')) : ""}

                        </span>
                      </div>

                    </div>
                    <div className="d-flex align-items-center py-2">
                      <div className='fs-5 text-dark '>
                        Signature :{' '}
                        <img
                          src={toAbsoluteUrl(`${project?.signature}`)}
                          alt='img'
                          className=' h-90px w-200px ms-1'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <PageTitle breadcrumbs={projectBreadcrumbs}>Project</PageTitle>
      <div className='card'>
        <div className='card-body'>

          <div ref={stepperRef} className='stepper stepper-links d-flex flex-column'>
            <div className='stepper-nav mb-5 ms-0'>
              <div
              id="ProjectDetails"
                className={`stepper-item cursor-pointer ${Step === 0 ? 'current' : ''} text-mut`}
                onClick={() => {
                  if (idd !== null) {
                    setSteps(0)
                  }
                }}
              >
                <h3 className='stepper-title'>Project Details</h3>
              </div>

              <div
                id="Locations"
                className={`stepper-item cursor-pointer ${Step === 1 ? 'current' : ''}`}
                onClick={() => {
                  if (idd !== null) {
                    setSteps(1)
                  }
                }}
              >
                 {idd == null? <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip id={`tooltip-${'placement'}`}>
                              You have to create a project first
                            </Tooltip>
                          }
                        >
                           <h3 className='stepper-title text-muted'>Locations</h3>
                        </OverlayTrigger>:<h3 className='stepper-title'>Locations</h3>}
              </div>
              {user.roles[0] === 'Building Owner' ? (
                ''
              ) : (
                <>
                  <div
                  id="ProjectTeam"
                    className={`stepper-item cursor-pointer ${Step === 2 ? 'current' : ''}`}
                    onClick={() => {
                      if (idd !== null) {
                        setSteps(2)
                      }
                    }}
                  >
{idd == null? <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip id={`tooltip-${'placement'}`}>
                              You have to create a project first
                            </Tooltip>
                          }
                        >
                            <h3 className='stepper-title text-muted'>Project Team</h3>
                        </OverlayTrigger>: <h3 className='stepper-title'>Project Team</h3>}                  </div>
                  <div
                  id="TagsProject"
                    className={`stepper-item cursor-pointer ${Step === 3 ? 'current' : ''}`}
                    onClick={() => {
                      if (idd !== null) {
                        setSteps(3)
                      }
                    }}
                  >
                   {idd == null? <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip id={`tooltip-${'placement'}`}>
                              You have to create a project first
                            </Tooltip>
                          }
                        >
                            <h3 className='stepper-title text-muted'>Tags</h3>
                        </OverlayTrigger>: <h3 className='stepper-title '>Tags</h3>}
                  </div>
                </>
              )}

              {/* <div className='stepper-item' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>Summary</h3>
              </div> */}
            </div>

            <div className='w-100 px-5' id='kt_create_account_form'>
              {Step === 0 ? (
                <div>
                  <Step1
                    setId={setId}
                    id={id}
                    setSteps={setSteps}
                    Step={Step}
                    project={project}
                    arrayName={arrayName}
                    setproject={setproject}
                  />
                </div>
              ) : (
                ''
              )}
              {Step === 1 ? (
                <div className={`${Step > 1 ? 'current' : ''}`}>
                  <Step2
                    setId={setId}
                    id={id}
                    setSteps={setSteps}
                    Step={Step}
                    pic={pic}
                    setshow={setShoww}
                    show={showw}
                    setedittitleshow={setedittitleshow}
                    fetchingImages={fetchingImages}
                    fetchProgress={fetchProgress}
                    setLoad={setLoad}
                    load={load}
                    images={images}
                    galleryImages={galleryImages}
                    fetchData={fetchData}
                    pdf={pdf}
                  />
                </div>
              ) : (
                ''
              )}
              {user.roles[0] === 'Building Owner' ? (
                ''
              ) : (
                <>
                  {Step === 2 ? (
                    <div>
                      <Step3 setSteps={setSteps} Step={Step} />
                    </div>
                  ) : (
                    ''
                  )}
                  {Step === 3 ? (
                    <div>
                      <Step4 setSteps={setSteps} Step={Step} />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Project }
