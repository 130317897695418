import {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import axios from 'axios'
import Swal from 'sweetalert2'
import {handleApiErrors} from '../../../../global/HandleApiErrors'
import {TagsInput} from 'react-tag-input-component'

const API_URL = process.env.REACT_APP_API_URLL

type Props = {
  data?: any
  setIsEmailOpen: (value: boolean) => void
}
const EmailModal: FC<Props> = ({data, setIsEmailOpen}) => {
  const [message, setMessage] = useState('')
  const [Emails, setEmails] = useState<any>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleSendEmail = async (email: any) => {
    if (!message)
      return Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please enter a reason for sending this email.',
        showConfirmButton: true,
        timer: 3000,
        width: '25rem',
      })
    setLoading(true)
    try {
      await axios.post(`${API_URL}/Tasks/SendEmail`, email).then((res) => {
        setIsEmailOpen(false)
        setMessage('')
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Email has been sent successfully.',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
      })
    } catch (error: any) {
      handleApiErrors(error.response.data)
    } finally {
      setLoading(false)
    }
  }

  const validateEmail = (email: any) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-550px'>
          <div className='modal-content'>
            <div className='modal-body scroll-y mx-5 mx-xl-5 my-7 mh-800px'>
              <div className='text-center d-flex flex-column align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/email/email.png')}
                  alt='image'
                  className='mh-100px fs-2x mb-5'
                />
                <label className='fw-bold fs-2x mb-10'>Send Task Email</label>
              </div>
              <div className='d-flex flex-column'>
                <span className='fs-2 fw-bold mb-1'>#{data?.data?.name}</span>
                <span className='fs-7 mb-5'>
                This email will be dispatched to all individual assigned to this task, 
                providing them with all the relevant information
                </span>
              </div>
              <div className='mt-5'>
                <label className='fw-bold fs-6 mb-2 required'>Message</label>
                <textarea
                  placeholder='Include a reason for sending this email'
                  name='name'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                />
              </div>
              <div className='my-7'>
                <label className='fw-bold fs-6 me-2'>To:</label>
                <span className='badge badge-secondary'>{data?.data?.contractorEmail}</span>
              </div>
              <div className='my-7'>
                <label className='fw-bold fs-6 me-2'>CC:</label>
                <TagsInput
                  value={Emails}
                  onChange={setEmails}
                  name='emails'
                  placeHolder='Enter email address'
                />
              </div>

              <div className='d-flex flex-end'>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    data-kt-users-modal-action='cancel'
                    onClick={() => {
                      setIsEmailOpen(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={loading}
                    type='button'
                    className='btn btn-sm btn-success btn-active-light-primary'
                    data-kt-users-modal-action='cancel'
                    onClick={() => {
                      if (Emails.length > 0) {
                        Emails.map((email: any) => {
                          if (!validateEmail(email)) {
                            return Swal.fire({
                              icon: 'warning',
                              title: 'Oops...',
                              text: 'Please enter a valid email address in the CC field',
                              showConfirmButton: true,
                              timer: 3000,
                              width: '25rem',
                            })
                          }
                        })
                      }
                      handleSendEmail({
                        TaskId: data?.data?.id,
                        Body: message,
                        EmailsCc: Emails,
                      })
                    }}
                  >
                    {loading ? 'Sending...' : 'Send'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {EmailModal}
