import React, { FC, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

type Props = {
    attachments: any[];
    clickedimageId?: any;
};

const ImagesViewer: FC<Props> = ({ attachments, clickedimageId }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        // Find the index of the clicked image based on the clickedimageId
        const clickedIndex = attachments.findIndex(
            (attachment: any) => attachment.id === clickedimageId
        );
        if (clickedIndex !== -1) {
            setCurrentIndex(clickedIndex);
        }
    }, [attachments, clickedimageId]);

    const images: any[] = attachments.map((attachment: any) => ({
        original: attachment.path,
        thumbnail: attachment.path,
        description: attachment.name,
    }));

    return (
        <div style={{ minHeight: '500px' }}>
            <ImageGallery
                items={images}
                startIndex={currentIndex}
                showFullscreenButton={true}
            />
        </div>
    );
};

export default ImagesViewer;
