import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  id?: ID
  name?: string
  priority?: string
  description?: string
  dueDate?: string
  projectId?: number | null
  locationId?: number | null
  value?: number | null
  tagIds?: any
  subContractorId?: number | null
  locationName?: string | null | undefined
  tags?: Array<any>
  attachments?: Array<any>
  statusName?: string | null | undefined
  projectName?: string | null | undefined
  subContractorName?: string | null | undefined
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  contractorFirstName?: string | null | undefined
  contractorLastName?: string | null | undefined
  locationNames?: string | null | undefined
  importingLoaction?: string | null | undefined
  locationPath?: string | null | undefined
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  name: '',
  priority: '',
  description: '',
  dueDate: '',
  projectId: null,
  locationId: null,
  value: null,
  tagIds: [],
  subContractorId: null,
}
