import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import ProfilePage from '../modules/profile/ProfilePage'
import { Project } from '../modules/project/components/Project'
import TagsPage from '../modules/apps/tag-management/TagsPage'
import SearchePage from '../../_metronic/partials/layout/search/SearchPage'
import { UsersListWrapper } from '../modules/apps/task-management/teams-list/TasksList'
import GuidedTour from '../modules/global/IntroDriver/IntroDriver'

const PrivateRoutes = () => {
  const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles
  const TeamsPage = lazy(() => import('../modules/apps/team-management/TeamsPage'))
  const TasksPage = lazy(() => import('../modules/apps/task-management/TasksPage'))
  const ProjectsPage = lazy(() => import('../modules/apps/project-management/ProjectsPage'))
  const SubcontractorsPage = lazy(() => import('../modules/apps/subcontractor-management/SubcontractorsPage'))
  const [IntroSeen, setIntroSeen] = useState<any>(null)

  useEffect(()=>{
    setTimeout(()=>{
      setIntroSeen(true)
    },1000)
  },[])

  return (
    <>
    {IntroSeen === true?<GuidedTour />:""}
     
      <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='team-management/*'
          element={
            <SuspensedView>
              <TeamsPage />
            </SuspensedView>
          }
        />
        <Route
          path='task-management/*'
          element={
            <SuspensedView>
              <TasksPage />
            </SuspensedView>
          }
        />
        <Route
          path='task-management/tasks/project/:id'
          element={
            <SuspensedView>
              <UsersListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='global-search/*'
          element={
            <SuspensedView>
              <SearchePage />
            </SuspensedView>
          }
        />
        <Route
          path='project-management/*'
          element={
            <SuspensedView>
              <ProjectsPage />
            </SuspensedView>
          }
        />
        <Route
          path='subcontractor-management/*'
          element={
            <SuspensedView>
              <SubcontractorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='tag-management/*'
          element={
            <SuspensedView>
              <TagsPage />
            </SuspensedView>
          }
        />
        <Route
          path='new-project/*'
          element={
            <SuspensedView>
              <Project />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
    </>
    
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
