import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';

const { TreeNode } = Tree;

interface MyTreeNode {
  key: string;
  title: string;
  children?: MyTreeNode[];
}

interface MyTreeProps {
  data: any[];
  onSelect: (selectedKeys: any[], info: any) => void;
  selectedKeys?: any[];
  setSelectedKeys: (selectedKeys: any[]) => void;
}

const MyTreeMulti: React.FC<MyTreeProps> = ({ data, onSelect, selectedKeys, setSelectedKeys }) => {
  // const [selectedKeys, setSelectedKeys] = useState<any[]>([]);

  const handleSelect = (selectedKeys: any[], info: any) => {
    setSelectedKeys(selectedKeys);
    onSelect(selectedKeys, info);
  };

  useEffect(() => {
    console.log('selectedKeys', selectedKeys)
  }
    , [selectedKeys])

  const renderTreeNodes = (treeData: MyTreeNode[]) =>
    treeData.map((node) => {
      if (node.children) {
        return (
          <TreeNode key={node.key} title={node.title}>
            {renderTreeNodes(node.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={node.key} title={node.title} />;
    });

  return (
    <Tree
      selectable
      multiple
      selectedKeys={selectedKeys}
      onSelect={handleSelect}
    >
      {renderTreeNodes(data)}
    </Tree>
  );
};

export default MyTreeMulti;
