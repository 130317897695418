/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../core/_models'
import { DateFormatwithNoMoment } from '../../../../../global/DateFormat'

type Props = {
  user: User
}

const TaskInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-600 text-hover-primary mb-1'>
        {
          DateFormatwithNoMoment(user.dueDate)
        }
      </a>
    </div>
  </div>
)

export {TaskInfoCell}
