import React, { useEffect } from 'react';
import Dropzone, { DropzoneFile } from 'dropzone';

interface MyComponentProps {
  setSelectedFiles: React.Dispatch<React.SetStateAction<DropzoneFile[]>>;
}

const CustomDropzone: React.FC<MyComponentProps> = ({ setSelectedFiles }) => {
  useEffect(() => {
    const initDropzone = (): void => {
      const myDropzone = new Dropzone('#_media', {
        url: 'https://keenthemes.com/scripts/void.php', // Set the url for your upload script location
        paramName: 'file', // The name that will be used to transfer the file
        maxFiles: 10,
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        autoQueue: false,
        parallelUploads: 1,
        acceptedFiles: 'image/*',
        accept: function (file: DropzoneFile, done: (error?: string | Error) => void): void {
          if (file.name === 'wow.jpg') {
            done("Naha, you don't.");
          } else {
            done();
          }
        },
      });

      myDropzone.on('addedfile', (file : any) => {
        setSelectedFiles((prevFiles: any) => [...prevFiles, file]);
        file.previewElement.querySelector(".dz-progress").style.display = "none";
      });

      myDropzone.on('removedfile', (file: any) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
      });
    };

    initDropzone();
  }, [setSelectedFiles]);

  return (
    <div className="card-body pt-0">
      <div className="fv-row mb-2">
        <div className="dropzone" id="_media">
          <div className="dz-message needsclick">
            <i className="ki-duotone ki-file-up text-primary fs-3x">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <div className="ms-4">
              <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop photos here or click to upload.</h3>
              <span className="fs-7 fw-semibold text-gray-400">Upload up to 6 photos</span>
            </div>
          </div>
        </div>
      </div>
      <div className="text-muted fs-7">Set the task media gallery.</div>
    </div>
  );
};

export default CustomDropzone;
