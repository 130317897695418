import {ID, Response} from '../../../../../../_metronic/helpers'
export type Team = {
  id?: ID
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  password?: string
  confirmPassword?: string
  role?: string
  status?: boolean
  isActive?: boolean
}
export const initialcontractorCompany: contractorCompany = {
  contractorCompany:[],
  Inspector:[]
}
export type contractorCompany = {
  id?:number|undefined
  contractorCompany:Array<any>
  Inspector:Array<ID>
}
export type TeamPassword = {
  id?: ID
  newPassword?: string
  confirmPassword?: string
}

export type UsersQueryResponse = Response<Array<Team>>

export const initialUser: contractorCompany = {
  contractorCompany:[],
  Inspector:[]
}

export const initialPassword: TeamPassword = {
  newPassword: '',
  confirmPassword: '',
}
