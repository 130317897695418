import React, { FC } from 'react'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
    fields: any;
    allowInvalidSubmit: boolean;
}
const Import: FC<Props> = ({ isOpen, onClose, onSubmit, fields, allowInvalidSubmit }) => {
    return (
        <>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={onSubmit}
                fields={fields}
                allowInvalidSubmit={false}
            />
        </>
    )
}

export default Import