/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { useQuery } from 'react-query'
import axios from 'axios'
import { useListView } from '../../../../app/pages/dashboard/core/ListViewProvider'

const API_URL = process.env.REACT_APP_API_URLL
const TAGS_URL = `${API_URL}`

type Props = {
  className: string
  projectId: any
  settasksummry:(p:any)=>void

}

const PieChart: React.FC<Props> = ({ className, projectId,settasksummry }) => {
  const { itemIdForUpdatePassword } = useListView()
  const [series, setSeries] = React.useState<any[]>([])
  const [progressArray, setprogressArray] = React.useState<any[]>([])

  useEffect(() => {
    if (itemIdForUpdatePassword) {
      alert(itemIdForUpdatePassword)
    }
  }, [itemIdForUpdatePassword])
  const { data: taskSummary, refetch } = useQuery<any>(
    `TaskSummary`,
    async () => {
      return await axios.get(`${TAGS_URL}/Dashboard/TaskSummary${projectId ? `?id=${projectId}` : ''}`)
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    refetch();
  }, [projectId, refetch]);
  function calculateProgress(numbersArray: number[]): number[] {
    const totalSum = numbersArray.reduce((total, num) => total + num, 0);
    const progressArray = numbersArray.map((num) => parseFloat(((num / totalSum) * 100).toFixed(2)));
  
    return progressArray;
  }
  
  useEffect(() => {
    
    if (taskSummary) {
      settasksummry(taskSummary?.data)
      const series = [taskSummary.data.data?.openTasks, taskSummary.data.data?.completedTasks, taskSummary.data.data?.closedTasks, taskSummary.data.data?.draftTasks]
      setSeries(series)
      setprogressArray(calculateProgress(series))
      
    }
  }, [taskSummary])

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])


  return (
    <div id="test-3" className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Tasks Summary</span>
        </h3>
      </div>
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_44_chart' style={{ height: '350px' }}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )


  function getChartOptions(height: number): ApexOptions {
    // const labelColor = getCSSVariableValue('--bs-gray-500')
    // const borderColor = getCSSVariableValue('--bs-gray-200')

    // const baseColor = getCSSVariableValue('--bs-primary')
    // const secondaryColor = getCSSVariableValue('--bs-info')

    return {
      series: progressArray && progressArray,
      chart: {
        type: 'donut',
        height: 350,
      },
      labels: [`Open  ${series[0] ?": "+series[0]:"" } `, `Completed ${series[1] ?": "+series[1]:""} `,`Closed ${series[2] ?": "+series[2]:""} `,`Draft ${series[3] ?": "+series[3]:""} `],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 5,
          columnWidth: '70%',
          barHeight: '70%',
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      tooltip: {
        y: {
          formatter: function (val) {
    
            return val + '%'
          },
        },
      },
      colors: ['#1570EF', '#1BC5BD', '#B6B6B6', '#FFA800'],
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
      dataLabels: {
        enabled: false,
      }
    }
  }
}

export { PieChart }

