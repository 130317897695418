import { useEffect, useState } from 'react';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useNavigate, useLocation } from 'react-router-dom';

const GuidedTour = () => {
    const cuurentUser: any = localStorage.getItem('User')
    const role: any = JSON.parse(cuurentUser)?.roles
    const UserEmail: any = JSON.parse(cuurentUser)?.email
    const [IntroSeen, setIntroSeen] = useState<any>(null)
    const navigate = useNavigate();
    const location = useLocation();
    function EmailChaeck(Email: any) {
        const currentPath = location.pathname;
        if (Email) {
            // Retrieve the current hasSeenIntro array from local storage
            const existingEmailsJSON = localStorage.getItem('hasSeenIntro');
            let hasSeenIntro = [];
            if (existingEmailsJSON) {
                // Parse the existing JSON to get the array
                hasSeenIntro = JSON.parse(existingEmailsJSON);
                // Check if the entered email is already in the array
                if (hasSeenIntro.includes(Email)) {
                    setIntroSeen(true)
                } else {
                    if (currentPath === "/profile/overview") {
                        setIntroSeen(false)
                    } else {
                        navigate('/profile/overview', { replace: true })
                    }
                }
            } else {
                if (currentPath === "/profile/overview") {
                    setIntroSeen(false)
                } else {
                    navigate('/profile/overview', { replace: true })
                }
            }
        }
    }
    function StopIntro() {
        if (UserEmail) {
            // Retrieve the current hasSeenIntro array from local storage
            const existingEmailsJSON = localStorage.getItem('hasSeenIntro');
            let hasSeenIntro = [];

            if (existingEmailsJSON) {
                // Parse the existing JSON to get the array
                hasSeenIntro = JSON.parse(existingEmailsJSON);
            }

            // Add the new email to the array
            hasSeenIntro.push(UserEmail);

            // Store the updated array back in local storage
            localStorage.setItem('hasSeenIntro', JSON.stringify(hasSeenIntro));
        }
    }
    useEffect(() => {
        if (UserEmail) {
            EmailChaeck(UserEmail)
        }
    }, [UserEmail])
    useEffect(() => {
        const stepsInspectorAdmin: any = [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                    onNextClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 100)
                    },
                },
            },
            {
                element: '#BasicInformation',
                popover: {
                    title: 'Basic Information',
                    description: 'Fill in your information',
                    onNextClick: () => {
                        navigate('/profile/billing', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 100)
                    },
                    onPrevClick: () => {
                        navigate('/profile/overview', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 100)
                    },

                }
            },

            {
                element: '#billing',
                popover: {
                    title: 'Billing',
                    description: 'Go to billing to see all of your invoices',
                    onPrevClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Welcome to your reporting dashboard with a summary of all your projects and tasks',
                    onNextClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                    onPrevClick: () => {
                        navigate('/profile/billing', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                },
            },
            {
                element: '#test-3',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the tasks summary',
                    side: "top",
                    align: 'start',
                    onPrevClick: () => {
                        navigate('/profile/billing', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#test-4',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the projects overview',
                    side: "top",
                    align: 'start',

                }
            },
            {
                element: '#test-2',
                popover: {
                    title: 'Dashboard',
                    description: 'To access tasks summary for each project, utilize the filtering option by project',
                    align: "start",
                    side: "right",

                }
            },
            {
                element: '#Projects',
                popover: {
                    title: 'Projects',
                    description: 'Go to projects',
                    onNextClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                },
            },
            {
                element: '#ProjectTabel',
                popover: {
                    title: 'Projects',
                    description: 'This project list presents the details of individual projects(name, team, description...)',
                    side: "right",

                    onPrevClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#docs-sidebar',
                popover: {
                    title: 'Project',
                    description: 'Create new project',
                    side: "right",
                    onNextClick: () => {
                        navigate('/new-project', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },
            {
                element: '#ProjectDetails',
                popover: {
                    title: 'Projects Details',
                    description: 'Here, you have the ability to update project details',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Locations',
                popover: {
                    title: 'Locations',
                    description: 'Add specific project locations for easy navigation',
                    side: "right",

                }
            },
            {
                element: '#ProjectTeam',
                popover: {
                    title: 'Project Team',
                    description: 'Add project team that are related to the project',
                    side: "right",

                }
            },
            {
                element: '#TagsProject',
                popover: {
                    title: 'Tags',
                    description: 'Here you can specify tags of the project',
                    side: "right",
                    onNextClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },

            {
                element: '#PROJECTNAME',
                popover: {
                    title: 'Projects',
                    description: 'To access tasks related to a project, click on the project name',
                    side: "right",
                    onNextClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                    onPrevClick: () => {
                        navigate('/new-project', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#tasksTable',
                popover: {
                    title: 'Tasks',
                    description: 'This is the task list containing all the added tasks',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#AddTask',
                popover: {
                    title: 'Tasks',
                    description: 'Create new task',
                    side: "right",
                }
            },
            {
                element: '#Teams',
                popover: {
                    title: 'Teams',
                    description: 'Go to teams', side: "right",
                    onNextClick: () => {
                        navigate('/team-management/teams', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },

            {
                element: '#teamTable',
                popover: {
                    title: 'Teams',
                    description: 'This list displays all the added teams.',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Addteammember',
                popover: {
                    title: 'Team',
                    description: 'Create new team member.',
                    side: "right",

                }
            },
        ];
        const stepsInspector: any = [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                    onNextClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 100)
                    },
                },
            },
            {
                element: '#BasicInformation',
                popover: {
                    title: 'Basic Information',
                    description: 'Fill in your information',
                    onPrevClick: () => {
                        navigate('/profile/overview', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 100)
                    },

                }
            },

            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Welcome to your reporting dashboard with a summary of all your projects and tasks',
                    onNextClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },

                },
            },
            {
                element: '#test-3',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the tasks summary',
                    side: "top",
                    align: 'start',
                    onPrevClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#test-4',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the projects overview',
                    side: "top",
                    align: 'start',

                }
            },
            {
                element: '#test-2',
                popover: {
                    title: 'Dashboard',
                    description: 'To access tasks summary for each project, utilize the filtering option by project',
                    align: "start",
                    side: "right",

                }
            },
            {
                element: '#Projects',
                popover: {
                    title: 'Projects',
                    description: 'Go to projects',
                    onNextClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                },
            },
            {
                element: '#ProjectTabel',
                popover: {
                    title: 'Projects',
                    description: 'This project list presents the details of individual projects(name, team, description...)',
                    side: "right",

                    onPrevClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#PROJECTNAME',
                popover: {
                    title: 'Projects',
                    description: 'To access tasks related to a project, click on the project name',
                    side: "right",
                    onNextClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },
           
            {
                element: '#tasksTable',
                popover: {
                    title: 'Tasks',
                    description: 'This is the task list containing all the added tasks',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#AddTask',
                popover: {
                    title: 'Tasks',
                    description: 'Create new task',
                    side: "right",
                }
            },
            {
                element: '#Teams',
                popover: {
                    title: 'Teams',
                    description: 'Go to teams', side: "right",
                    onNextClick: () => {
                        navigate('/team-management/teams', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },

            {
                element: '#teamTable',
                popover: {
                    title: 'Teams',
                    description: 'This list displays all the added teams.',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
        ];
        const stepsContractorAdmin: any = [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                    onNextClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 100)
                    },
                },
            },
            {
                element: '#BasicInformation',
                popover: {
                    title: 'Basic Information',
                    description: 'Fill in your information',
                    onPrevClick: () => {
                        navigate('/profile/overview', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Welcome to your reporting dashboard with a summary of all your tasks and subcontractors',
                    onNextClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                },
            },
            {
                element: '#TasksSummarySubcontractor',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the tasks summary',
                    side: "top",
                    align: 'start',
                    onPrevClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },

                }
            },
            {
                element: '#testSubcontractorTable',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the Subcontractors Overview',
                    side: "top",
                    align: 'start',

                }
            },
            {
                element: '#Projects',
                popover: {
                    title: 'Projects',
                    description: 'Go to projects',
                    onNextClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                },
            },
            {
                element: '#ProjectTabel',
                popover: {
                    title: 'Projects',
                    description: 'This project list presents the details of individual projects(name, team, description...)',
                    side: "right",

                    onPrevClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#PROJECTNAME',
                popover: {
                    title: 'Projects',
                    description: 'To access tasks related to a project, click on the project name',
                    side: "right",
                    onNextClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },
            {
                element: '#tasksTable',
                popover: {
                    title: 'Tasks',
                    description: 'This is the task list containing all the added tasks',
                    side: "right",
                    onNextClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                    onPrevClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },

                }
            },
            {
                element: '#Subcontractor',
                popover: {
                    title: 'Subcontractor',
                    description: 'Go to subcontractor', side: "right",
                    onNextClick: () => {
                        navigate('/subcontractor-management/subcontractors', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },

            {
                element: '#SubcontractorTable',
                popover: {
                    title: 'Subcontractor',
                    description: 'This list displays all the added Subcontractors',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#AddSubcontractor',
                popover: {
                    title: 'Subcontractor',
                    description: 'Create new Subcontractor',
                    side: "right",
                }
            },
        ];
        const stepsContractor: any = [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                    onNextClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 100)
                    },
                },
            },
            {
                element: '#BasicInformation',
                popover: {
                    title: 'Basic Information',
                    description: 'Fill in your information',
                    onPrevClick: () => {
                        navigate('/profile/overview', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Welcome to your reporting dashboard with a summary of all your tasks and subcontractors',
                    onNextClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                },
            },
            {
                element: '#TasksSummarySubcontractor',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the tasks summary',
                    side: "top",
                    align: 'start',
                    onPrevClick: () => {
                        navigate('/profile/settings', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },

                }
            },
            {
                element: '#testSubcontractorTable',
                popover: {
                    title: 'Dashboard',
                    description: 'Check out the Subcontractors Overview',
                    side: "top",
                    align: 'start',

                }
            },
            {
                element: '#Projects',
                popover: {
                    title: 'Projects',
                    description: 'Go to projects',
                    onNextClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                },
            },
            {
                element: '#ProjectTabel',
                popover: {
                    title: 'Projects',
                    description: 'This project list presents the details of individual projects(name, team, description...)',
                    side: "right",

                    onPrevClick: () => {
                        navigate('/dashboard', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#PROJECTNAME',
                popover: {
                    title: 'Projects',
                    description: 'To access tasks related to a project, click on the project name',
                    side: "right",
                    onNextClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },
            {
                element: '#tasksTable',
                popover: {
                    title: 'Tasks',
                    description: 'This is the task list containing all the added tasks',
                    side: "right",
                    onNextClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                    onPrevClick: () => {
                        navigate('/project-management/projects', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },

                }
            },
            {
                element: '#Subcontractor',
                popover: {
                    title: 'Subcontractor',
                    description: 'Go to subcontractors', side: "right",
                    onNextClick: () => {
                        navigate('/subcontractor-management/subcontractors', { replace: true })
                        setTimeout(() => {
                            console.log('In Project')
                            driverObj.moveNext();
                        }, 1000)
                    },
                }
            },

            {
                element: '#SubcontractorTable',
                popover: {
                    title: 'Subcontractor',
                    description: 'This list displays all the added subcontractor.',
                    side: "right",
                    onPrevClick: () => {
                        navigate('/task-management/tasks/project/0', { replace: true })
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
        ];
        function StepsRe() {
            if (role[0] === "Inspector") {
                return stepsInspector
            } else if (role[0] === "Inspector Admin") {
                return stepsInspectorAdmin
            } else if (role[0] === "Contractor Admin") {
                return stepsContractorAdmin
            } else if (role[0] === "Contractor") {
                return stepsContractor
            }
        }
        function StepsDone() {
            if (role[0] === "Inspector") {
                return "#teamTable"
            } else if (role[0] === "Inspector Admin") {
                return "#Addteammember"
            } else if (role[0] === "Contractor Admin") {
                return "#AddSubcontractor"
            } else if (role[0] === "Contractor") {
                return "#SubcontractorTable"
            }
        }
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            nextBtnText: 'Next',
            prevBtnText: 'Previous',
            doneBtnText: 'Done',
            steps: StepsRe(),
            allowClose: false,
            smoothScroll: true,
            onCloseClick: (popover, state) => {
                console.log("")
            },
            onPopoverRender: (popover, { config, state }) => {
                if (state?.activeIndex !== StepsRe()?.length - 1) {
                    const firstButton = document.createElement('button');
                    firstButton.innerText = 'Skip';
                    popover.footerButtons.appendChild(firstButton);
                    firstButton.addEventListener('click', () => {
                        StopIntro();
                        driverObj.destroy();
                    });
                }
            },
            onDestroyed: (popover, config, state) => {
                if (config?.element === StepsDone()) {
                    if (UserEmail) {
                        // Retrieve the current hasSeenIntro array from local storage
                        const existingEmailsJSON = localStorage.getItem('hasSeenIntro');
                        let hasSeenIntro = [];
                        if (existingEmailsJSON) {
                            // Parse the existing JSON to get the array
                            hasSeenIntro = JSON.parse(existingEmailsJSON);
                            // Check if the entered email is already in the array
                            if (hasSeenIntro.includes(UserEmail)) {
                                setIntroSeen(true)
                            } else {
                                StopIntro()
                            }
                        } else {
                            StopIntro()
                        }
                    }
                } else {
                    console.log("")
                }
            },
        });
        if (IntroSeen === false) {
            setTimeout(() => {
                driverObj.drive();
            }, 2000)
        }
    }, [IntroSeen]);

    return null;
};

export default GuidedTour;
