import { FC, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ReactSelectStyles } from '../../../../global/ReactSelectStyle'
import Select from 'react-select'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useQuery, useQueryClient } from 'react-query'
import { InitAdrressValues } from '../BillingWraper'
const AddressSchema = Yup.object().shape({
    address1: Yup.string().required('Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zipCode: Yup.string().required('Zip Code is required'),
})
const API_URL = process.env.REACT_APP_API_URLL

const getCountries = (): Promise<any> => {
    return axios.get(`${API_URL}/Generic/Countries`)
}

type Props = {
    setIsOpen: (isOpen: boolean) => void
    initialValues: InitAdrressValues
}

const AddressModalForm: FC<Props> = ({ setIsOpen, initialValues }) => {
    const queryClient = useQueryClient()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async (data: any) => {
        setIsSubmitting(true)
        try {
            await axios.put(`${API_URL}/billing/BillingAddress`, data).then((res) => {
                Swal.fire({
                    text: `Address has been updated successfully!`,
                    icon: 'success',
                    showConfirmButton: true,
                    timer: 2000,
                    width: '25rem',
                })
                setIsOpen(false)
                queryClient.invalidateQueries('BillingDetails')
            })
        } catch (error: any) {
            Swal.fire({
                text: error.response.data,
                icon: 'error',
                showConfirmButton: true,
                timer: 2000,
                width: '25rem',
            })
        }
        finally {
            setIsSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: AddressSchema,
        onSubmit: async (values, { setSubmitting }) => {
            handleSubmit(values)
        },
    })

    const { data: countries } = useQuery('countries', getCountries, {
        refetchOnWindowFocus: false,
    })


    const { data: states, refetch } = useQuery('states', () => axios.get(`${API_URL}/Generic/States?id=${formik.values.countryId}`), {
        refetchOnWindowFocus: false,
        enabled: !!formik.values.countryId
    })


    useEffect(() => {
        !!formik.values.countryId && refetch()
    }, [formik.values.countryId])


    const countriesOptions = countries?.data?.countries?.map((country: any) => {
        return {
            label: country.name,
            value: country.id
        }
    })

    const statesOptions = states?.data?.states?.map((state: any) => {
        return {
            label: state.name,
            value: state.name
        }
    })

    return (
        <form className='form'>
            {/* begin::Scroll */}
            <div className='d-flex flex-column me-n7 pe-7'>
                <div className='d-flex flex-column mb-7 fv-row'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        <span className='required'>Address Line 1</span>
                    </label>

                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Address Line 1'
                        name='address1'
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                    />
                    {
                        formik.errors.address1 && formik.touched.address1 ? (
                            <div className='text-danger mt-2'>
                                <span className='p-2'>{formik.errors.address1}</span>
                            </div>
                        ) : null
                    }
                </div>
                <div className='d-flex flex-column mb-7 fv-row'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        <span >Address Line 2</span>
                    </label>

                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Address Line 2'
                        name='address2'
                        value={formik.values.address2}
                        onChange={formik.handleChange}
                    />
                    {
                        formik.errors.address2 && formik.touched.address2 ? (
                            <div className='text-danger mt-2'>
                                <span className='p-2'>{formik.errors.address2}</span>
                            </div>
                        ) : null
                    }
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>City</span>
                            </label>

                            <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='City'
                                name='city'
                                value={formik.values.city}
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.city && formik.touched.city ? (
                                    <div className='text-danger mt-2'>
                                        <span className='p-2'>{formik.errors.city}</span>
                                    </div>
                                ) : null
                            }
                        </div>

                    </div>
                    <div className='col-6'>
                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>State</span>
                            </label>
                            <Select
                                options={statesOptions}
                                styles={ReactSelectStyles}
                                name='state'
                                placeholder='State'
                                value={statesOptions?.find((option: any) => option.value === formik.values.state)}
                                onChange={(option: any) => formik.setFieldValue('state', option.value)}
                            />
                            {
                                formik.errors.state && formik.touched.state ? (
                                    <div className='text-danger mt-2'>
                                        <span className='p-2'>{formik.errors.state}</span>
                                    </div>
                                ) : null
                            }
                        </div>

                    </div>

                </div>

                <div className='row'>

                    <div className='col-6'>
                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>Zip Code</span>
                            </label>

                            <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='Zip Code'
                                name='zipCode'
                                value={formik.values.zipCode}
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.zipCode && formik.touched.zipCode ? (
                                    <div className='text-danger mt-2'>
                                        <span className='p-2'>{formik.errors.zipCode}</span>
                                    </div>
                                ) : null
                            }
                        </div>

                    </div>

                    <div className='col-6'>
                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>Country</span>
                            </label>

                            <Select
                                options={countriesOptions}
                                styles={ReactSelectStyles}
                                name='country'
                                placeholder='Country'
                                value={countriesOptions?.find((option: any) => option.label === formik.values.country)}
                                onChange={(option: any) => {
                                    formik.setFieldValue('country', option.label)
                                    formik.setFieldValue('countryId', option.value)
                                }}
                            />

                            {
                                formik.errors.country && formik.touched.country ? (
                                    <div className='text-danger mt-2'>
                                        <span className='p-2'>{formik.errors.country}</span>
                                    </div>
                                ) : null
                            }
                        </div>

                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button
                        disabled={isSubmitting}
                        type='button'
                        className='btn btn-primary'
                        onClick={() => formik.handleSubmit()}>
                        {
                            isSubmitting ? 'Please wait...' : 'Save Changes'
                        }
                    </button>
                </div>
            </div>
        </form>
    )
}

export { AddressModalForm }
