import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { KTCard } from '../../../../../_metronic/helpers'
import { TeamsListHeader } from './components/header/TeamsListHeader'
import { TasksTable } from './table/TasksTable'
import { TaskEditModal } from './task-edit-form/TaskEditModal'
import { TasksDetails } from './task-edit-form/TaskDetails'
import { ActivitiesModal } from '../../../global/activities-modal/ActivitiesModal'
import { ReportModal } from './report-modal/ReportModal'

const TasksList = () => {
  const { itemIdForUpdate, activitiesId, itemIdForUpdatePassword, setItemIdForUpdatePassword, openReport } = useListView()
  return (
    <>
      <KTCard>
        <TeamsListHeader />
        <TasksTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TaskEditModal />}
      {activitiesId !== undefined && <TasksDetails />}
      {openReport && <ReportModal />}
      {itemIdForUpdatePassword !== undefined && <ActivitiesModal url='Tasks' setActivitiesId={setItemIdForUpdatePassword} activitiesId={itemIdForUpdatePassword} />}
    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TasksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { UsersListWrapper }
