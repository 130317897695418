import React, { FC } from 'react'
import { ImageCardNumber2 } from '../../../global/ImageCard'

type Props = {
    setIsOpen: (isOpen: boolean) => void
    data: any
}
const PaymentMethods: FC<Props> = ({ setIsOpen, data }) => {
    return (
        <div className="card  mb-5 mb-xl-10  card-flush h-xl-100">
            <div className="card-header card-header-stretch pb-0">
                <div className="card-title">
                    <h3 className="m-0">Payment Method</h3>
                </div>
            </div>
            <div className="card-body tab-content">
                <div
                    className="tab-pane fade show active"
                >
                    <div className="row gx-9 gy-6">
                        <div className="col-12 pb-4">
                            <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                                <div className="d-flex flex-column py-2">
                                    <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                                        {data?.subscription?.cardName}
                                        <span className="badge badge-light-success fs-7 ms-2">
                                            Primary
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {ImageCardNumber2(data?.subscription?.cardBrand)}
                                        <div>
                                            <div className="fs-4 fw-bold">{data?.subscription?.cardBrand} **** {data?.subscription?.card}</div>
                                            <div className="fs-6 fw-semibold text-gray-400">
                                                Card expires at {data?.subscription?.cardExpiredMonth}/
                                                {
                                                    data?.subscription?.cardExpiredYear?.toString().slice(-2)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center py-2">
                                    <button
                                        className="btn btn-sm btn-light btn-active-light-primary"
                                        onClick={() => {
                                            setIsOpen(true)
                                        }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PaymentMethods