import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Team, TeamPassword, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URLL
const USER_URL = `${API_URL}/Teams`
const USER_PASSWORD_URL = `${API_URL}/Teams/Password`

const getUsers = (id: any, query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${API_URL}/Projects/ProjectTeam/${id}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Team | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Team>>) => response.data)
    .then((response: Response<Team>) => response.data)
}

const createUser = (user: Team): Promise<Team | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<Team>>) => response.data)
    .then((response: Response<Team>) => response.data)
}

const updateUser = (user: Team): Promise<Team | undefined> => {
  return axios
    .put(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Team>>) => response.data)
    .then((response: Response<Team>) => response.data)
}
const updateUserPassword = (teamPassword: TeamPassword): Promise<TeamPassword | undefined> => {
  return axios
    .put(`${USER_PASSWORD_URL}`, teamPassword)
    .then((response: AxiosResponse<Response<TeamPassword>>) => response.data)
    .then((response: Response<TeamPassword>) => response.data)
}
const activateUser = (data: any): Promise<any | undefined> => {
  return axios
    .put(`${USER_URL}/Activate`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}


const deleteSelectedUsers = (id:any ,userIds: Array<ID>): Promise<void> => {
  return axios.delete(`${API_URL}/Projects/TeamDelete/${id}`, {
    data: {
      add: false,
      teamIds: userIds,
    }
  });
}


export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  updateUserPassword,
  activateUser,
}
