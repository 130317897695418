import {useListView} from '../../core/ListViewProvider'
import { TeamsListToolbar } from './TeamsListToolbar'
import { TeamsListGrouping } from './TeamsListGrouping'
import { TeamsListSearchComponent } from './TeamsListSearchComponent'

const TeamsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TeamsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TeamsListGrouping /> : <TeamsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TeamsListHeader}
