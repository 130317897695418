import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import { TagEditModal } from './tag-edit-modal/TagEditModal'
import { TagsListHeader } from './components/header/TagsListHeader'
import { TagsTable } from './table/UsersTable'
import { ActivitiesModal } from '../../../global/activities-modal/ActivitiesModal'

const TagsList = () => {
  const {itemIdForUpdate,activitiesId,setActivitiesId} = useListView()
  return (
    <>
      <KTCard>
        <TagsListHeader />
        <TagsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TagEditModal />}
      {activitiesId !== undefined && <ActivitiesModal url='Tags' setActivitiesId={setActivitiesId} activitiesId={activitiesId} />}

    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TagsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}
