/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { ID, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { DateFormat } from '../DateFormat'
import SVG from 'react-inlinesvg'
import {TimeConversion} from '../../../modules/global/index'

type Props = {
    id: ID
    url: string
}

const TableActivites: React.FC<Props> = ({ id, url }) => {
    const [expandedRow, setExpandedRow] = useState<number | null>(null)
    const API_URL = process.env.REACT_APP_API_URLL
    const ACTIVITIES_URL = `${API_URL}/${url}/Activities/${id}`

    const [currentPage, setCurrentPage] = useState(1)
    const [activitiesPerPage] = useState(10)

    const { data: activities } = useQuery(`activities/${id}`, () => {
        return axios.get(`${ACTIVITIES_URL}`)
    }, {
        cacheTime: 0
    })

    const indexOfLastActivity = currentPage * activitiesPerPage
    const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage
    const currentActivities = activities?.data?.activities?.slice(indexOfFirstActivity, indexOfLastActivity)

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber)
    }

    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(activities?.data?.activities?.length / activitiesPerPage); i++) {
        pageNumbers.push(i)
    }

    return (
        <div className='table-responsive custom-scrollbar'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                    <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>User</th>
                        <th className='min-w-140px'>Event</th>
                        <th className='min-w-120px'>Date</th>
                        <th className='min-w-120px'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentActivities?.map((activity: any, index: any) => (
                            <React.Fragment key={index}>
                                <tr className='cursor-pointer' onClick={() => setExpandedRow(expandedRow === index ? null : index)}>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {activity.user}
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {activity.action}
                                        </span>
                                    </td>
                                    <td className='text-end'>
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='text-muted me-2 fs-7 fw-semibold'>
                                                    {
                                                    TimeConversion(activity?.created,localStorage?.getItem("TimeZone"))                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='text-end cursor-pointer'>
                                        <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Info-circle.svg')} />
                                    </td>
                                </tr>
                                {expandedRow === index && (
                                    <tr>
                                        <td colSpan={4}>
                                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                                <thead>
                                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                        <th className="p-0 pb-3 min-w-175px text-start">FIELD</th>
                                                        <th className="p-0 pb-3 min-w-175px text-start">OLD VALUE</th>
                                                        <th className="p-0 pb-3 min-w-175px text-start">NEW VALUE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        activity?.fields.length > 0 ? activity?.fields?.map((field: any, index: any) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <span className='fw-semibold d-block fs-7'>
                                                                        {field.field}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className='fw-semibold d-block fs-7'>
                                                                        {field.oldValue}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className='fw-semibold d-block fs-7'>
                                                                        {field.newValue}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td className='fw-bold fs-7 text-center p-5' colSpan={4}>No Change to Display</td></tr>
                                                    }
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))
                    }
                </tbody>
            </table>
            <div className='d-flex justify-content-center'>
                <ul className='pagination'>
                    {
                        pageNumbers?.map(number => (
                            <li key={number} className='page-item cursor-pointer'>
                                <span onClick={() => paginate(number)} className='page-link'>
                                    {number}
                                </span>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export { TableActivites }
