/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { useMemo,useState,useEffect } from 'react';

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous';
  }

  if (label === 'Next &raquo;') {
    return 'Next';
  }

  return label;
};

const UsersListPagination = () => {
  const pagination = useQueryResponsePagination();
  const isLoading = useQueryResponseLoading();
  const [totalcount,settotalcount]= useState<any>([])
  const { updateState } = useQueryRequest();
  const { pageCount, pageNumber } = pagination || {};
  useEffect(()=>{
    settotalcount(pagination)
  },[pagination])
  const paginationLinks = useMemo(() => {
    const links = [];

    const displayedPages = 5; // Number of pages to display (excluding ellipsis)

    // Calculate the range of pages to display
    let startPage = Math.max(1, pageNumber - Math.floor(displayedPages / 2));
    let endPage = Math.min(pageCount, startPage + displayedPages - 1);

    // Adjust the range if it exceeds the bounds
    const totalPages = endPage - startPage + 1;
    if (totalPages < displayedPages) {
      if (startPage === 1) {
        endPage = Math.min(pageCount, startPage + displayedPages - 1);
      } else {
        startPage = Math.max(1, endPage - displayedPages + 1);
      }
    }

    // Add ellipsis if necessary
    if (startPage > 1) {
      links.push({
        label: '...',
        page: startPage - 1,
      });
    }

    // Add page links
    for (let i = startPage; i <= endPage; i++) {
      links.push({
        label: i.toString(),
        page: i,
      });
    }

    // Add ellipsis if necessary
    if (endPage < pageCount) {
      links.push({
        label: '...',
        page: endPage + 1,
      });
    }

    // add Previous link
    links.unshift({
      label: 'Previous',
      page: pageNumber > 1 ? pageNumber - 1 : null,
    });

    // add Next link
    links.push({
      label: 'Next',
      page: pageNumber < pageCount ? pageNumber + 1 : null,
    });

    return links;
  }, [pageCount, pageNumber]);

  const updatePage = (pageNumber: number | null) => {
    if (!pageNumber || isLoading || pagination.pageNumber === pageNumber) {
      return;
    }

    updateState({ pageNumber, pageSize: pagination.pageSize || 10 });
  };

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
      <b>Total rows: {totalcount.totalItemCount}</b>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {paginationLinks.map((link) => (
              <li
                key={link.label}
                className={clsx('page-item', {
                  active: pagination.pageNumber === link.page,
                  disabled: isLoading || link.page === null,
                  previous: link.label === 'Previous',
                  next: link.label === 'Next',
                })}
              >
                <a
                  className={clsx('page-link', {
                    'page-text': link.label === 'Previous' || link.label === 'Next',
                    'me-5': link.label === 'Previous',
                  })}
                  onClick={() => updatePage(link.page)}
                  style={{ cursor: 'pointer' }}
                >
                  {mappedLabel(link.label)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { UsersListPagination };
