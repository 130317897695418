import React, { FC } from 'react'
import { User } from '../../core/_models'

type Props = {
  user: User
}
const TaskStatus: FC<Props> = ({ user }) => {

  const handleLbaleColor = (statusName: any) => {
    if (statusName === 'Draft') {
      return 'warning'
    } else if (statusName === 'Open') {
      return 'secondary'
    } else if (statusName === 'Completed') {
      return 'success'
    } else if (statusName === 'Closed') {
      return 'primary'
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className={`badge badge-${handleLbaleColor(user.statusName)} fw-bolder my-1`}>
          {
            user.statusName
          }
        </span>
      </div>
    </div>
  )
}

export default TaskStatus