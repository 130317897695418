import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import {FC} from 'react'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const STRIPE_PROMISE = process.env.REACT_APP_STRIPE_PROMISE
const stripePromise = loadStripe(STRIPE_PROMISE as string)

type Props = {
formik: any
handleSubmit2: (data:any) => void
}
const Payment2: FC<Props> = ({formik , handleSubmit2}) => {

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm formik={formik} handleSubmit2={handleSubmit2}/>
        </Elements>
      )}
    </>
  )
}
export default Payment2
