import { FC } from 'react'
import { User } from '../../core/_models'

type Props = {
  user: User
}

const TaskLocationCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-600 text-hover-primary mb-1'>
        {
          user?.locationPath || user?.importingLoaction
        }
      </a>
    </div>
  </div>
)

export { TaskLocationCell }
