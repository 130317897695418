// @ts-nocheck
import {Column} from 'react-table'
import {TagSelectionCell} from './TagSelectionCell'
import {TagSelectionHeader} from './TagSelectionHeader'
import {User} from '../../core/_models'
import { TagActionsCell } from './TagActionsCell'
import { TagCustomHeader } from './TagCustomHeader'
import { TagInfoCell } from './TagInfoCell'
function extractDetailsFromUrl(url: string) {
  const searchParams = new URLSearchParams(new URL(url).search)
  return searchParams.get('details') === 'true'
}
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) =>
    <>{ extractDetailsFromUrl(window.location.href)===false?  <TagSelectionHeader tableProps={props} />    :""}</> 
     ,
    id: 'selection',
    Cell: ({...props}) => <TagSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TagCustomHeader tableProps={props} title='Tag Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <TagInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TagCustomHeader tableProps={props} title='Description' className='min-w-125px' />,
    accessor: 'description',
  },
  {
    Header: (props) => (
      <TagCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TagActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
