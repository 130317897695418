import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import { TeamsListHeader } from './components/header/TeamsListHeader'
import { TeamsTable } from './table/TeamsTable'
import { TeamsEditModal } from './team-edit-modal/TeamsEditModal'
import { PasswordEditModal } from './password-edit-modal/PasswordEditModal'
import { ActivitiesModal } from '../../../global/activities-modal/ActivitiesModal'

const TeamsList = () => {
  const {itemIdForUpdate,itemIdForUpdatePassword, activitiesId,setActivitiesId} = useListView()
  return (
    <>
      <KTCard>
        <TeamsListHeader />
        <TeamsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TeamsEditModal />}
      {itemIdForUpdatePassword !== undefined && <PasswordEditModal />}
      {activitiesId !== undefined && <ActivitiesModal url='Teams' setActivitiesId={setActivitiesId} activitiesId={activitiesId} />}
    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TeamsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper , TeamsList}
