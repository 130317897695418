import {useEffect, useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
// import {TaskImageViewerModal} from '../images-viewer/ImageViewerModal'
// import {handleApiErrors} from '../../../../global/HandleApiErrors'
// import {TimeConversion} from '../../../../global'
// import {EmailModal} from '../email-modal/EmailModal'
// import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import { TimeConversion } from '../../../../app/modules/global'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { EmailModal } from '../../../../app/modules/apps/task-management/teams-list/email-modal/EmailModal' 
import { handleApiErrors } from '../../../../app/modules/global/HandleApiErrors'
import { TaskImageViewerModal } from '../../../../app/modules/apps/task-management/teams-list/images-viewer/ImageViewerModal'
const TasksDetails = (idd:any) => {
  const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles
  const API_URL = process.env.REACT_APP_API_URLL
  const [data, setData] = useState<any>()
  const [dataComments, setDataComment] = useState<any>()
  const [imageId, setImageId] = useState<any>()
  const [imageSrc, setImageSrc] = useState<any>()
  const [rejectR, setrejectR] = useState(false)
  const [reason, setreason] = useState<string>('')
  const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false)
  const [comments, setComments] = useState<any>({
    comment: '',
    title: '',
    type: '',
  })
  const [commentIdForEdit, setCommentIdForEdit] = useState<any>(undefined)

  const getData = async () => {
    const res = await axios.get(`${API_URL}/Tasks/${idd.idd}`)
    setData(res.data)
  }
  const getComments = async (id: any) => {
    const res = await axios.get(`${API_URL}/Tasks/Comments/${id}`)
    setDataComment(res.data)
  }

  const handlePostComment = async (id: any, data: any) => {
    await axios.post(`${API_URL}/Tasks/Comments/${id}`, data)
  }
  const handlePutComment = async (data: any) => {
    try {
      const response = await axios.put(`${API_URL}/Tasks/EditComments/${commentIdForEdit}`, data)
      if (response.status === 200) {
        setComments({
          comment: '',
          title: '',
          type: '',
        })
        setCommentIdForEdit(undefined)
        Swal.fire({
          text: 'Comment has been updated.',
          icon: 'success',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
      }
    } catch (error: any) {
      handleApiErrors(error?.response?.data)
    }
  }

  const handleDeleteComment = async (id: any) => {
    await axios.delete(`${API_URL}/Tasks/DeleteComments/${id}`)
  }
  const handlepostreject = async (id: any) => {
    await axios.post(`${API_URL}/Tasks/Comments/${id}`, {
      comment: reason,
      title: null,
      type: null,
      rejected: true,
    })
  }
  const handleChangeStatus = async (id: any, statusId: any) => {
    await axios.put(`${API_URL}/Tasks/ChangeStatus/${id}/?StatusId=${statusId}`)
  }

  // useEffect(() => {
  //   if ((window as any).taskId) {
  //     getData()
  //   }
  // }, [(window as any).taskId])

  useEffect(() => {
    if (data) {
      getComments(data?.data?.id)
    }
  }, [data])
  useEffect(() => {
    console.log(idd)
    getData()
  }, [])
  const handleLbaleColor = (statusName: any) => {
    if (statusName === 'Draft') {
      return 'primary'
    } else if (statusName === 'Open') {
      return 'success'
    }
  }
  const handleStatusLabel = (statusName: any) => {
    if (statusName === 'Draft') {
      return 'Open'
    } else if (statusName === 'Open') {
      return 'Complete'
    } else if (statusName === 'Completed') {
      return 'Reject'
    } else if (statusName === 'Closed') {
      return 'Reopen'
    }
  }

  const handleNextStatus = (statusName: any) => {
    if (statusName === 'Draft' || statusName === null) {
      return 2
    } else if (statusName === 'Open') {
      return 4
    } else if (statusName === 'Completed') {
      return 5
    } else if (statusName === 'Closed') {
      return false
    }
  }

  return (
    <>
      <input
        hidden
        id='taskiD'
        onClick={() => {
          getData()
        }}
      />
      <form id='kt_modal_add_user_form'>
        {/* begin::Scroll */}
        <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_user_scroll'>
          <div className='fv-row mb-7'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <label className='fw-bold fs-6 mb-2'>Task Name</label>
                <br></br>
                <h2 className='fw-bold ms-7 mt-5'>{data?.data?.name}</h2>
              </div>
              <button
                onClick={() => setIsEmailOpen(true)}
                type='button'
                className={`btn btn-light-success btn-sm mb-8 cursor-pointer`}
              >
                <i className='fas fa-envelope me-2'></i>
                Send email
              </button>
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Description</label>
            <br></br>
            <h4 className='fw-bold ms-7 mt-5'>{data?.data?.description ? data?.data?.description:''}</h4>
          </div>

          <div className='table-responsive custom-scrollbar'>
            {/*begin::Table*/}
            <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
              <tbody className='fw-semibold text-gray-600'>
                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon svg-icon-2x me-2'
                      />
                      Location
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.locationPath ?data?.data?.locationPath:''}
                  </td>
                </tr>
                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen049.svg'
                        className='svg-icon svg-icon-2x me-2'
                      />
                      Assignee
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.firstName?data?.data?.firstName !== null &&
                      data?.data?.firstName +
                        ' ' +
                        (data?.data?.lastName !== null && data?.data?.lastName):''}
                  </td>
                </tr>
                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon svg-icon-2x me-2'
                      /> Created By
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.createdByFirstName} {data?.data?.createdByLastName}
                  </td>
                </tr>
                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/files/fil002.svg'
                        className='svg-icon svg-icon-2x me-2'
                      />
                      Created On
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.createdDate?new Date(data?.data?.createdDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }):''}
                  </td>
                </tr>

                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com009.svg'
                        className='svg-icon svg-icon-2x me-2'
                      /> Tags
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.tags &&
                      data?.data?.tags.map((tag: any) => {
                        return <div className='badge badge-light-primary me-3'>{tag?.name}</div>
                      })}
                  </td>
                </tr>
                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2x me-2'
                      />  Due Date
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.dueDate?new Date(data?.data?.dueDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }):''}
                  </td>
                </tr>

                <tr>
                  <td className='text-muted'>
                    <div className='d-flex align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/finance/fin010.svg'
                        className='svg-icon svg-icon-2x me-2'
                      /> Value
                    </div>
                  </td>
                  <td className='fw-bold text-end'>
                    {data?.data?.value?new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(data?.data?.value):''}
                  </td>
                </tr>
              </tbody>
            </table>
            {/*end::Table*/}
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              Task Photos ({data?.data?.attachmentsCount})
            </label>
          </div>
          <div className='d-flex flex-wrap'>
            {data?.data?.attachments &&
              data?.data?.attachments.map((attachment: any) => {
                return (
                  <div
                    className='image-input image-input-empty image-input-outline image-input-placeholder m-5'
                    data-kt-image-input='true'
                    onClick={() => {
                      setImageId(attachment?.id)
                      setImageSrc(attachment?.path)
                    }}
                  >
                    <img
                      src={attachment?.path}
                      alt={attachment?.name}
                      className='image-input-wrapper w-125px h-125px  cursor-pointer'
                    />
                  </div>
                )
              })}
          </div>
          <div className='fv-row mt-7'>
            <label className='fw-bold fs-6 mb-2'>Comments</label>
            <div className='flex-column-fluid'>
              {dataComments?.data &&
                dataComments?.data?.map((comment: any) => {
                  return (
                    <>
                      <div
                        className={`border rounded p-2 p-lg-6 mb-10  ${
                          comment?.owner === true ? 'bg-light' : ''
                        }`}
                      >
                        <div className='mb-0'>
                          <div className='d-flex flex-stack flex-wrap mb-5'>
                            <div className='d-flex align-items-center py-1'>
                              <div className='symbol symbol-35px me-2'>
                                <div
                                  className={`symbol-label ${
                                    comment?.createdByPicture ? '' : 'bg-light-success'
                                  } fs-3 fw-bold text-success`}
                                >
                                  {comment?.createdByPicture ? (
                                    <div
                                      className='symbol symbol-35px rounded'
                                      data-bs-toggle='tooltip'
                                      title='Barry Walter'
                                    >
                                      <img alt='Pic' src={comment?.createdByPicture} />
                                    </div>
                                  ) : (
                                    comment?.createdByFirstName?.charAt(0)
                                  )}
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-start justify-content-center'>
                                <span className='text-gray-800 fs-7 fw-bold lh-1 mb-2'>
                                  {comment?.owner === true
                                    ? 'Me'
                                    : comment?.createdByFirstName +
                                      ' ' +
                                      comment?.createdByLastName}
                                </span>
                                <span className='text-muted fs-8 fw-bold lh-1'>
                                  {TimeConversion(
                                    comment?.created,
                                    localStorage?.getItem('TimeZone')
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className='d-flex align-items-center py-1'>
                              {comment?.type === 'Reject' ? (
                                <span className='badge badge-light-danger'>Rejection reason</span>
                              ) : (
                                ''
                              )}

                              {comment?.owner === true ? (
                                <>
                                  <i
                                    className='fas fa-edit ms-5 cursor-pointer'
                                    onClick={() => {
                                      setCommentIdForEdit(comment?.id)
                                      setComments({
                                        comment: comment?.value,
                                        title: comment?.title,
                                        type: comment?.type,
                                      })
                                    }}
                                  ></i>
                                  <i
                                    className='fas fa-trash text-danger ms-5 cursor-pointer'
                                    hidden={commentIdForEdit !== undefined}
                                    onClick={() =>
                                      handleDeleteComment(comment.id).then(() => {
                                        getComments(data?.data?.id)
                                        Swal.fire({
                                          text: 'Comment has been deleted.',
                                          icon: 'success',
                                          showConfirmButton: true,
                                          timer: 2000,
                                          width: '25rem',
                                        })
                                      })
                                    }
                                  ></i>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className='fs-5 fw-normal text-gray-800'>{comment?.value}</div>
                        </div>
                        <div className='ps-10 mb-0' />
                      </div>
                    </>
                  )
                })}
            </div>
          </div>

          <div>
            <label className='fw-bold fs-6 mb-2 mt-8'>Leave Comment</label>
            <textarea
              onChange={(e) => setComments({...comments, comment: e.target.value})}
              value={comments.comment}
              className='form-control form-control-solid mb-5'
              rows={3}
              placeholder='Leave a comment'
            />
            <div className='d-flex justify-content-end'>
              {commentIdForEdit && (
                <button
                  onClick={() => {
                    setComments({
                      comment: '',
                      title: '',
                      type: '',
                    })
                    setCommentIdForEdit(undefined)
                  }}
                  type='button'
                  className='btn btn-sm btn-light me-2'
                  data-bs-dismiss='modal'
                >
                  Cancel
                </button>
              )}
              <button
                type='button'
                className='btn btn-sm btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  if (commentIdForEdit) {
                    handlePutComment(comments).then(() => {
                      getComments(data?.data?.id)
                    })
                  } else {
                    handlePostComment(data?.data?.id, comments).then(() => {
                      setComments({
                        comment: '',
                        title: '',
                        type: '',
                      })
                      getComments(data?.data?.id)
                      Swal.fire({
                        text: 'Comment has been added.',
                        icon: 'success',
                        showConfirmButton: true,
                        timer: 2000,
                        width: '25rem',
                      })
                    })
                  }
                }}
              >
                {commentIdForEdit ? 'Update' : 'Post'}
              </button>
            </div>
          </div>
        </div>
        {rejectR === true ? (
          <div className='alert alert-light mt-3' role='alert'>
            <div className='mb-3'>
              <label className='form-label'>
                Rejection reason<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                className='form-control'
                id='exampleFormControlInput1'
                onChange={(e) => {
                  setreason(e.target.value)
                }}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {data?.data?.statusName === 'Closed' ? (
          <div className='text-center pt-15 pb-5 d-flex align-items-center'>
            {role[0] === 'Inspector Admin' && (
              <h2 className='text-muted fw-bold'>{data?.data?.statusName}</h2>
            )}
            {role[0] === 'Inspector Admin' && <div className='h-3px w-100 bg-success mx-2'></div>}
            {role[0] === 'Inspector Admin' && (
              <button
                type='button'
                onClick={() => {
                  handleChangeStatus(data?.data?.id, 2).then(() => {
                    getData()
                    document.getElementById('kt_table_users_refresssh')?.click()
                    Swal.fire({
                      text: 'Task status has been updated.',
                      icon: 'success',
                      showConfirmButton: true,
                      timer: 2000,
                      width: '25rem',
                    })
                  })
                }}
                className={`btn btn-secondary btn-sm me-3 cursor-pointer`}
              >
                Reopen
              </button>
            )}
            {(role[0] === 'Contractor Admin' || role[0] === 'Contractor') && (
              <div className='d-flex justify-content-center w-100'>
                <div className='badge badge-light-primary px-5 py-3 fw-bold'>Closed</div>
              </div>
            )}
          </div>
        ) : (
          <div className='text-center pt-15 pb-5 d-flex align-items-center'>
            <h2 className='text-muted fw-bold'>{data?.data?.statusName}</h2>
            <div className='h-3px w-100 bg-success mx-2'></div>
            {data?.data?.statusName !== 'Completed' && (
              <>
                <button
                  type='button'
                  onClick={() => {
                    const nextStatusId = handleNextStatus(data?.data?.statusName)
                    if (nextStatusId) {
                      handleChangeStatus(data?.data?.id, nextStatusId).then(() => {
                        getData()
                        document.getElementById('kt_table_users_refresssh')?.click()
                        Swal.fire({
                          text: 'Task status has been updated.',
                          icon: 'success',
                          showConfirmButton: true,
                          timer: 2000,
                          width: '25rem',
                        })
                      })
                    }
                  }}
                  className={`btn btn-${handleLbaleColor(
                    data?.data?.statusName
                  )} btn-sm me-3 cursor-pointer`}
                >
                  {handleStatusLabel(data?.data?.statusName)}
                </button>
                {role[0] === 'Inspector Admin' && (
                  <button
                    type='button'
                    onClick={() => {
                      const nextStatusId = handleNextStatus(data?.data?.statusName)
                      if (nextStatusId) {
                        handleChangeStatus(data?.data?.id, 5).then(() => {
                          getData()
                          document.getElementById('kt_table_users_refresssh')?.click()
                          Swal.fire({
                            text: 'Task status has been updated.',
                            icon: 'success',
                            showConfirmButton: true,
                            timer: 2000,
                            width: '25rem',
                          })
                        })
                      }
                    }}
                    className={`btn btn-primary btn-sm me-3 cursor-pointer`}
                  >
                    Close
                  </button>
                )}
              </>
            )}
            {data?.data?.statusName === 'Completed' && (
              <>
                {role[0] !== 'Contractor Admin' && role[0] !== 'Contractor' && (
                  <>
                    {rejectR === true ? (
                      <button
                        type='button'
                        onClick={() => {
                          setrejectR(false)
                        }}
                        className={`btn btn-secondary btn-sm me-3 cursor-pointer`}
                      >
                        Cancel
                      </button>
                    ) : (
                      ''
                    )}

                    <button
                      type='button'
                      disabled={rejectR === true && reason.length === 0 ? true : false}
                      onClick={() => {
                        if (rejectR === false) {
                          setrejectR(true)
                        } else {
                          handlepostreject(data?.data?.id).then(() => {
                            getData()
                            setrejectR(false)
                            getComments(data?.data?.id)

                            handleChangeStatus(data?.data?.id, 2).then(() => {
                              getData()
                              document.getElementById('kt_table_users_refresssh')?.click()
                              Swal.fire({
                                text: 'Task status has been updated.',
                                icon: 'success',
                                showConfirmButton: true,
                                timer: 2000,
                                width: '25rem',
                              })
                            })

                            handleChangeStatus(data?.data?.id, 2).then(() => {
                              getData()
                              document.getElementById('kt_table_users_refresssh')?.click()
                              Swal.fire({
                                text: 'Task status has been updated.',
                                icon: 'success',
                                showConfirmButton: true,
                                timer: 2000,
                                width: '25rem',
                              })
                            })
                          })
                        }
                      }}
                      className={`btn btn-danger btn-sm me-3 cursor-pointer`}
                    >
                      Reject
                    </button>
                  </>
                )}
                {role[0] !== 'Contractor Admin' && role[0] !== 'Contractor' ? (
                  <>
                    {rejectR === false ? (
                      <button
                        type='button'
                        onClick={() => {
                          const nextStatusId = handleNextStatus(data?.data?.statusName)
                          if (nextStatusId) {
                            handleChangeStatus(data?.data?.id, 5).then(() => {
                              getData()
                              document.getElementById('kt_table_users_refresssh')?.click()
                              Swal.fire({
                                text: 'Task status has been updated.',
                                icon: 'success',
                                showConfirmButton: true,
                                timer: 2000,
                                width: '25rem',
                              })
                            })
                          }
                        }}
                        className={`btn btn-primary btn-sm me-3 cursor-pointer`}
                      >
                        Close
                      </button>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <button type='button' className={`btn btn-warning btn-sm me-3 cursor-pointer`}>
                    Checking...
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </form>

      {imageId && (
        <TaskImageViewerModal
          imageId={imageId}
          setImageId={setImageId}
          attachments={data?.data?.attachments}
          setImageSrc={setImageSrc}
        />
      )}
      {isEmailOpen && <EmailModal data={data} setIsEmailOpen={setIsEmailOpen} />}
    </>
  )
}

export {TasksDetails}
