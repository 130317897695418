/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import SVG from 'react-inlinesvg'
import {Form} from 'react-bootstrap'
import {handleApiErrors} from '../../global/HandleApiErrors'
const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [isShowPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.username, values.password)
        console.log('data', auth)
        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        window.location.reload()
        setCurrentUser(auth)
      } catch (error: any) {
        handleApiErrors(error?.response?.data)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='ms-15'>
        <a href='#' className=''>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/Frame 13.svg')} className='h-45px' />
        </a>
        <div className=' fs-4 w-90 mt-5'>
          Introducing a new approach to experiencing construction projects in the vast virtual realm
        </div>

        {/*begin::Wrapper*/}

        <h1 className=' mt-7'>Login</h1>
        <div className='w-lg-500px bg-body rounded  mx-auto'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}

            {/* begin::Heading */}

            {/* begin::Login options */}

            {/* end::Login options */}

            {/* begin::Separator */}

            {/* end::Separator */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark mt-10'>Email</label>
              <input
                placeholder='Email'
                {...formik.getFieldProps('username')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.username && formik.errors.username},
                  {
                    'is-valid': formik.touched.username && !formik.errors.username,
                  }
                )}
                type='email'
                name='username'
                autoComplete='off'
              />
              {formik.touched.username && formik.errors.username && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.username}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <span className='text-danger'> *</span>
                <div className='input-group'>
                  <input
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder={'Password'}
                    className={`form-control h-auto py-3 px-6`}
                    {...formik.getFieldProps('password')}
                  />
                  <span
                    className='input-group-text align-self-center cursor-pointer bg-white'
                    onClick={(e) => setShowPassword(!isShowPassword)}
                  >
                    {!isShowPassword ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
           
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex justify-content-between align-items-right mt-5 mb-5'>
              <div className='container ms-20'>
                <div className='row'>
                  <div className='col-sm-8'></div>
                  <div className='col-sm-4 '>
                    {' '}
                    <Link to='/auth/forgot-password' className='link-primary ms-2'>
                      Forgot Password ?
                    </Link>
                  </div>
                </div>
              </div>
              {/* begin::Link */}

              {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-flex justify-content-between align-items-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary w-100 me-1'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              <Link
                to='/auth/registration'
                className='btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1'
              >
                Sign up
              </Link>
            </div>
            {/* end::Action */}
          </form>
        </div>
      </div>
    </>
  )
}
