import {FC} from 'react'

type Props = {
  projectName: any
}
const TagsListHeader: FC<Props> = ({projectName}) => {
  return (
    <div className='card-header border-0 pt-6'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>
          {projectName} - <span className='text-primary'>Open Tasks</span>
        </span>
      </h3>
      {/* <TagsListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <TagsListGrouping /> : <TagListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TagsListHeader}
