import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import { Flatpickr } from '../../../../global/Flatpickr '
import { handleApiErrors } from '../../../../global/HandleApiErrors'
import { useQueryClient } from 'react-query'
import { QUERIES } from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Col, Row } from 'react-bootstrap'
import CustomTree from '../../../../global/MyTree'
import { UsersQueryResponse } from '../core/_models'
import { useParams } from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URLL

const getProjectsLocations = (id: any): Promise<any> => {
    return axios
        .get(`${API_URL}/Projects/Locations/${id}`)
        .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

type Props = {
    setIsDueDateOpen: (value: boolean) => void
    selected: any
    clearSelected: () => void
}
const LocationModal: FC<Props> = ({ selected, setIsDueDateOpen, clearSelected }) => {
    const params = useParams<any>()
    const { id } = params
    const [locations, setLocations] = useState<any>([])
    const [treeInfo, setTreeInfo] = useState<any>([])
    const [plan, setPlan] = useState<string>('')
    const queryClient = useQueryClient()
    const { query } = useQueryResponse()
    const [selectedDate, setSelectedDate] = useState('' as any)
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])


    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        setTreeInfo(info)
    }

    const handleChangeLocation = async (data: any) => {
        if (treeInfo.length === 0) return Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Please select a location.',
            showConfirmButton: true,
            timer: 3000,
            width: '25rem',
        })
        try {
            await axios.put(`${API_URL}/Tasks/BulkChangeLocation`, data)
                .then((res) => {
                    queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
                    clearSelected()
                    setIsDueDateOpen(false)
                    setTreeInfo([])
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Location has been changed successfully.',
                        showConfirmButton: true,
                        timer: 2000,
                        width: '25rem',
                    })
                })
        } catch (error: any) {
            handleApiErrors(error.response.data)
        }
    }

    function convertData(data: any) {
        return data.map((item: any) => {
            const newItem: any = {
                key: item.id,
                title: item.name,
            }

            if (item.locations && item.locations.length > 0) {
                newItem.children = convertData(item.locations)
            }

            return newItem
        })
    }

    // getProjectsLocations(user.projectId).then((res) => {
    //     setLocations(res.locations)
    // })

    useEffect(() => {
        getProjectsLocations(id).then((res) => {
            setLocations(res.locations)
            setPlan(res.plan)
        })
    }, [id])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-550px'>
                    <div className='modal-content'>
                        <div className='modal-body scroll-y mx-5 mx-xl-5 my-3 mh-800px'>
                            <h4 className=' mb-8'>Select Location</h4>
                            <div className='col-xl-12'>
                                <Row>
                                    <Col xl='4' lg='4' md='6'>
                                        <CustomTree data={locations && convertData(locations)} onSelect={onSelect} />
                                    </Col>
                                    <Col xl='8' lg='8' md='6' className='plan-image'>
                                        {plan && <img
                                            alt='Logo'
                                            src={plan}
                                            className='w-100 h-400px object-cover object-center rounded border border-gray-300 border-dashed p-5 bg-white shadow-sm mb-5 mb-xl-0 card-img-top'
                                        />}
                                    </Col>
                                </Row>
                            </div>

                            <div className='d-flex flex-end'>
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-sm btn-light btn-active-light-primary me-3'
                                        data-kt-users-modal-action='cancel'
                                        onClick={() => {
                                            setIsDueDateOpen(false)
                                        }
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        // disabled={loading}
                                        type='button'
                                        className='btn btn-sm btn-success btn-active-light-primary'
                                        data-kt-users-modal-action='cancel'
                                        onClick={() => {
                                            handleChangeLocation({
                                                actionId: parseInt(treeInfo?.node?.key),
                                                Ids: selected
                                            })
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export { LocationModal }
