import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search} from '../../../partials'
import {ModelStateMessage, QueryFetchPost} from '../../../../app/modules/global'

// import {useLayout} from '../../core'
const BASE_URL = process.env.REACT_APP_BASE_URL

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const [imageApi, setimageApi] = useState<any>(null)
  const [pro, setpro] = useState<any>(null)
  const GetProfile = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        setpro(response[1].profile)
        if (response[1].profile.profilePicture) {
          setimageApi(response[1].profile.profilePicture)
        } else {
          setimageApi(null)
        }
      } else {
        ModelStateMessage(response)
      }
    })
  }
  useEffect(() => {
    GetProfile()
  }, [])
  // const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}
{/* 
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div
            className='image-input image-input-outline'
            data-kt-image-input='true'
            style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
          >
            {imageApi === null ? (
              <div
                className='image-input-wrapper w-40px h-40px'
                style={{backgroundImage: 'url(/assets/media/avatars/300-1.jpg)'}}
              />
            ) : (
              <img
                alt='Logo'
                className='image-input-wrapper w-40px h-40px'
                src={toAbsoluteUrl(imageApi)}
              />
            )}
          </div>
        </div>
        <HeaderUserMenu pro={pro}/>
      </div>
      <div className='separator my-2'>
        <button
          id='testtest2'
          onClick={() => {
            QueryFetchPost({
              link: `${BASE_URL}/api/Account/Profile`,
              data: undefined,
              method: 'GET',
              key: 'RegisterEmail',
              signal: undefined,
              isFormData: false,
            }).then((response) => {
              if (response[0] === 200) {
                setpro(response[1].profile)
                console.log(response[1].profile.profilePicture)
                if (response[1].profile.profilePicture) {
                  setimageApi(response[1].profile.profilePicture)
                } else {
                  setimageApi(null)
                }
              } else {
                ModelStateMessage(response)
              }
            })
          }}
          className='btn btn-light'
        ></button>
      </div>
      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
