// @ts-nocheck
import { Column } from 'react-table'
import { TaskSelectionCell } from './TaskSelectionCell'
import { TaskSelectionHeader } from './TaskSelectionHeader'
import { User } from '../../core/_models'
import { TaskActionsCell } from './TaskActionsCell'
import { TaskCustomHeader } from './TaskCustomHeader'
import { TaskInfoCell } from './TaskInfoCell'
import TaskInfoName from './TaskInfoName'
import TaskStatus from './TaskStatus'
import { TaskAssignCell } from './TaskAssignCell'
import { TaskLocationCell } from './TaskLocationCell'
import { Attachmentfun } from './Attachmentfun'
const cuurentUser: any = localStorage.getItem('User')
const role: any = JSON.parse(cuurentUser)?.roles

const usersColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) =><>{role[0] === 'Contractor' || role[0] === 'Contractor Admin'?"":<> <TaskSelectionHeader tableProps={props} /></>}</>,
    id: 'selection',
    Cell: ({ ...props }) => <TaskSelectionCell id={props.data[props.row.index].id}/>,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='' />,
    id: 'attachment',
    Cell: ({ ...props }) => <Attachmentfun otherAttachmentCount={props.data[props.row.index].otherAttachmentCount}/>,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <TaskInfoName user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <TaskCustomHeader tableProps={props} title='Project Name' className='min-w-125px' />,
  //   accessor: 'projectName',
  // },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Due Date' className='min-w-125px' />,
    id: 'dueDate',
    Cell: ({ ...props }) => <TaskInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Location' className='min-w-125px' />,
    id: 'locationPath',
    Cell: ({ ...props }) => <TaskLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Assignee' className='min-w-125px' />,
    id: 'contractorFirstName',
    Cell: ({ ...props }) => <TaskAssignCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'statusName',
    Cell: ({ ...props }) => <TaskStatus user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    accessor: 'address1',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='City' className='min-w-125px' />
    ),
    accessor: 'city',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'state',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='Zip Code' className='min-w-125px' />
    ),
    accessor: 'zipCode',
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title='Country' className='min-w-125px' />
    ),
    accessor: 'country',
  },
  {
    Header: (props) => (
      <>     {JSON.parse(localStorage?.getItem("User") || '{}')?.roles[0]==="Contractor"?"":<TaskCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />} 
      </>
    ),
    id: 'actions',
    Cell: ({ ...props }) => <TaskActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
