import React from 'react'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { Formik } from "formik";
import {useState} from 'react'
import SVG from 'react-inlinesvg'
import { ModelStateMessage, QueryFetchPost } from '../../global';
var url2 = window.location.search;
let param = url2?.replace("?code=", "");
let token = param?.split(",,,")[0];
var email = param?.split(",,,")[1];


interface Model{
  password: string,
  confirmPassword:string
  email: string,
  token: string,
}
const initialValues = {
  email: email,
    password: "",
    token: token,
    confirmPassword: "",
}

const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
    .email('Wrong password format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
    .required('Password is required'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required("Confirm Password is required"),
})

export function Resetpassword() {
  const [isShowPassword, setShowPassword] = useState(false)
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [isconfirm, setConfirm] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  // const [isShowPassword, setShowPassword] = useState(false);
  const handleSubmit = async (values: Model) => {
    setLoading(true)
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Account/ResetPassword`,
        data: values,
        method: "POST",
        key: 'RessetPassword',
        signal: undefined,
        isFormData: false
      }).then((response) => {
        if (response[0] === 200) {
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Password reset successfully',
          })
          setTimeout(() => {
            navigate('/auth/login', { replace: true })
          }, 150);
          // setLoading(false);

          setLoading(false)
        } 
        else{
          ModelStateMessage(response)
          setLoading(false)
        }
      });
         
         
    } catch (error) {
      Swal.fire('Error', 'An error occurred. please try again later.', 'error');
    }
  };


  return (
    <>
     <div className='ms-15'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/Frame 13.svg')} className='h-45px' />
      </div>
      {/* <div className='ms-17 fs-4 w-100 mt-5'>
      Enter your email address and we will send you instructions to reset your password
      </div> */}
      <h1 className='ms-17 mt-5'>Reset Password !</h1>
        {/*begin::Wrapper*/}
        <div className='w-lg-500px bg-body rounded p-10 p-lg-15 mx-auto'>
        <Formik<Model>
    validationSchema={forgotPasswordSchema}
    initialValues={initialValues}
    onSubmit={(values) => {
      handleSubmit(values)
    }}
  >
       {  ({ handleSubmit, values, handleChange,isSubmitting,isValid ,handleBlur, touched, errors}) => {
          return(
            <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={handleSubmit}
          >
      {/* end::Title */}

      {/* begin::Form group */}
      <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <div className='input-group input-group-solid mb-5'>
              <input
              name='password'
              id='password'
              type={isShowPassword ? 'text' : 'password'}
                autoComplete='off'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': touched.password && errors.password,
                  },
                  {
                    'is-valid': touched.password && !errors.password,
                  }
                )}
              />
              <span className='input-group-text align-self-center cursor-pointer' onClick={(e) => setShowPassword(!isShowPassword)}>
                {!isShowPassword ? (
                  <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                ) : (
                  <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                )}
              </span>
               </div>
              {touched.password && errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.password}</span>
                  </div>
                </div>
              )}
           
      {/* end::Form group */}
      <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm Password</label>
            <div className='input-group input-group-solid mb-5'>
              <input
               name='confirmPassword'
               id='confirmPassword'
               type={isconfirm ? 'text' : 'password'}
                autoComplete='off'
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': touched.confirmPassword && errors.confirmPassword,
                  },
                  {
                    'is-valid': touched.confirmPassword && !errors.confirmPassword,
                  }
                )}
              />
               <span className='input-group-text align-self-center cursor-pointer' onClick={(e) => setConfirm(!isconfirm)}>
                {!isconfirm ? (
                  <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                ) : (
                  <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                )}
              </span> 
                </div>
              {touched.confirmPassword && errors.confirmPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.confirmPassword}</span>
                  </div>
                </div>
              )}
         
      {/* begin::Form group */}
      <div className='d-flex justify-content-between align-items-center mt-7'>
                <button
                disabled={isSubmitting || !isValid}
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary w-100 me-1'
         
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                <a href="/auth" className="btn btn-outline btn-outline btn-outline-primary btn-active-light-primary w-100 ms-1">Login</a>
              </div>
      {/* end::Form group */}
    </form>)
      }}
      </Formik>
    </div>
 
    </>
  
  )
}
