// @ts-nocheck
import {Column} from 'react-table'
import {TeamInfoCell} from './TeamInfoCell'
import {TeamTwoStepsCell} from './TeamTwoStepsCell'
import {TeamActionsCell} from './TeamActionsCell'
import {TeamSelectionCell} from './TeamSelectionCell'
import {TeamCustomHeader} from './TeamCustomHeader'
import {TeamSelectionHeader} from './TeamSelectionHeader'
import {User} from '../../core/_models'
function extractDetailsFromUrl(url: string) {
  const searchParams = new URLSearchParams(new URL(url).search)
  return searchParams.get('details') === 'true'
}
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) =>
    <>{ extractDetailsFromUrl(window.location.href)===false? <TeamSelectionHeader tableProps={props} />:""}</> 
   ,
    id: 'selection',
    Cell: ({...props}) => <TeamSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TeamCustomHeader tableProps={props} title='Full Name' className='min-w-125px' />,
    id: 'firstName',
    Cell: ({...props}) => <TeamInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TeamCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => (
      <TeamCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    accessor: 'phone',
  },
  {
    Header: (props) => (
      <TeamCustomHeader tableProps={props} title='Company' className='min-w-125px' />
    ),
    accessor: 'companyName',
  },
  {
    Header: (props) => (
      <TeamCustomHeader tableProps={props} title='role' className='min-w-125px' />
    ),
    accessor: 'role',
  },
  {
    Header: (props) => (
      <TeamCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'isActive',
    Cell: ({...props}) => <TeamTwoStepsCell isActive={props.data[props.row.index].isActive} />,
  },
   {
     Header: (props) => (
       <TeamCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
     ),
     id: 'actions',
     Cell: ({...props}) => <TeamActionsCell id={props.data[props.row.index].id} isActive={props.data[props.row.index].isActive} user={props.data[props.row.index]} />,
   },
]

export {usersColumns}
