import { type } from 'os'
import React, { FC } from 'react'
import Swal from 'sweetalert2'


type Props = {
    data: any
    setIsOpen: (isOpen: boolean) => void
    cancelSubscription: () => Promise<void>
}
const BillingSummaryDetails: FC<Props> = ({ data, cancelSubscription, setIsOpen }) => {

    // create a function take a date and return it like this Dec 09, 2023
    const formatDate = (date: string) => {
        const d = new Date(date)
        const month = d.toLocaleString('default', { month: 'short' })
        const day = d.getDate()
        const year = d.getFullYear()
        return `${month} ${day}, ${year}`
    }
    return (
        <div className="card mb-5 mb-xl-10">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-7">
                        {data && data?.isSubscribed ? <>
                            <h3 className="mb-2">
                                Active until {formatDate(data?.subscription?.endDate)}
                            </h3>
                            <p className="fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15">
                                We will send you a notification upon Subscription expiration
                            </p>
                            <div className="fs-5 mb-2">
                                <span className="text-gray-800 fw-bold me-1">
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }).format(data?.subscription?.plan?.amount)
                                    }
                                </span>
                                <span className="text-gray-600 fw-semibold">
                                    Per {data?.subscription?.plan?.reccuring}
                                </span>
                            </div>
                            <div className="fs-6 text-gray-600 fw-semibold">
                                <span className="badge badge-light-success fs-7">{data?.subscription?.plan?.name}</span>
                            </div>
                        </> : <h4 className="text-muted mb-0">
                            You don't have any active subscription
                        </h4>}
                    </div>
                    <div className="col-lg-5">
                        <div className="d-flex justify-content-end pb-0 px-0">
                            {/* {data && data?.isSubscribed &&    
                             <button
                                className="btn btn-light btn-light-danger me-2"
                                onClick={async () => {
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: `You want to cancel your subscription!`,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#d33',
                                        cancelButtonColor: '#3085d6',
                                        confirmButtonText: `Yes`
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            await cancelSubscription()
                                        }
                                    })
                                }}
                            >
                                Cancel Subscription
                            </button>
                            } */}
                            {data && !data?.isSubscribed && <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setIsOpen(true)
                                }}
                            >
                                Upgrade Plan
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BillingSummaryDetails