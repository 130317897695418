import {Link} from 'react-router-dom'
interface Model {
  firstName: ''
  lastName: ''
  phoneNumber: ''
  email: ''
  companyName: ''
  address1: string
  address2: string
  city: string
  country: string
  profilePicture: null
  role: string
  site: string
  state: string
  zipCode: string
}
interface SettingsProps {
  profile: Model | undefined
  setProfile: React.Dispatch<React.SetStateAction<Model | undefined>>
}
export function Overview(props: SettingsProps) {
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>

          <Link to='/profile/settings' className='btn btn-primary align-self-center'>
            Edit Profile
          </Link>
        </div>

        <div className='card-body p-9'>
          {props.profile?.firstName === null ||
          props.profile?.firstName === '' ||
          props.profile?.lastName === null ||
          props.profile?.lastName === '' ? (
            ''
          ) : (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {props?.profile?.firstName} {props?.profile?.lastName}
                </span>
              </div>
            </div>
          )}
             {props.profile?.email === null || props.profile?.email === '' ? (
            ''
          ) : (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Email</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{props?.profile?.email}</span>
              </div>
            </div>
          )}
          {props.profile?.companyName === null || props.profile?.companyName === '' ? (
            ''
          ) : (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Company name</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{props?.profile?.companyName}</span>
              </div>
            </div>
          )}

          {props.profile?.phoneNumber === null || props.profile?.phoneNumber === '' ? (
            ''
          ) : (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Contact Phone</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{props.profile?.phoneNumber}</span>
              </div>
            </div>
          )}

   

{props.profile?.site === null||props.profile?.site === ""?"": <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

            <div className='col-lg-8'>
              <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {props.profile?.site}
              </Link>
            </div>
          </div>}

         
          {props.profile?.address1 === null || props.profile?.address1 === '' ? (
            ''
          ) : (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Address 1</label>

              <div className='col-lg-8'>
                <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  {props.profile?.address1}
                </Link>
              </div>
            </div>
          )}
          {props.profile?.address2 === null || props.profile?.address2 === '' ? (
            ''
          ) : (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Address 2</label>

              <div className='col-lg-8'>
                <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  {props.profile?.address2}
                </Link>
              </div>
            </div>
          )}
          {props.profile?.city === null||props.profile?.city === ""?"":
          <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>City</label>

          <div className='col-lg-8'>
            <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
              {props.profile?.city}
            </Link>
          </div>
        </div>}
        {props.profile?.state === null||props.profile?.state === ""?"":  <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>State</label>

            <div className='col-lg-8'>
              <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {props.profile?.state}
              </Link>
            </div>
          </div>}
        {props.profile?.zipCode === null||props.profile?.zipCode === ""?"":   <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Zip code</label>

            <div className='col-lg-8'>
              <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {props.profile?.zipCode}
              </Link>
            </div>
          </div>}

       

          {props.profile?.country === null||props.profile?.country === ""?"": <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Country</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{props.profile?.country}</span>
            </div>
          </div>}

        
         
        </div>
      </div>
    </>
  )
}
