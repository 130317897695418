import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { TeamsListLoading } from '../components/loading/TeamsListLoading'
import Swal from 'sweetalert2'
import { initialPassword } from '../core/_models'
import { updateUserPassword } from '../core/_requests'
import { handleApiErrors } from '../../../../global/HandleApiErrors'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import { useState } from 'react'

const editPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('New Password is required'),

    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
        .min(6, 'Minimum 6 symbols')
        .required('Confirm Password is required'),

})

const TeamsEditPasswordModalForm = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfrimPassword] = useState(false)
    const { setItemIdForUpdatePassword, itemIdForUpdatePassword } = useListView()
    const { refetch } = useQueryResponse()

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdatePassword(undefined)
    }


    const formik = useFormik({
        initialValues: initialPassword,
        validationSchema: editPasswordSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                values.id = itemIdForUpdatePassword
                await updateUserPassword(values).then((res) => {
                    Swal.fire({
                        text: 'Team password has been updated.',
                        icon: 'success',
                        showConfirmButton: true,
                        timer: 2000,
                        width: '25rem',
                    })

                    cancel(true)
                })
            } catch (error: any) {
                handleApiErrors(error?.response?.data)
            } finally {
                setSubmitting(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >

                    <div className='row'>
                        <div className='col-lg-12'>
                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>New Password</label>
                                <div className='input-group input-group-solid mb-5'>
                                    <input
                                        placeholder='New Password'
                                        {...formik.getFieldProps('newPassword')}
                                        type={showPassword ? 'text' : 'password'}
                                        name='newPassword'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                                            {
                                                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                                            }
                                        )}
                                        autoComplete='off'
                                        disabled={formik.isSubmitting}
                                    />
                                    <span className='input-group-text align-self-center cursor-pointer' onClick={(e) => setShowPassword(!showPassword)}>
                                        {!showPassword ? (
                                            <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                                        ) : (
                                            <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                                        )}
                                    </span>
                                </div>
                                {formik.touched.newPassword && formik.errors.newPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.newPassword}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            {/* end::Input group */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Confirm Password</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <div className='input-group input-group-solid mb-5'>
                                    <input
                                        placeholder='Confirm Password'
                                        {...formik.getFieldProps('confirmPassword')}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name='confirmPassword'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                                            {
                                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                                            }
                                        )}
                                        autoComplete='off'
                                        disabled={formik.isSubmitting}
                                    />
                                    <span className='input-group-text align-self-center cursor-pointer' onClick={(e) => setShowConfrimPassword(!showConfirmPassword)}>
                                        {!showConfirmPassword ? (
                                            <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                                        ) : (
                                            <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                                        )}
                                    </span>
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.confirmPassword}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            {/* end::Input group */}
                        </div>
                    </div>


                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                        disabled={formik.isSubmitting}
                    >
                        Discard
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>Create</span>
                        {(formik.isSubmitting) && (
                            <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting) && <TeamsListLoading />}
        </>
    )
}

export { TeamsEditPasswordModalForm }
