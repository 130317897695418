import React, { FC, useEffect, useState } from 'react';
import { useTable, Column } from 'react-table';
import axios from 'axios';
import { UsersListLoading } from '../components/loading/UsersListLoading';

const API_URL = process.env.REACT_APP_API_URLL
const TAGS_URL = `${API_URL}`

interface TaskData {
    companyId: number;
    companyName: string;
    openTasks: number;
    closedTasks: number;
    completedTasks: number;
    totalTasks: number;
    overdueTasks: number;
}

type Props = {
    projectId: any
    setCompanyTask:(p:any)=>void
}

const TaskCompanyTable: FC<Props> = ({ projectId ,setCompanyTask}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data from the API
    useEffect(() => {
        if (projectId) {
            axios.get(`${TAGS_URL}/Dashboard/CompanyTask/${projectId}`)
                .then(response => {
                    setCompanyTask(response.data)
                    setData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [projectId]);

    // Define table columns
    const columns: Column<TaskData>[] = React.useMemo(
        () => [
            {
                Header: () => <span>Company Name</span>,
                accessor: 'companyName',
            },
            {
                Header: () => <span className='btn btn-primary btn-sm'>Open</span>,
                accessor: 'openTasks',
            },
            {
                Header: () => <span className='btn btn-success btn-sm'>Completed</span>,
                accessor: 'completedTasks',
            },
            {
                Header: () => <span className='btn btn-light btn-sm'>Closed</span>,
                accessor: 'closedTasks',
            },
            {
                Header: () => <span className='btn btn-transparant btn-sm'>Total</span>,
                accessor: 'totalTasks',
            },
            {
                Header: () => <span className='btn btn-danger btn-sm'>Overdue Tasks</span>,
                accessor: 'overdueTasks',
            },
        ],
        []
    );

    // Create a table instance
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    // Render the table
    return (
        <div className='table-responsive custom-scrollbar p-5'>
            <table {...getTableProps()} className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => (
                                    <td {...cell.getCellProps()} className={`${index === 0 ? '' : 'text-center'}`}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table >
            {loading && <UsersListLoading />}
        </div >
    );
}

export default TaskCompanyTable;
