/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { activateUser, deleteUser, deleteSelectedUsers } from '../../core/_requests'
import Swal from 'sweetalert2'
import { handleApiErrors } from '../../../../../global/HandleApiErrors'

type Props = {
  id: ID
  isActive: boolean
  user: any
}

const TeamActionsCell: FC<Props> = ({ id, isActive, user }) => {
  const { setItemIdForUpdate, setItemIdForUpdatePassword, setActivitiesId } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    console.log(user)
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const openEditPasswordModal = () => {
    setItemIdForUpdatePassword(id)
  }
  const openActivitiesModal = () => {
    setActivitiesId(id)
  }
  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }

  var idd = window.location.href && extractIdFromUrl(window.location.href)
  const handleActivate = async () => {

    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${isActive ? 'deactivate' : 'activate'} this team!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${isActive ? 'Deactivate' : 'Activate'}`
    }).then(async (result) => {
      if (result.isConfirmed) {
        await activateUser(
          {
            id: id,
            isActive: !isActive,
          }
        ).then(() => {
          Swal.fire({
            text: `${isActive ? 'Team deactivated' : 'Team activated'} successfully`,
            icon: 'success',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
          queryClient.invalidateQueries([`${QUERIES.TEAMS_LIST}-${query}`])
        })
      }
    })
  }

  const deleteItem = useMutation(() => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      Swal.fire({
        text: 'Team has been deleted.',
        icon: 'success',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
      queryClient.invalidateQueries([`${QUERIES.TEAMS_LIST}-${query}`])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >

        {/* begin::Menu item */}
        {user.role === "Inspector" ? <>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>
          {/* end::Menu item */}

          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await deleteItem.mutateAsync().then(() => {
                      Swal.fire({
                        text: 'Team has been deleted.',
                        icon: 'success',
                        showConfirmButton: true,
                        timer: 2000,
                        width: '25rem',
                      })
                    })
                  }
                })
              }}
            >
              Delete
            </a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openActivitiesModal}>
              Activities
            </a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditPasswordModal}>
              Change Password
            </a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={handleActivate}>
              {
                isActive ? 'Deactivate' : 'Activate'
              }
            </a>
          </div>
        </> :
          <>
            <div className='menu-item px-3 cursor-pointer' onClick={async () => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      try {
                        await deleteSelectedUsers(idd, [id]);
                        Swal.fire({
                          text: 'Team has been removed from this project.',
                          icon: 'success',
                          showConfirmButton: true,
                          timer: 2000,
                          width: '25rem',
                        });
                        queryClient.invalidateQueries([`${QUERIES.TEAMS_LIST}-${query}`]);
                      } catch (error:any) {
                        // Handle the error here
                        handleApiErrors(error?.response?.data)

                      }
                      
                    }
                  })
                }}>
             
                Remove from project
            
            </div></>}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { TeamActionsCell }
