/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteUser } from '../../core/_requests'
import Swal from 'sweetalert2'
import { handleApiErrors } from '../../../../../global/HandleApiErrors'

type Props = {
  id: ID
}

const TagActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate, setActivitiesId } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const openActivitiesModal = () => {
    setActivitiesId(id)
  }

  const deleteItem = useMutation(() => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.TAGS_LIST}-${query}`])
      Swal.fire({
        text: 'The tag has been deleted!',
        icon: 'success',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openActivitiesModal}>
            Activities
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then(async (result) => {
                if (result.isConfirmed) {
                  try {
                    await deleteItem.mutateAsync();
                    Swal.fire({
                      text: 'Tag has been deleted.',
                      icon: 'success',
                      showConfirmButton: true,
                      timer: 2000,
                      width: '25rem',
                    });
                  } catch (error:any) {
                    // Handle the error here
                    console.log(error?.response)
                    console.error('An error occurred while deleting the tag:', error);
                    // Optionally, show an error message to the user
                    handleApiErrors(error?.response?.data)
                  }
                }
              })
            }}
          >
            Delete
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { TagActionsCell }
