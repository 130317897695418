import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { ReactSelectStyles } from "./ReactSelectStyle";


interface SelectAsyncPaginateProps {
    value?: any;
    onChange?: (option: any) => void;
    apiLink?: string;
    isDisabled?: boolean;
    placeholder?: string;
    width?: string;
    isClearable?: boolean;
    isMulti?: boolean;
}

const SelectAsyncPaginate: React.FC<SelectAsyncPaginateProps> = (props) => {

    const loadOptions: any = async (
        searchQuery: string,
        loadedOptions: any,
        { page }: { page: number }
    ) => {
        const AUTH_LOCAL_STORAGE_KEY = 'User'
        const token = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
        const response = await fetch(
            `${props.apiLink}?search=${searchQuery}&pageNumber=${page}&pageSize=10`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token?.token}`,
                },
            }
        );
        const responseJSON: any = await response.json();

        const optionsList = responseJSON?.data?.map((item: any) => ({
            label: item?.firstName ? item?.firstName + " " + item?.lastName : item?.name,
            value: item?.id,
        }));

        return {
            options: optionsList,
            hasMore: responseJSON?.pagination?.hasNextPage,
            additional: {
                page: page + 1,
            },
        };
    };

    const onChange = (option: any) => {
        if (typeof props.onChange === "function") {
            props.onChange(option);
        }
    };

    return (
        <AsyncPaginate
            loadOptions={loadOptions}
            value={props.value}
            onChange={onChange}
            placeholder={props.placeholder}
            additional={{
                page: 1,
            }}
            isDisabled={props.isDisabled}
            styles={ReactSelectStyles}
            className={props.width}
            isClearable={props.isClearable}
            isMulti={props.isMulti}
        />
    );
};

SelectAsyncPaginate.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default SelectAsyncPaginate;
