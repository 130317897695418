import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'
import Swal from 'sweetalert2'
import { handleApiErrors } from '../../../../../global/HandleApiErrors'

const TagsListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.TAGS_LIST}-${query}`])
      clearSelected()
    },
  })
  function extractDetailsFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('details') === 'true'
  }
  const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)
  return (
    <div className='d-flex justify-content-start align-items-center flex-wrap px-5'>
      {isDetails ? (
        ''
      ) : (
        <>
          <div className='fw-bolder me-5'>
            <span className='me-2'>{selected.length}</span> Selected
          </div>

          <button
            type='button'
            className='btn btn-danger mb-4'
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  try {
                    await deleteSelectedItems.mutateAsync();
                    Swal.fire({
                      text: 'Tags have been deleted.',
                      icon: 'success',
                      showConfirmButton: true,
                      timer: 2000,
                      width: '25rem',
                    });
                  } catch (error:any) {
                    // Handle the error here
                    console.error('An error occurred while deleting the tags:', error);
                    // Optionally, show an error message to the user
                    handleApiErrors(error?.response?.data)
                  }
                }
              })
            }}
          >
            Delete selected
          </button>
        </>
      )}
    </div>
  )
}

export {TagsListGrouping}
