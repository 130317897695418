import React, {FC, useEffect} from 'react'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js'
import {StripeCardElement, StripeCardElementOptions} from '@stripe/stripe-js'

type Props = {
  formik: any
  handleSubmit2: (data:any) => void
}
const CheckoutForm: FC<Props> = ({formik, handleSubmit2}) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      // Handle the case where the card element is missing
      return
    }

    const result = await stripe.createToken(cardElement as StripeCardElement, {
      name: formik.values.cardName,
    })

    if (result.error) {
      console.error(result.error.message)
    } else {
      console.log('token resula',result)
      if(result.token?.id && result.token?.card?.id){
        formik.setFieldValue('cardToken', result.token?.id)
        formik.setFieldValue('cardId', result.token?.card?.id)
        handleSubmit2({
          cardToken: result.token?.id,
          cardId: result.token?.card?.id,
        })
      }

    }
  }

  const cardElementOptions: StripeCardElementOptions = {
    hidePostalCode: true,
  }
  return (
    <form onSubmit={handleSubmit}>
      <div
        className='custom-card-element-container'
        style={{
          display: 'block',
          width: '100%',
          padding: '0.975rem 1rem',
          fontSize: '1.1rem',
          fontWeight: 500,
          lineHeight: 1.5,
          color: '#5e6278',
          backgroundColor: '#f9f9f9',
          backgroundClip: 'padding-box',
          border: '#e1e3ea',
          borderRadius: '0.475rem',
          boxShadow: 'none',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
          outline: 'none',
        }}
      >
        <CardElement options={cardElementOptions} className='custom-card-element' />
      </div>
      <button 
        id='pay_now2' 
        hidden={true} 
        className='btn btn-sm btn-primary mt-5' 
        type='button'
        onClick={handleSubmit}
        >
        Pay Now
      </button>
    </form>
  )
}

export default CheckoutForm
