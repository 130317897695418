import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { ReactSelectStyles } from '../../../../app/modules/global/ReactSelectStyle'
import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Modal } from 'react-bootstrap'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import SVG from 'react-inlinesvg'
import axios from 'axios'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
import { handleApiErrors } from '../../../../app/modules/global/HandleApiErrors'
import { TimeConversion } from '../../../../app/modules/global'
import { TasksDetails } from './TaskDetails'

const BASE_URL = process.env.REACT_APP_BASE_URL

export type OptionType = {
  value: any
  label: any
}
const options: OptionType[] = [
  { value: 'tasks', label: 'Tasks' },
  { value: 'projects', label: 'Projects' },
  { value: 'global', label: 'global' },
]

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
}

const SearchPage = () => {
  const [timezone, settimezone] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [key, setkey] = useState<any>()
  const [data, setdata] = useState<any>()
  const [elapsedS, setelapsedS] = useState<any>()
  const [numbers, setnumbers] = useState<any>()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [edittitleshow, setedittitleshow] = useState(false)
  const [idtask, setidtask] = useState()

  function handleChangePage(event: any, newPage: any) {
    setPage(newPage)
  }

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }))

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onChangePage } = props

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {

      onChangePage(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label='First Page'
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label='Previous Page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='Next Page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='Last Page'
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }

  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: '100%',

      marginTop: theme.spacing(3),
    },

    table: {
      minWidth: 500,
    },

    tableWrapper: {
      overflowX: 'auto',
    },
  }))
  const classes = useStyles()
  function markUpSearchLabel(value: string, key: string, item: any): JSX.Element | string {
    if (value?.toLowerCase().includes(key?.toLowerCase())) {
      return <mark>{value}</mark>
    } else {
      if (item.key === "Creation Date") {
        return TimeConversion(value, localStorage?.getItem("TimeZone")) || '';
      } else {
        return <mark>{value}</mark>
      }
    }
  }

  function extractEntityValueFromURL(url: string): string | null {
    const parsedUrl = new URL(url)
    const entityParam = parsedUrl.searchParams.get('entity')
    return entityParam
  }
  function extractKeyValueFromURL(url: string): string | null {
    const parsedUrl = new URL(url)
    const entityParam = parsedUrl.searchParams.get('key')
    return entityParam
  }
  const entityValue = extractEntityValueFromURL(window.location.href)
  const KeyValue = extractKeyValueFromURL(window.location.href)
  async function getsearsh(entity: any, keey: any) {
    setRowsPerPage(10)
    setPage(0)
    setLoading(true)
    try {
      const response = await axios.get(
        `${BASE_URL}/api/GlobalSearch?query=${keey}&entity=${entity}`
      )
      if (response.status === 200) {
        setLoading(false)
        setdata(response.data.results)
        setelapsedS(response.data.elapsedS)
        setnumbers(response.data.number)
      }
    } catch (error: any) {
      handleApiErrors(error?.response?.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    settimezone(entityValue)
    setkey(KeyValue)
    getsearsh(entityValue, KeyValue)
  }, [window.location.href])
  return (
    <>
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='col-lg-12 py-7'>
            <div className='row'>
              <div className='col'>
                <label>Entity</label>
                <div className=' css-2b097c-container'>
                  <Select
                    options={options}
                    value={options.find((obj) => obj.value === timezone)}
                    onChange={(e: any) => {
                      settimezone(e?.value)
                    }}
                    isClearable={true}
                    styles={ReactSelectStyles}
                  />
                </div>
              </div>
              <div className='col'>
                <label>Search</label>
                <input
                  type='text'
                  className='form-control'
                  name='searchText'
                  value={key}
                  placeholder='Search Anything...'
                  onChange={(e) => {
                    setkey(e.target.value)
                  }}
                // defaultValue="re"
                />
              </div>
              <div className='col-xl-2 col-lg-2 col-md-12 text-right pt-7 align-self-center'>
                <button
                  id="search22"
                  onClick={() => getsearsh(timezone, key)}
                  className='btn btn-primary'
                  style={{ float: 'right', textDecorationLine: 'none' }}
                  disabled={key?.length < 3 ? true : false}
                >
                  Search{' '}
                  {loading && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  )}
                </button>
              </div>
              <div className='col-xl-12 col-lg-12 col-md-12 mt-4'>
                <div id='result-stats'>
                  <h5>
                    {' '}
                    Showing {numbers} results for: {elapsedS}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row: any) => {
                    return (
                      <TableRow key={row.title}>
                        <TableCell align='left' width='80px'>
                          <div>
                            <div className='symbol-label'>
                              {row.entity === 'Task' ? (
                                <div className='symbol symbol-40px me-4'>
                                  <span className='symbol-label bg-light '>
                                    <SVG
                                      className='svg-icon svg-pramry'
                                      src={toAbsoluteUrl('/media/svg/icons/Files/File-done.svg')}
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div className='symbol symbol-40px me-4'>
                                  <span className='symbol-label bg-light'>
                                    <KTIcon
                                      iconName='chart-simple-3'
                                      className='fs-2 text-primary'
                                    />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align='left' width='200px'>
                          <Typography>
                            {row.entity === 'Task' ? <span className='text-primary cursor-pointer' onClick={() => {
                              if (row.entity === 'Task') {
                                console.log(row.id, row)
                                setidtask(row.id)
                                setedittitleshow(true)
                              }
                            }} >
                              {row.title}
                            </span> : <Link className='' onClick={() => {
                              if (row.entity === 'Task') {
                                console.log(row.id, row)
                                setidtask(row.id)
                                setedittitleshow(true)
                              }
                            }} to={`${row.link}`}>
                              {row.title}
                            </Link>}
                           
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <div className='col-lg-12 row'>
                            {row?.metadata?.map((item: any) => (
                              <div className='col-lg-3'>
                                <Typography component='p'>
                                  <strong>{item?.key} </strong>:{' '}
                                  {markUpSearchLabel(item?.value, key, item)}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={data?.length || 0} // Assuming 'data' is an array
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                      native: true,
                    }}
                    onPageChange={(
                      event: React.MouseEvent<HTMLButtonElement> | null,
                      newPage: number
                    ) => {
                      handleChangePage(event, newPage)
                    }}
                    onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setRowsPerPage(parseInt(e.target.value, 10))
                      handleChangePage(null, 0) // Reset to first page when rows per page changes
                    }}
                    ActionsComponent={(props: TablePaginationActionsProps) => {
                      return (
                        <TablePaginationActions
                          page={page}
                          key={'tableSearch'}
                          count={data?.length || 0}
                          onChangePage={(event, pageCount) => {
                            event?.preventDefault()
                            setPage(pageCount)
                          }}
                          rowsPerPage={rowsPerPage}
                        />
                      )
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
        <Modal
          show={edittitleshow}
          onHide={() => {
            setedittitleshow(false)
          }}
          aria-labelledby='example-modal-sizes-title-lg'
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-lg'>Task details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TasksDetails idd={idtask} />
          </Modal.Body>
          <Modal.Footer>
            <div className='text-centre'>
              <button
                type='reset'
                onClick={() => {
                  setedittitleshow(false)
                }}
                className='btn btn-light'
                data-kt-users-modal-action='cancel'
              >
                Discard
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default SearchPage
