/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useQueryRequest } from '../../../../app/pages/dashboard/core/QueryRequestProvider'

const API_URL = process.env.REACT_APP_API_URLL
const TAGS_URL = `${API_URL}`
type Props = {
    className: string
}

const StackedContracotr: React.FC<Props> = ({ className }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const [projectsNames, setProjectsNames] = React.useState<any[]>([])
    const [open, setOpen] = React.useState<any[]>([])
    const [complete, setComplete] = React.useState<any[]>([])
    const [closed, setClosed] = React.useState<any[]>([])
    const { mode } = useThemeMode()
    const { updateState } = useQueryRequest()
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height))
        if (chart) {
            chart.render()
        }

        return chart
    }

    const { data: projects } = useQuery(
        `ContractorOverView`,
        async () => {
            return await axios.get(`${TAGS_URL}/Dashboard/ContractorOverView`)
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        if (projects) {
            const projectsNames = projects.data.data.subcontractor.map((project: any) => project.firstName + ' ' + project.lastName)
            setProjectsNames(projectsNames)
            const open = projects.data.data.subcontractor.map((project: any) => project.openTasks)
            setOpen(open)
            const complete = projects.data.data.subcontractor.map((project: any) => project.completedTasks)
            setComplete(complete)
            const closed = projects.data.data.subcontractor.map((project: any) => project.closedTasks)
            setClosed(closed)
        }
    }, [projects])


    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, closed, complete, open, projectsNames, mode])

    return (
        <div className={`card ${className}`} id="testSubcontractorTable">
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Subcontractors Overview</span>
                    <div className='col-3'>
                        <button
                            hidden={true}
                            id='kt_table_users_refressshTasks'
                            onClick={() => {
                                updateState({ filter: { projectId: (window as any).projectId } })
                            }}
                        >
                            <i className='bi bi-download'></i>
                        </button>
                    </div>
                </h3>
            </div>
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_5_chart' className='chart-container' style={{ height: '350px' }}></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )

    function getChartOptions(height: number): ApexOptions {
        // const labelColor = getCSSVariableValue('--bs-gray-500')
        // const borderColor = getCSSVariableValue('--bs-gray-200')

        // const baseColor = getCSSVariableValue('--bs-primary')
        // const secondaryColor = getCSSVariableValue('--bs-info')

        return {
            series: [
                {
                    name: 'Open',
                    data: open && open,
                },
                {
                    name: 'Completed',
                    data: complete && complete,
                },
                {
                    name: 'Closed',
                    data: closed && closed,
                },
            ],
            chart: {
                type: 'bar',
                height: 300,
                stacked: true,
                stackType: '100%',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    borderRadius: 5,
                    columnWidth: '70%',
                    barHeight: '40%',
                    distributed: false,
                    rangeBarOverlap: true,
                    rangeBarGroupRows: false,
                    colors: {
                        ranges: [
                            {
                                from: 0,
                                to: 0,
                                color: undefined,
                            },
                        ],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                },
            },
            stroke: {
                width: 5,
                colors: ['#fff'],
            },
            xaxis: {
                categories: projectsNames && projectsNames,
                labels: { show: false },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + ''
                    },
                },
            },
            colors: ['#1570EF', '#1BC5BD', '#B6B6B6'],
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40,
                formatter: function (label, opts) {
                    // return label + " - " + opts.w.globals.series[opts.seriesIndex]
                    return label
                },
            },
            dataLabels: {
                enabled: false,
            },
        }
    }
}

export { StackedContracotr }

