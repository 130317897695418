import { FC, useState, useEffect } from 'react'
import SelectAsyncPaginate from '../../../../global/SelectAsyncPaginate'
import Select from 'react-select'
import { ReactSelectStyles } from '../../../../global/ReactSelectStyle'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import axios from 'axios'
import Card from './Card'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver'
import { useParams } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URLL

type Props = {
  isUserLoading: boolean
  user: any
}

const options = [
  { value: 'company', label: 'Sort by company' },
  { value: 'location', label: 'Sort by location' },
  { value: '', label: 'Sort by task number' },
]
const ReportModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [selectedTemplate, setSelectedTempalte] = useState<string>('')
  const [groupBy, setGroupBy] = useState<string>('')
  const [openReport, setOpenReport] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)
  const [Withpic, setWithpic] = useState<any>(false)
  const [send, setsend] = useState<any>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const chunkSize = 100000

  const params = useParams<any>()
  const { id } = params
  const [showSearchComponent, setShowSearchComponent] = useState(false)

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Due date', key: 'dueDate' },
    { label: 'Location', key: 'locationPath' },
    { label: 'Assignee', key: 'contractor' },
    { label: 'Status', key: 'status' },

    // Additional headers...
  ]
  const handleGetReport = async () => {
    if (!selectedProject) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select a project',
      })
    }
    if (!selectedTemplate) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select a template',
      })
    }
    try {
      setLoading(true)
      console.log(groupBy)
      const res = await axios.get(
        `${API_URL}/Tasks/export/${selectedProject.value}?groupBy=${selectedTemplate === 'spreedsheet'?"":groupBy}`
      )
      setData(res.data.data)
      if (res?.data.data && selectedTemplate === 'small' || res?.data.data && selectedTemplate === 'NOsmall') {
        if (selectedTemplate === 'small') {
          setWithpic(true)
        } else {
          setWithpic(false)
        }

        setOpenReport(true)
      }
      if (res?.data.data && selectedTemplate === 'spreedsheet') {
        if (groupBy === 'location') {
          // Flatten the tasks array from all objects and include the location header
          const allTasks = res.data.data?.reduce((accumulator: any, currentObject: any) => {
            return [
              ...accumulator,
              ...currentObject.tasks.map((task: any) => ({
                ...task,
                location: currentObject.location,
              })),
            ]
          }, [])

          // Create a new workbook
          const workbook = XLSX.utils.book_new()
          const worksheetName = 'All Tasks'
          const worksheetData = allTasks?.map((task: any) => ({
            Id: task.id,
            Name: task.name,
            Tags: task.tags,
            'Contracting company': task.contractingCompany,
            Location: task.location, // Include the location header
          }))
          const worksheet = XLSX.utils.json_to_sheet(worksheetData)

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)

          // Generate the Excel file
          const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })

          // Save the Excel file
          const currentDate = new Date().toISOString().slice(0, 10)
          const fileName = `${(window as any).projectName}_${currentDate}.xlsx`
          const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
          saveAs(file, fileName)
        } else if (groupBy === 'company') {
          const allTasks = res.data.data?.reduce((accumulator: any, currentObject: any) => {
            const tasksWithCompany = currentObject.tasks?.map((task: any) => ({
              ...task,
              contractingCompany: currentObject.contractingCompany,
            }))
            return [...accumulator, ...tasksWithCompany]
          }, [])

          // Define the headers you want to include
          const headers = [
            { label: 'Id', key: 'id' },
            { label: 'Name', key: 'name' },
            { label: 'Due date', key: 'dueDate' },
            { label: 'Location', key: 'locationPath' },
            { label: 'Assignee', key: 'contractorFirstName' },
            { label: 'Status', key: 'status' },
            { label: 'Contracting Company', key: 'contractingCompany' }, // Add the contractingCompany header
            // additional headers...
          ]

          // Create a new workbook
          const workbook = XLSX.utils.book_new()
          const worksheetName = 'All Tasks'
          const worksheetData = [
            headers?.map((header) => header.label), // Add header labels as the first row
            ...allTasks?.map((task: any) =>
              headers?.map((header) => {
                if (header.key === 'locationPath') {
                  // Replace the 'locationPath' key with 'Location' header
                  return task.locationPath
                }
                return task[header.key]
              })
            ),
          ]
          const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)

          // Generate the Excel file
          const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })

          // Save the Excel file
          const currentDate = new Date().toISOString().slice(0, 10)
          const fileName = `${(window as any).projectName}_${currentDate}.xlsx`
          const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
          saveAs(file, fileName)
        } else {
          const workbook = XLSX.utils.book_new()

          // Create a new worksheet
          const worksheetName = 'Tasks'
          const worksheetData = res.data.data?.map((task: any) => ({
            Id: task.id,
            Name: task.name,
            Description: task.description,
            'Due Date': task.dueDate,
            Location: task.locationPath,
            Assignee: `${task.contractorFirstName === null ? '' : task.contractorFirstName} ${task.contractorLastName === null ? '' : task.contractorLastName
              }`,
            Status: task.status,
          }))

          const worksheet = XLSX.utils.json_to_sheet(worksheetData)

          // Set the column widths
          const columnWidths = [{ wpx: 80 }, { wpx: 200 }, { wpx: 400 }]
          worksheet['!cols'] = columnWidths

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)

          // Generate the Excel file
          const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })

          // Save the Excel file
          const currentDate = new Date().toISOString().slice(0, 10)
          const fileName = `${(window as any).projectName}_${currentDate}.xlsx`
          const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
          saveAs(file, fileName)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const exportChunk = async (chunk: any) => {
    const worksheet = XLSX.utils.json_to_sheet(chunk)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks')
    XLSX.writeFile(workbook, 'tasks.xlsx')
  }

  const exportTasks = async () => {
    console.log(data)
    if (groupBy === 'location') {
      // Flatten the tasks array from all objects and include the location header
      const allTasks = data.reduce((accumulator: any, currentObject: any) => {
        return [
          ...accumulator,
          ...currentObject.tasks.map((task: any) => ({
            ...task,
            location: currentObject.location,
          })),
        ]
      }, [])

      // Create a new workbook
      const workbook = XLSX.utils.book_new()
      const worksheetName = 'All Tasks'
      const worksheetData = allTasks.map((task: any) => ({
        Id: task.id,
        Name: task.name,
        Tags: task.tags,
        'Contracting company': task.contractingCompany,
        Location: task.location, // Include the location header
      }))
      const worksheet = XLSX.utils.json_to_sheet(worksheetData)

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)

      // Generate the Excel file
      const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })

      // Save the Excel file
      const currentDate = new Date().toISOString().slice(0, 10)
      const fileName = `${(window as any).projectName}_${currentDate}.xlsx`
      const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(file, fileName)
    } else if (groupBy === 'company') {
      const allTasks = data.reduce((accumulator: any, currentObject: any) => {
        const tasksWithCompany = currentObject.tasks.map((task: any) => ({
          ...task,
          contractingCompany: currentObject.contractingCompany,
        }))
        return [...accumulator, ...tasksWithCompany]
      }, [])

      // Define the headers you want to include
      const headers = [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Due date', key: 'dueDate' },
        { label: 'Location', key: 'locationPath' },
        { label: 'Assignee', key: 'contractorFirstName' },
        { label: 'Status', key: 'status' },
        { label: 'Contracting Company', key: 'contractingCompany' }, // Add the contractingCompany header
        // additional headers...
      ]

      // Create a new workbook
      const workbook = XLSX.utils.book_new()
      const worksheetName = 'All Tasks'
      const worksheetData = [
        headers.map((header) => header.label), // Add header labels as the first row
        ...allTasks.map((task: any) =>
          headers.map((header) => {
            if (header.key === 'locationPath') {
              // Replace the 'locationPath' key with 'Location' header
              return task.locationPath
            }
            return task[header.key]
          })
        ),
      ]
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)

      // Generate the Excel file
      const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })

      // Save the Excel file
      const currentDate = new Date().toISOString().slice(0, 10)
      const fileName = `${(window as any).projectName}_${currentDate}.xlsx`
      const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(file, fileName)
    } else {
      const workbook = XLSX.utils.book_new()

      // Create a new worksheet
      const worksheetName = 'Tasks'
      const worksheetData = data?.map((task: any) => ({
        Id: task.id,
        Name: task.name,
        Description: task.description,
        'Due Date': task.dueDate,
        Location: task.locationPath,
        Assignee: `${task.contractorFirstName === null ? '' : task.contractorFirstName} ${task.contractorLastName === null ? '' : task.contractorLastName
          }`,
        Status: task.status,
      }))

      const worksheet = XLSX.utils.json_to_sheet(worksheetData)

      // Set the column widths
      const columnWidths = [{ wpx: 80 }, { wpx: 200 }, { wpx: 400 }]
      worksheet['!cols'] = columnWidths

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)

      // Generate the Excel file
      const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })

      // Save the Excel file
      const currentDate = new Date().toISOString().slice(0, 10)
      const fileName = `${(window as any).projectName}_${currentDate}.xlsx`
      const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(file, fileName)
    }
  }

  useEffect(() => {
    if (id) {
      setSelectedProject({ value: id, label: (window as any).projectName })
    }
  }, [id])

  return (
    <>
      <form id='kt_modal_add_user_form' className='form'>
        {/* <div className='row'>
          <div className='col-12 mb-5'>
            <h1 className='text-dark fs-6 mb-3 fw-bold'>Project:</h1>
            <SelectAsyncPaginate
              apiLink={`${API_URL}/Tasks/Projects`}
              value={selectedProject}
              isDisabled={id ? true : false}
              onChange={async (e: any) => {
                setSelectedProject(e)
              }}
            />
          </div>
        </div> */}
        <div className='row'>
          <div className='col-12 mb-5'>
            <h1 className='text-dark fs-6 mb-3 fw-bold'>Report Type:</h1>
            <span className='text-muted fs-6'>
              Select wich report you want to generate for your task list (any filters applied to the
              list will be applied in the report)
            </span>
          </div>
        </div>
        <div className='row g-10'>
          <div className='col-xl-3'>
            <div
              className={`d-flex h-100 align-items-center ${selectedTemplate === 'small' && 'border border-primary'
                }`}
            >
              <div
                onClick={() => setSelectedTempalte('small')}
                className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-5 px-10'
              >
                <div className='mb-7 text-center'>
                  <h1 className='text-dark mb-5 fw-bolder'>Report with pictures</h1>
                  <div className='text-center'>
                    <img
                      src={toAbsoluteUrl('/media/Plan/template.jpg')}
                      alt=''
                      className='h-120px'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3'>
            <div
              className={`d-flex h-100 align-items-center ${selectedTemplate === 'NOsmall' && 'border border-primary'
                }`}
            >
              <div
                onClick={() => { setSelectedTempalte('NOsmall') }}
                className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-5 px-10'
              >
                <div className='mb-7 text-center'>
                  <h1 className='text-dark mb-5 fw-bolder'>Report without pictures</h1>
                  <div className='text-center'>
                    <img
                      src={toAbsoluteUrl('/media/Plan/template.jpg')}
                      alt=''
                      className='h-120px'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3'>
            <div
              className={`d-flex h-100 align-items-center ${selectedTemplate === 'spreedsheet' && 'border border-primary'
                }`}
            >
              <div
                onClick={() => {
                  setGroupBy("")
                  setSelectedTempalte('spreedsheet')}}
                className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-5 px-10'
              >
                <div className='mb-7 text-center'>
                  <h1 className='text-dark mb-5 fw-bolder'>SPREADSHEET (CSV)</h1>
                  <div className='text-center'>
                    <img src={toAbsoluteUrl('/media/Plan/csv.png')} alt='' className='h-120px' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-stack flex-wrap pt-5'>
          <div className='my-1 me-5 col-4'>
            {selectedTemplate === 'spreedsheet' ? "" :
              <Select
                value={
                  options.filter(function (option) {
                    return option.value === groupBy
                  })[0]
                }
                options={options}
                styles={ReactSelectStyles}
                onChange={async (e: any) => {
                  setGroupBy(e.value)
                }}
              />}

          </div>
          <div>
            <button
              disabled={loading || !!selectedTemplate === false}
              onClick={() => {
                setData(null)
                handleGetReport()
              }}
              type='button'
              className='btn btn-success me-3'
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              {loading ? 'Downloading...' : 'Download'}
            </button>
          </div>

        </div>

        {openReport && (
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-1000px'>
              <div className='modal-content border border-dark'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Print Reports</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setOpenReport(false)}
                    style={{ cursor: 'pointer' }}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y my-7'>
                  <Card data={data} selectedProject={selectedProject.value} groupBy={groupBy} Withpic={Withpic} />
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  )
}

export { ReportModalForm }
