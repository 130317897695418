import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Flatpickr } from '../../../../global/Flatpickr '
import { handleApiErrors } from '../../../../global/HandleApiErrors'
import { useQueryClient } from 'react-query'
import { QUERIES } from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../core/QueryResponseProvider'

const API_URL = process.env.REACT_APP_API_URLL

type Props = {
    setIsDueDateOpen: (value: boolean) => void
    selected: any
    clearSelected: () => void
}
const DueDateModal: FC<Props> = ({ selected, setIsDueDateOpen, clearSelected }) => {
    const queryClient = useQueryClient()
    const { query } = useQueryResponse()
    const [selectedDate, setSelectedDate] = useState('' as any)
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const handleDueDate = async (data: any) => {
        if (!selectedDate) return Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Please select a due date.',
            showConfirmButton: true,
            timer: 3000,
            width: '25rem',
        })
        try {
            await axios.put(`${API_URL}/Tasks/BulkDueDate`, data)
                .then((res) => {
                    queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
                    clearSelected()
                    setIsDueDateOpen(false)
                    setSelectedDate(null)
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Due Date has been changed successfully.',
                        showConfirmButton: true,
                        timer: 2000,
                        width: '25rem',
                    })
                })
        } catch (error: any) {
            handleApiErrors(error.response.data)
        }
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-550px'>
                    <div className='modal-content'>
                        <div className='modal-body scroll-y mx-5 mx-xl-5 my-3 mh-800px'>

                            <div className='d-flex flex-column'>
                                <label className='fw-bold fs-6 mb-5 required'>
                                    Select Due Date
                                </label>
                                <Flatpickr
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                />
                            </div>

                            <div className='d-flex flex-end'>
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-sm btn-light btn-active-light-primary me-3'
                                        data-kt-users-modal-action='cancel'
                                        onClick={() => {
                                            setIsDueDateOpen(false)
                                        }
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        // disabled={loading}
                                        type='button'
                                        className='btn btn-sm btn-success btn-active-light-primary'
                                        data-kt-users-modal-action='cancel'
                                        onClick={() => {
                                            handleDueDate({
                                                dueDate: selectedDate,
                                                Ids: selected
                                            })
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export { DueDateModal }
