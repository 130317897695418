/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {
  initialQueryState,
  KTIcon,
  toAbsoluteUrl,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQuery} from 'react-query'
import {getLocationPlan, getTags, getTaskViewModel} from '../../core/_requests'
import {ReactSelectStyles} from '../../../../../global/ReactSelectStyle'
import Select from 'react-select'
import SelectAsyncPaginate from '../../../../../global/SelectAsyncPaginate'
import axios, {AxiosResponse} from 'axios'
import {UsersQueryResponse} from '../../core/_models'
import {Col, Row} from 'react-bootstrap'
import CustomTree from '../../../../../global/MyTree'
import {FlatpickrRange} from '../../../../../global/FlatpickrRange'
import {OptionType} from '../../../../../global/ReactSelect'
import SelectAsyncPaginate2 from '../../../../../global/SelectAsyncPaginate2'
import MyTreeMulti from '../../../../../global/MyTreeMulti'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import moment from 'moment'

const API_URL = process.env.REACT_APP_API_URLL

const DateTypeOptions: OptionType[] = [
  {value: 'completeddate', label: 'Completed date'},
  {value: 'opendate', label: 'Open date'},
  {value: 'closeddate', label: 'Closed date'},
  {value: 'duedate', label: 'Due date'},
  {value: 'created', label: 'Created'},
]

const TeamsListSearchComponent = ({ showSearchComponent }: { showSearchComponent: boolean }) => {
  const params = useParams<any>()
  const {id} = params
  const [StatusId, setStatusId] = useState<string | null | undefined>()
  const [locations, setLocations] = useState<any>([])
  const [Location, setLocation] = useState<any>([])
  const [locationName, setLocationName] = useState<any>('')
  const [treeInfo, setTreeInfo] = useState<any>([])
  const [plan, setPlan] = useState<string>('')
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<any>('')
  const [date, setdate] = useState<string | null | undefined>()
  const [DateType, setDateType] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const [TagsIncludes, SetTagsIncludes] = useState<any>([])
  const [TagsExcludes, SetTagsExcludes] = useState<any>([])
  const [AssignedTo, setAssignedTo] = useState<any>([])
  const [CreatedBy, setCreatedBy] = useState<any>([])
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])
  const [selectedSubContractorInTeam, setSelectedSubContractorInTeam] = useState<any>(null)
  const [selectedCreatedBy, setSelectedCreatedBy] = useState<any>(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const [tagss, settags] = useState<any>([])
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ search: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm]
  )

  function call() {
    console.log(selectedProject?.value)
    if (selectedProject?.value) {
      axios.get(`${API_URL}/Tasks/Tags?ProjectId=${selectedProject.value}`)
        .then((response) => {
          const tags = response.data.data.map((tag: any) => ({ value: tag.id, label: tag.name }));
          settags(tags);
          console.log(tags)
        })
    }
  }
  useEffect(() => {
 call()
  }, [selectedProject])

  const { data: status } = useQuery('taskViewModel', getTaskViewModel, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  useEffect(() => {
    if (selectedSubContractorInTeam) {
      setAssignedTo(selectedSubContractorInTeam?.map((item: any) => item.value))
    }
  }, [selectedSubContractorInTeam])
  useEffect(() => {
    if (selectedCreatedBy) {
      setCreatedBy(selectedCreatedBy?.map((item: any) => item.value))
    }
  }, [selectedCreatedBy])

  const statusOptions = status?.data?.status?.map((status: any) => ({
    value: status.id,
    label: status.name,
  }))


  const filterData = () => {
    updateState({
      filter: {
        StatusId,
        ProjectId: selectedProject?.value,
        LocationId: treeInfo?.node?.key,
        startDate,
        endDate,
        date,
        AssignedTo,
        TagsIncludes,
        TagsExcludes,
        Location,
        DateType,
        CreatedBy,
      },
      ...initialQueryState,
    })
  }

  useEffect(() => {
    filterData()
  }, [
    StatusId,
    selectedProject,
    CreatedBy,
    DateType,
    treeInfo,
    startDate,
    endDate,
    AssignedTo,
    TagsIncludes,
    TagsExcludes,
    Location,
  ])

  useEffect(() => {
    updateState({
      filter: {...initialQueryState, projectId: id},
    })

    getProjectsLocations(id).then((res) => {
      setLocations(res.locations)
    })

    if (id) {
      setSelectedProject({value: id, label: (window as any).projectName})
    } else {
      setSelectedProject(null)
    }
  }, [id])

  const getProjectsLocations = (id: any): Promise<any> => {
    return axios
      .get(`${API_URL}/Projects/Locations/${id}`)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    if (treeInfo.node) {
      // setLocationName(treeInfo.node.title)
      getLocationPlan(treeInfo.node.key).then((res: any) => {
        setPlan(res?.data?.location?.buildingPlan)
      })
    }
  }, [treeInfo])

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setTreeInfo(info)
    console.log('selected', info)
    const selectedLocation: any = []
    info?.selectedNodes?.map((item: any) => {
      selectedLocation.push(item.key)
    })
    setLocation(selectedLocation)
    const selectedLocationName: any = []
    info?.selectedNodes?.map((item: any) => {
      selectedLocationName.push(item.title)
    })

    // loop through the selected Locations and return a sting of the names of the locations separated by a >
    const locationName = selectedLocationName
      ?.map((item: any) => {
        return item
      })
      .join(' > ')
    setLocationName(locationName)
  }

  function convertData(data: any) {
    return data.map((item: any) => {
      const newItem: any = {
        key: item.id,
        title: item.name,
      }

      if (item.locations && item.locations.length > 0) {
        newItem.children = convertData(item.locations)
      }

      return newItem
    })
  }
  return showSearchComponent ? (
    <>
      <div className='d-flex flex-wrap mx-17'>
        {/* begin::Search */}
        <div className='mb-3 me-3'>
          <label className='form-label'>Search:</label>
          <div>
            <div className='d-flex align-items-center position-relative my-1 me-2'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Status:</label>
            <Select
              className='w-250px'
              isClearable={true}
              options={statusOptions}
              placeholder='Status'
              value={
                StatusId ? statusOptions?.find((status: any) => status.value === StatusId) : null
              }
              onChange={(e: any) => {
                setStatusId(e?.value)
              }}
              styles={ReactSelectStyles}
            />
          </div>
        </div>
        {!id && (
          <div className='mb-3 me-3'>
            <div className='my-1 me-2'>
              <label className='form-label'>Project:</label>
              <SelectAsyncPaginate
                apiLink={`${API_URL}/Tasks/Projects`}
                value={selectedProject}
                isDisabled={false}
                isClearable={true}
                placeholder='Project'
                width='w-250px'
                onChange={async (e: any) => {
                  setPlan("")
                  setSelectedProject(e)
                  await getProjectsLocations(e.value).then((res) => {
                    setLocations(res.locations)
                  })
                }}
              />
            </div>
          </div>
        )}
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Location:</label>
            <div
              className='input-group input-group-solid cursor-pointer w-250px'
              onClick={() => {
                if (!selectedProject) {
                  Swal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Please select a project first!',
                  })
                } else {
                  setIsModalOpen(true)
                }
              }}
            >
              <input
                type='text'
                name='locationId'
                className='form-control'
                value={locationName}
                disabled
                placeholder='Select location'
              />
              <span className='input-group-text'>
                <i className='ki-duotone ki-abstract-10 text-primary fs-1'>
                  <i className='path1'></i>
                  <i className='path2'></i>
                </i>
              </span>
            </div>
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Date Type:</label>
            <Select
              className='w-250px'
              placeholder='Date Type'
              isClearable={true}
              options={DateTypeOptions}
              value={DateType ? DateTypeOptions.find((obj) => obj.value === DateType) : null}
              onChange={(e: any) => setDateType(e?.value)}
              styles={ReactSelectStyles}
            />
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Date Range:</label>
            <FlatpickrRange
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate ? moment(startDate).format('MM-DD-YYYY') : ''}
              endDate={endDate ? moment(endDate).format('MM-DD-YYYY') : ''}
            />
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Assigned to:</label>
            <SelectAsyncPaginate2
              apiLink={`${API_URL}/Tasks/SubContractorInTeam`}
              value={selectedSubContractorInTeam}
              isDisabled={selectedProject === null ? true : false}
              id={selectedProject?.value}
              onChange={async (e: any) => {
                setSelectedSubContractorInTeam(e)
              }}
              isClearable={true}
              placeholder='Assignee'
              width='w-250px'
              isMulti={true}
            />
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='position-relative my-1 me-2'>
            <label className='form-label'>Includes Tags:</label>
            <Select
              className='w-250px'
              isDisabled={selectedProject === null ? true : false}
              isMulti
              options={tagss}
              value={
                TagsIncludes ? tagss?.filter((tag: any) => TagsIncludes?.includes(tag.value)) : null
              }
              onChange={(e: any) => {
                SetTagsIncludes(e?.map((tag: any) => tag.value))
              }}
              styles={ReactSelectStyles}
              placeholder='Includes Tags'
            />
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Excludes Tags:</label>
            <Select
              className='w-250px'
              isDisabled={selectedProject === null ? true : false}
              isMulti
              options={tagss}
              value={
                TagsExcludes ? tagss?.filter((tag: any) => TagsExcludes?.includes(tag.value)) : null
              }
              onChange={(e: any) => {
                SetTagsExcludes(e?.map((tag: any) => tag.value))
              }}
              styles={ReactSelectStyles}
              placeholder='Excludes Tags'
            />
          </div>
        </div>
        <div className='mb-3 me-3'>
          <div className='my-1 me-2'>
            <label className='form-label'>Created by:</label>
            <SelectAsyncPaginate
              apiLink={`${API_URL}/Tasks/GetCreatedBy`}
              value={selectedCreatedBy}
              isDisabled={false}
              isClearable={true}
              isMulti={true}
              placeholder='Created by'
              width='w-250px'
              onChange={async (e: any) => {
                setSelectedCreatedBy(e)
              }}
            />
          </div>
        </div>
        {/* end::Search */}
      </div>

      {isModalOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-750px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Select Location</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setIsModalOpen(false)}
                    style={{cursor: 'pointer'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                </div>
                <div className='modal-body mx-5 mx-xl-5 my-7'>
                  <form>
                    {/* form input fields here */}
                    <h4 className=' mb-8'>Select Location</h4>
                    <div className='col-xl-12'>
                      <Row>
                        <Col xl='4' lg='4' md='6'>
                          <MyTreeMulti
                            setSelectedKeys={setSelectedKeys}
                            selectedKeys={selectedKeys}
                            data={locations && convertData(locations)}
                            onSelect={onSelect}
                          />
                        </Col>
                        <Col xl='8' lg='8' md='6' className='plan-image'>
                          {plan && (
                            <img
                              alt='Logo'
                              src={plan}
                              className='w-100 h-400px object-cover object-center rounded border border-gray-300 border-dashed p-5 bg-white shadow-sm mb-5 mb-xl-0 card-img-top'
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className='position-relative mt-20 pt-20'>
                      <button
                        type='button'
                        className='btn btn-primary btn-sm me-4'
                        onClick={() => {
                          setIsModalOpen(false)
                        }}
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        className='btn btn-danger btn-sm me-4'
                        onClick={() => {
                          setSelectedKeys([])
                          setPlan("")
                          setLocation([])
                          setLocationName('')
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  ) : null
}

export {TeamsListSearchComponent}
