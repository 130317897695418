import moment from "moment";

export function DateFormat(date: any): string {
    if (date) {
        return moment(date).format('MM/DD/YYYY HH:mm');
    }
    return '';
}
export function DateFormatwithNoMoment(date: any): string {
    if (date) {
        return moment(date).format('MM/DD/YYYY');
    }
    return '';
}