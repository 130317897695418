/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Tag } from '../../core/_models'

type Props = {
  user: Tag
}

const TagInfoCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className="badge badge-secondary">{user.name}</span>
    </div>
  </div>
)

export { TagInfoCell }
