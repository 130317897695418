import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import Swal from 'sweetalert2'
import {useQueryClient} from 'react-query'
import Payment2 from './stripe/Payment2'
const cardSchema = Yup.object().shape({
  cardName: Yup.string().required('Name on card is required'),
})

const API_URL = process.env.REACT_APP_API_URLL

type Props = {
  setIsOpen: (isOpen: boolean) => void
}

const initialValues = {
  cardName: '',
  cardToken: '',
  cardId: '',
}
const BillingCardModalForm: FC<Props> = ({setIsOpen}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const queryClient = useQueryClient()

  const handleSubmit = async (data: any) => {
    setIsSubmitting(true)
    try {
      await axios.put(`${API_URL}/billing/UpdateCard`, data).then((res) => {
        Swal.fire({
          text: `Card has been updated!`,
          icon: 'success',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
        setIsOpen(false)
        queryClient.invalidateQueries('BillingDetails')
      })
    } catch (error: any) {
      Swal.fire({
        text: error.response.data,
        icon: 'error',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: cardSchema,
    onSubmit: async (values, {setSubmitting}) => {
      document.getElementById('pay_now2')?.click()
    },
  })

  return (
    <form className='form'>
      {/* begin::Scroll */}
      <div className='d-flex flex-column me-n7 pe-7'>
        <div className='w-100'>
          <div className='d-flex flex-column mb-7 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>Name On Card</span>
            </label>

            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter card name'
              name='cardName'
              value={formik.values.cardName}
              onChange={formik.handleChange}
            />
            {formik.errors.cardName && formik.touched.cardName ? (
              <div className='text-danger mt-2'>
                <span className='p-2'>{formik.errors.cardName}</span>
              </div>
            ) : null}
          </div>

          <div className='row mb-10'>
            <div className='col-12 fv-row'>
              <label className='required fs-6 fw-bold form-label mb-2'>Card info</label>
              <div className='row fv-row'>
                <Payment2
                  formik={formik}
                  handleSubmit2={handleSubmit}
                />
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end mt-5'>
            <button
              disabled={isSubmitting}
              type='button'
              className='btn btn-primary'
              onClick={() => formik.handleSubmit()}
            >
              {isSubmitting ? 'Please wait...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export {BillingCardModalForm}
