import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import axios from 'axios'
import Swal from 'sweetalert2'
import {Flatpickr} from '../../../../global/Flatpickr '
import {handleApiErrors} from '../../../../global/HandleApiErrors'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import SelectAsyncPaginate from '../../../../global/SelectAsyncPaginate'
import {useParams} from 'react-router-dom'
import SelectAsyncPaginate3 from '../../../../global/SelectAsyncPaginate3'
const API_URL = process.env.REACT_APP_API_URLL

type Props = {
  SetIsTagsOpen: (value: boolean) => void
  selected: any
  clearSelected: () => void
  type: string
}
const TagsModal: FC<Props> = ({selected, SetIsTagsOpen, clearSelected, type}) => {
  const params = useParams<any>()
  const {id} = params
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedTagsIds, setSelectedTagsIds] = useState<any[]>([])
  const [tagss, settagss] = useState<any>([])
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleAddRemoveTags = async (data: any) => {
    if (selectedTagsIds.length === 0)
      return Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select a tag.',
        showConfirmButton: true,
        timer: 3000,
        width: '25rem',
      })
    try {
      await axios.put(`${API_URL}/Tasks/BulkChangeTag`, data).then((res) => {
        queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${query}`])
        clearSelected()
        SetIsTagsOpen(false)
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: `${
            type === 'remove'
              ? 'Tags have been removed successfully.'
              : 'Tags have been added successfully.'
          }`,
          showConfirmButton: true,
          timer: 3000,
          width: '25rem',
        })
      })
    } catch (error: any) {
      handleApiErrors(error.response.data)
    }
  }

  useEffect(() => {
    const selectedTagsIdss = selectedTags.map((item: any) => item.value)
    setSelectedTagsIds(selectedTagsIdss)
  }, [selectedTags])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-550px'>
          <div className='modal-content'>
            <div className='modal-body mx-5 mx-xl-5 my-3 mh-800px'>
              <div className='d-flex flex-column mb-10'>
                <label className='fw-bold fs-6 mb-5 required'>Select Tags</label>
                <SelectAsyncPaginate3
                  apiLink={`${API_URL}/Tasks/Tags`}
                  value={selectedTags}
                  id={id}
                  onChange={(e: any) => {
                    setSelectedTags(e)
                  }}
                  isClearable={true}
                  width='w-250px'
                  isMulti={true}
                />
              </div>
              <div className='d-flex flex-end'>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    data-kt-users-modal-action='cancel'
                    onClick={() => {
                      SetIsTagsOpen(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    // disabled={loading}
                    type='button'
                    className={`btn btn-sm btn-${
                      type === 'remove' ? 'danger' : 'primary'
                    } btn-active-light-${type === 'remove' ? 'danger' : 'primary'}`}
                    data-kt-users-modal-action='cancel'
                    onClick={() => {
                      handleAddRemoveTags({
                        tagIds: selectedTagsIds,
                        Ids: selected,
                        add: type === 'remove' ? false : true,
                      })
                    }}
                  >
                    {type === 'remove' ? 'Remove Tags' : 'Add Tags'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {TagsModal}
