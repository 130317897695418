/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { User } from '../../core/_models'

type Props = {
  user: User
}

const TaskAssignCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-600 text-hover-primary mb-1'>
        {
          user.contractorFirstName + ' ' + user.contractorLastName
        }
      </a>
    </div>
  </div>
)

export { TaskAssignCell }
