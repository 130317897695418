// @ts-nocheck
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { Modal } from 'react-bootstrap'
import { TasksDetails } from '../../../../../_metronic/partials/layout/search/TaskDetails'


type Props = {
  row: Row<User>
}

const CustomRow: FC<Props> = ({ row }) => {
  const [edittitleshow, setedittitleshow] = useState(false)
  const [idtask, setidtask] = useState()

  return (
    <>
     <Modal
          show={edittitleshow}
          onHide={() => {
            setedittitleshow(false)
          }}
          aria-labelledby='example-modal-sizes-title-lg'
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-lg'>Task details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TasksDetails idd={idtask} />
          </Modal.Body>
          <Modal.Footer>
            <div className='text-centre'>
              <button
                type='reset'
                onClick={() => {
                  setedittitleshow(false)
                }}
                className='btn btn-light'
                data-kt-users-modal-action='cancel'
              >
                Discard
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      <tr {...row.getRowProps()}>
        {row.cells.map((cell, index) => {
          return (
            <>{index===0? <td
              {...cell.getCellProps()}
              className={clsx({ 'text-end min-w-100px ': cell.column.id === 'actions' }) + `${index === 0 ? 'link-primary text-primary cursor-pointer' : ''}`}             
              onClick={() => {
                if (index === 0) {
                  console.log(cell?.row?.original)
                 setidtask(cell?.row?.original.id)
                 setedittitleshow(true)
                  
                }
              }}
            >
              {cell?.row?.original.name}
            </td>: <td
              {...cell.getCellProps()}
              className={clsx({ 'text-end min-w-100px ': cell.column.id === 'actions' }) + `${index === 1 ? 'link-primary text-primary cursor-pointer' : ''}`}             
          
            >
              {cell.render('Cell')}
            </td>}
            
            </>
           
          )
        })}
      </tr>
    </>

  )
}

export { CustomRow }
