import {  useEffect } from 'react'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import Import from '../../../../../global/Import';
import { useListView } from '../../core/ListViewProvider'
import { TeamsListFilter } from './TeamsListFilter'
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ReactSelectStyles } from '../../../../../../modules/global/ReactSelectStyle'
const BASE_URL = process.env.REACT_APP_BASE_URL
const createUserSchema = Yup.object().shape({
  contractorCompany: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contractor company is required'),
  Inspector: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Inspector Name is required'),
})
const TeamsListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const [loading] = useState(false)
  const [editshow, seteditShow] = useState(false)

  const [isOpen, setIsOpen] = useState(false);
  function onClose() {
    setIsOpen(false);
  }
  function onSubmit(data: any) {
    console.log(data);
  }

  const fields = [
    {
      label: "Firt Name",
      key: "firstname",
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      example: "Stephanie",
      validations: [
        {
          rule: "required",
          errorMessage: "First Name is required",
          level: "error",
        },
      ],
    },
    {
      label: "Last Name",
      key: "lastname",
      fieldType: {
        type: "input",
      },
      example: "Norgate",
      validations: [
        {
          rule: "required",
          errorMessage: "Last Name is required",
          level: "error",
        },
      ],
    },
    {
      label: "Email",
      key: "email",
      fieldType: {
        type: "input",
      },
      example: "Norgate@mail.com",
      validations: [
        {
          rule: "required",
          errorMessage: "Email is required",
          level: "error",
        },
        {
          rule: "email",
          errorMessage: "Email is not valid",
          level: "error",
        },
      ],
    },
    {
      label: "Team",
      key: "team",
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      example: "Developer",
    },
    {
      label: "Manager",
      key: "manager",
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "checkbox",
      },
      example: "True/False",
    },
  ];
  const [Listinspection, setListinspection] = useState([])
  const [contractorCompany, setcontractorCompany] = useState([])
  const inspectionOptions = Listinspection?.map((project: any) => ({
    value: project.id,
    label: project.firstName,
  }))
  const contractorCompanyOptions = contractorCompany?.map((project: any) => ({
    value: project.contractingCompanyId,
    label: project.name,
  }))
  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/;
    const match = url.match(regex);
    if (match) {
      return parseInt(match[1]);
    }
    return null;
  }

  // const id = window.location.href && extractIdFromUrl(window.location.href)
  useEffect(() => {
    async function getViewModel() {
      try {
        const response = await axios.get(`${BASE_URL}/api/Projects/GetViewModel`)
        if (response.status === 200) {
          setcontractorCompany(response?.data.data.contractorCompany)
        }
      } catch (error) {
        console.log(error)
      }
    }
    async function getViewModelList() {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/Projects/TeamDropDown?isActive=true&pageNumber=1&pageSize=10&sortOrder=desc`
        )
        if (response.status === 200) {
          setListinspection(response.data.data)
          console.log(response.data.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getViewModelList()
    getViewModel()
  }, [])
  const [userForEdit] = useState({
    contractorCompany: [],
    Inspector: [],
  })
  function extractDetailsFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('details') === 'true'
  }
  const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: createUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log()

    },
  })
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <TeamsListFilter />

      {isDetails ? "" :
        <>
          {/* <button
        onClick={() => setIsOpen(true)}
         type='button' 
         className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Import
      </button> */}
          {/* end::Export */}

          {/* begin::Add user */}
          <button type='button' className='btn btn-primary me-5' onClick={openAddUserModal}>
            <KTIcon iconName='plus' className='fs-2' />
            New team member
          </button>
        </>}

      {/* begin::Export */}



      <Modal
        show={editshow}
        onHide={() => {
          seteditShow(false)
        }}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>Edit teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Inspector Team ({formik.values.Inspector.length})</label>
            <Select
              isMulti
              name='Inspector'
              options={inspectionOptions}
              // value={
              //   inspectionOptions?.filter((item: any) =>
              //     formik?.values?.Inspector?.includes(item.value)
              //   ) || {value: null, label: 'Select...'}
              // }
              onChange={(e: any) =>
                formik.setFieldValue(
                  'Inspector',
                  e.map((item: any) => item.value)
                )
              }
              styles={ReactSelectStyles}
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Subcontractor ({formik.values.contractorCompany.length})</label>
            <Select
              isMulti
              name='contractorCompany'
              options={contractorCompanyOptions}
              // value={
              //   contractorCompanyOptions?.filter((item: any) =>
              //     formik?.values?.contractorCompany?.includes(item.value)
              //   ) || {value: null, label: 'Select...'}
              // }
              onChange={(e: any) =>
                formik.setFieldValue(
                  'contractorCompany',
                  e.map((item: any) => item.value)
                )
              }
              styles={ReactSelectStyles}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-centre'>
            <button
              type='reset'
              onClick={() => {
                seteditShow(false)
              }}
              className='btn btn-light'
              data-kt-users-modal-action='cancel'
            >
              Discard
            </button>
            <button type='button' className='btn btn-primary ms-10' >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* end::Add user */}
      <Import isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} allowInvalidSubmit={false} />
    </div>
  )
}

export { TeamsListToolbar }
