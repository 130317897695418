import { useMemo, useEffect, useState } from 'react';
import { useTable, Row } from 'react-table';
import { CustomRow } from '../table/columns/CustomRow';
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider';
import { usersColumns } from './columns/_columns';
import { Tag } from '../core/_models';
import { UsersListLoading } from '../components/loading/UsersListLoading';
import { UsersListPagination } from '../components/pagination/UsersListPagination';
import { KTCardBody } from '../../../../../../_metronic/helpers';
import Toggle from 'react-styled-toggle';

const TagsTable = () => {
  const users = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => users, [users]);
  const columns = useMemo(() => usersColumns, []);
  const [ColumnsVisibility, setHiddenColumnsVisibility] = useState<boolean>(false);

  // Retrieve hidden columns from local storage
  const hiddenColumnsString = localStorage.getItem('hiddenColumns');
  const hiddenColumns = hiddenColumnsString ? JSON.parse(hiddenColumnsString) : [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns, // Set the initial hidden columns from local storage
      },
    },
  );

  // Save hidden columns to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('hiddenColumns', JSON.stringify(state.hiddenColumns));
  }, [state.hiddenColumns]);

  return (
    <KTCardBody className='py-4'>
      <div className='d-flex justify-content-end pb-5 my-3'>
        <Toggle
          value='1'
          onChange={() => {
            setHiddenColumnsVisibility(!ColumnsVisibility);
          }}
          name='toggle'
          checked={ColumnsVisibility}
          disabled={false}
          labelRight='Column visibility'
          labelLeft=''
          height={28}
          sliderHeight={22}
        />
      </div>

      {
        ColumnsVisibility && <div className='me-5 pb-5 my-3'>
          {/* <div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
            <span className='text-gray-800 fw-bold me-3'>Toggle All</span>
          </div> */}
          <div className='row'>
            {allColumns.map(column => {
              if (column.id !== 'selection' && column.id !== 'actions' && column.id !== 'team') {
                return (
                  <div key={column.id} className='col-2'>
                    <div className="form-check form-check-custom form-check-solid mx-5 pb-3">
                      <input className="form-check-input me-3" type="checkbox" {...column.getToggleHiddenProps()} />
                      <span className='text-gray-800 fw-bold'>{column.render('Header')}</span>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <br />
        </div>
      }

      <div className='table-responsive custom-scrollbar'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headerGroup.headers.map(column => (
                  <>{column.render('Header')}</>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Tag>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};

export { TagsTable };
