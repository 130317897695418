import { FC } from 'react'

type Props = {
  isActive?: boolean
}

const TeamTwoStepsCell: FC<Props> = ({ isActive }) => (
  <>
    {
      isActive ? (
        <span className='badge badge-light-success'>Active</span>
      ) : (
        <span className='badge badge-light-danger'>Inactive</span>
      )
    }
  </>
)

export { TeamTwoStepsCell }
