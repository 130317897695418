import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { ReactSelectStyles } from "./ReactSelectStyle";


interface SelectAsyncPaginateProps {
    value?: any;
    onChange?: (option: any) => void;
    apiLink?: string;
    isDisabled?: boolean;
    id: any;
    width?: string;
    isClearable?: boolean;
    placeholder?: string;
    isMulti?: boolean;
}

const SelectAsyncPaginate2: React.FC<SelectAsyncPaginateProps> = (props) => {
    const [optionsListt, setOptionsList] = useState<any>([]);
    const [key, setKey] = useState<any>("");

    const loadOptions: any = async (
        searchQuery: string,
        loadedOptions: any,
        { page }: { page: number }
    ) => {
        let optionsList: any = [];
        const AUTH_LOCAL_STORAGE_KEY = 'User'
        const token = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
        const response = await fetch(
            `${props.apiLink}?ProjectId=${props.id}&search=${searchQuery}&pageNumber=${page}&pageSize=10`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token?.token}`,
                },
            }
        );
        const responseJSON: any = await response.json();

        optionsList = responseJSON?.data?.map((item: any) => ({
            label: item?.firstName ? item?.firstName + " " + item?.lastName : item?.name,
            value: item?.id,
        }));

        setOptionsList(optionsList.length === 0 ? [] : optionsList);
        return {
            options: optionsList.length === 0 ? [] : optionsList,
            hasMore: responseJSON?.pagination?.hasNextPage,
            additional: {
                page: page + 1,
            },
        };
    };

    const onChange = (option: any) => {
        if (typeof props.onChange === "function") {
            props.onChange(option);
        }
    };

    useEffect(() => {
        props.id && loadOptions("", "", { page: 1 });
        setKey(`${props.apiLink}-${props.id}`);
      }, [props.id]);

    return (
        <>
            <AsyncPaginate
                loadOptions={loadOptions}
                key={key}
                value={props.value}
                onChange={onChange}
                placeholder={props.placeholder ? props.placeholder : "Select..."}
                additional={{
                    page: 1,
                }}
                isDisabled={props.isDisabled}
                isClearable={props.isClearable}
                styles={ReactSelectStyles}
                className={props.width}
                isMulti={props.isMulti ? props.isMulti : false}
            />
        </>
    );
};

SelectAsyncPaginate2.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default SelectAsyncPaginate2;
