/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {ModelStateMessage, QueryFetchPost} from '../../../../app/modules/global'
const AUTH_LOCAL_STORAGE_KEY = 'User'
const BASE_URL = process.env.REACT_APP_BASE_URL

interface Model {
  expiresOn: ''
  lastName: ''
  firstName: ''
  isAuthenticated: boolean
  message: string
  token: string
  username: string
  profilePicture: null
  roles: []
  email: string
}
const HeaderUserMenu = (props:any) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const {logout} = useAuth()
  const [showPassword, setShowPassword] = useState<Model | undefined>()
  const [pic, setpic] = useState<any | undefined>()
  const [imageApi, setimageApi] = useState<any>(null)
  const [fname, setfname] = useState<any>(null)
  const [lname, setlname] = useState<any>(null)
  const [email, setemail] = useState<any>(null)
  const GetProfile = async () => {
    setShowPassword(undefined)
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        setShowPassword(response[1].profile)
        setfname(response[1].profile.firstName)
        setlname(response[1].profile.lastName)
        setemail(response[1].profile.email)
        if (response[1].profile.profilePicture) {
          console.log(showPassword)
          console.log(response[1].profile)
          setimageApi(response[1].profile.profilePicture)
        } else {
          setimageApi(null)
        }
      } else {
        ModelStateMessage(response)
      }
    })
  }
  useEffect(() => {
    setpic(localStorage.getItem('profilePicture'))
    GetProfile()
  }, [])
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
            >
              {pic === 'null' ? (
                <div
                  className='image-input-wrapper w-50px h-50px'
                  style={{backgroundImage: 'url(/assets/media/avatars/300-1.jpg)'}}
                />
              ) : (
                <img
                  alt='Logo'
                  className='image-input-wrapper w-50px h-50px'
                  src={toAbsoluteUrl(imageApi)}
                />
              )}
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5 text-nowrap'>
              {fname}{"  "}{lname}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7 text-break'>
              {email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'>
        <button
          id='testtest'
          onClick={() => {
            console.log("inininin")
            document?.getElementById('testtest2')?.click();
            setpic(localStorage.getItem('profilePicture'))
            GetProfile()
            setShowPassword(JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}'))
          }}
          className='btn mt-1 bg-white'
        ></button>
      </div>

      <div className='menu-item px-5'>
        <Link to={'profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      {/* <div className='menu-item px-5 my-1'>
        <Link to='settings' className='menu-link px-5'>
          Settings
        </Link>
      </div> */}

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      ></div>

      {/* <div className='separator my-2'></div> */}

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
