import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ModelStateMessage, QueryFetchPost} from '../../global'
import {removeAuth, useAuth} from '../../auth'
const ChangeSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('newPassword')], "Password and Confirm Password didn't match"),
})
const BASE_URL = process.env.REACT_APP_BASE_URL
const PasswordAndSecurity: React.FC = () => {
  var initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [loading, setLoading] = useState(false)
  const {logout} = useAuth()
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowPassword1, setShowPassword1] = useState(false)
  const [isShowPassword2, setShowPassword2] = useState(false)
  const AUTH_LOCAL_STORAGE_KEY = 'User'
  const ChangePassword = async (credentials: typeof initialValues) => {
    setLoading(true)
    const authData = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/ChangePassword`,
      data: credentials,
      method: 'POST',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        setLoading(false)
        setTimeout(() => {
          logout()
        }, 1000)
        Swal.fire({
          title: 'Success',
          timer: 1000,
          text: 'Password updated successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        initialValues = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }

        formik.resetForm()
        logout()
      } else if (response[0] === 400) {
        Swal.fire('Oops?',response[1].errors[0].description, 'error')
        setLoading(false)
      } else {
        ModelStateMessage(response)
        setLoading(false)
      }
    })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: ChangeSchema,
    onSubmit: (values) => {
      ChangePassword(values)
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Change Password</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Current Password
              </label>
              <div className='col-lg-8 fv-row'>
                <div className='input-group input-group-solid mb-5'>
                  <input
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder={'Current Password'}
                    className={`form-control h-auto py-3 px-6`}
                    {...formik.getFieldProps('oldPassword')}
                    onChange={(e) => formik.setFieldValue('oldPassword', e.target.value)}
                  />

                  <span
                    className='input-group-text align-self-center cursor-pointer'
                    onClick={(e) => setShowPassword(!isShowPassword)}
                  >
                    {!isShowPassword ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{formik.errors.oldPassword}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>New Password</label>
              <div className='col-lg-8 fv-row'>
                <div className='input-group input-group-solid mb-5'>
                  <input
                    type={isShowPassword1 ? 'text' : 'password'}
                    placeholder='New Password'
                    className={`form-control h-auto py-3 px-6`}
                    {...formik.getFieldProps('newPassword')}
                    onChange={(e) => formik.setFieldValue('newPassword', e.target.value)}
                  />

                  <span
                    className='input-group-text align-self-center cursor-pointer'
                    onClick={(e) => setShowPassword1(!isShowPassword1)}
                  >
                    {!isShowPassword1 ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{formik.errors.newPassword}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Confirm Password
              </label>
              <div className='col-lg-8 fv-row'>
                <div className='input-group input-group-solid mb-5'>
                  <input
                    type={isShowPassword2 ? 'text' : 'password'}
                    className={`form-control h-auto py-3 px-6`}
                    placeholder='Confirm Password'
                    {...formik.getFieldProps('confirmPassword')}
                    onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
                  />

                  <span
                    className='input-group-text align-self-center cursor-pointer'
                    onClick={(e) => setShowPassword2(!isShowPassword2)}
                  >
                    {!isShowPassword2 ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{formik.errors.confirmPassword}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={Object.keys(formik.errors).length != 0 || loading}
            >
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export {PasswordAndSecurity}
