import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
// import {isNotEmpty} from '../../../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { initialUser, contractorCompany } from '../core/_models'
// import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
// import {createUser, updateUser} from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
// import {TeamsListLoading} from '../components/loading/TeamsListLoading'
// import {OptionType} from '../../../../global/ReactSelect'
import Swal from 'sweetalert2'
// import {handleApiErrors} from '../../../../global/HandleApiErrors'
// import {ReactSelectStyles} from '../../../../global/ReactSelectStyle'
// import {useParams} from 'react-router-dom'
// import Select from 'react-select'
// import SVG from 'react-inlinesvg'
import axios from 'axios'
// import {Col, Row} from 'react-bootstrap'
// import {useLocation} from 'react-router-dom'
// import queryString from 'query-string'
import { QueryFetchPost, ModelStateMessage } from '../../../../global'
import clsx from 'clsx'
import PhoneInput from 'react-phone-input-2'
import SVG from 'react-inlinesvg'
import Import from '../../../../global/Import'
import { Loading } from '../../../../global/Loading'
// import {Link} from 'react-router-dom'

// import { Select, Page, setOptions, localeAr } from '@mobiscroll/react';

const BASE_URL = process.env.REACT_APP_BASE_URL

type Props = {
  isUserLoading: boolean
  user: any
  setshow: (value: React.SetStateAction<boolean>) => void;

}

interface CheckboxProps {
  id: string
  value: string
  onCheckboxChange: (id: string, isChecked: boolean) => void
}
interface typ {
  lastName: string
  firstName: string
  email: string
  isInspectors: boolean
}
interface Model {
  firstName: string | undefined
  lastName: string | undefined
  phoneNumber: string | undefined
  email: string | undefined
  companyName: string | undefined
  address1: string
  address2: string
  city: string | undefined
  country: any
  profilePicture: null
  role: string | undefined
  site: string | undefined
  state: any
  zipCode: string | undefined
}
const createUserSchema = Yup.object().shape({
  contractorCompany: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contractor company is required'),
  Inspector: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Inspector Name is required'),
})
const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone number is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})
const registrationSchemains = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),

  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone number is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

// const editUserSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   firstName: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('First Name is required'),
//   lastName: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Last Name is required'),
// })

const TeamsEditModalForm: FC<Props> = ({ user, isUserLoading, setshow }) => {
  const [ListTeams, setListTeams] = useState<any>()
  const [check, setcheck] = useState<any>('0')
  const [ids, setids] = useState<any>()
  const [DetailCheck, setDetailCheck] = useState<typ[]>([])
  const [show, setShow] = useState(false)
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [loading, setLoading] = useState(false)
  const [Icount, setIcount] = useState<number | null>()
  const [Scount, setScount] = useState<number | null>()
  var ListId: Array<number> = []
  useEffect(()=>{console.log(DetailCheck,DetailCheck.length)},[DetailCheck])
  const [userForEdit] = useState<contractorCompany>({
    ...user,
    contractorCompany: user.contractorCompany || initialUser.contractorCompany,
    Inspector: user.Inspector || initialUser.Inspector,
  })
  const handleClose = () => setShow(false)
  const Handelchange = (e: React.ChangeEvent) => {
    console.log()
    setcheck(e.target.ariaValueText)
  }
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  useEffect(() => {
    if (!show) {

    }
  }, [show])
  const [importing, setImporting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const API_URL = process.env.REACT_APP_API_URLL
  const IMPORT_URL = `${API_URL}/Importing/ProjectTeam`

  function onClose() {
    setIsOpen(false)
  }
  async function onSubmit(data: any) {
    setImporting(true)
    await QueryFetchPost({
      link: IMPORT_URL,
      data: {
        team: data.validData,
      },
      method: 'POST',
      key: 'importTeams',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      setImporting(false)
      if (response[0] === 200 && response[1].messages.length === 0) {
        geteam()
        setImporting(false)
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Teams imported successfully',
          width: '25rem',
        })
      } else if (response[0] === 200 && response[1].messages.length > 0) {
        setImporting(false)
        geteam()
        let messages = ''
        response[1].messages.forEach((message: any) => {
          console.log(message.message)
          messages += message.message + '</br>'
        })
        Swal.fire({
          icon: 'info',
          title: 'Info',
          html: `<div class="text-center">${messages}</div>`,
        })
      } else {
        setImporting(false)
        ModelStateMessage(response)
      }
    })
  }

  const fields = [
    {
      label: 'First Name',
      key: 'First Name*',
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: 'input',
      },
      example: 'Stephanie',
      validations: [
        {
          rule: 'required',
          errorMessage: 'First Name is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Last Name',
      key: 'Last Name*',
      fieldType: {
        type: 'input',
      },
      example: 'Norgate',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Last Name is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Email',
      key: 'Email*',
      fieldType: {
        type: 'input',
      },
      example: 'Norgate@mail.com',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Email is required',
          level: 'error',
        },
        {
          rule: 'Email*',
          errorMessage: 'Email is not valid',
          level: 'error',
        },
      ],
    },
    {
      label: 'Phone',
      key: 'Phone',
      fieldType: {
        type: 'input',
      },
      example: '+1 123 456 7890',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Phone is required',
          level: 'error',
        },
        {
          rule: 'Email*',
          errorMessage: 'Email is not valid',
          level: 'error',
        },
      ],
    },
    {
      label: 'Role',
      key: 'Role*',
      fieldType: {
        type: 'select',
        options: [
          {
            label: 'Subcontractor',
            value: 'Subcontractor',
          },
          {
            label: 'Inspector',
            value: 'Inspector',
          },
        ],
      },
      example: 'Inspector',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Role is required',
          level: 'error',
        },
        {
          rule: 'Role*',
          errorMessage: 'Role is not valid',
          level: 'error',
        },
      ],
    },
    {
      label: 'Company Name',
      key: 'companyName',
      fieldType: {
        type: 'input',
      },
      example: 'Adelphatech',
    },
  ]

  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }
  const id = window.location.href && extractIdFromUrl(window.location.href)
  async function geteam() {
    setScount(null)
    setIcount(null)
    try {
      const response = await axios.get(`${BASE_URL}/api/Projects/GetCompanies?search=${searchTerm}`)
      if (response.status === 200) {
        
        const totalCount = response?.data?.data?.contractingCompanies?.reduce(
          (sum :any, obj:any) => sum + obj.subContractor.length,
          0
        )
        setScount(totalCount)
        setIcount(response?.data?.data?.inspectionCompanies?.ispectorsTeam.length)
        setListTeams(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    geteam()
  }, [])
  const [loadingteam, setloadingteam] = useState(false)

  async function createteams() {
    setloadingteam(true)
    await QueryFetchPost({
      link: `${BASE_URL}/api/Projects/AddTeam/${id}`,
      data: {
        ids: items,
      },
      method: 'POST',
      key: 'createteams',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        setloadingteam(false)
        Swal.fire({
          title: 'Success',
          text: `Teams added successfully`,
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        refetch()
        cancel()
      } else {
        ModelStateMessage(response)
        setloadingteam(false)

      }
    })
  }
  async function registration(credentials: typeof initialValues) {
    console.log(check)
    setLoading(true)
    if (check === '0') {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Account/Register`,
        data: {
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          email: credentials.email,
          password: credentials.password,
          confirmPassword: credentials.confirmPassword,
          companyName: credentials.companyName,
          phoneNumber: credentials.phoneNumber,
          Site: credentials.Site,
          role: check,
          fromProject: true,
        },
        method: 'POST',
        key: 'RegisterEmail',
        signal: undefined,
        isFormData: false,
      }).then((response) => {
        if (response[0] === 200) {
          setShow(false)
          setLoading(false)
          Swal.fire({
            title: 'Success',
            text: `${check === '0' ? 'Subcontractor' : 'Inspector'} created successfully`,
            icon: 'success',
            confirmButtonText: 'Ok',
          })

          geteam()
        } else {
          ModelStateMessage(response)
          setLoading(false)
        }
      })
    } else {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Teams`,
        data: {
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          email: credentials.email,
          password: credentials.password,
          confirmPassword: credentials.confirmPassword,
          phone: credentials.phoneNumber,
          role: check,
          fromProject: true,
        },
        method: 'POST',
        key: 'RegisterEmail',
        signal: undefined,
        isFormData: false,
      }).then((response) => {
        if (response[0] === 200) {
          console.log(check)
          setLoading(false)
          Swal.fire({
            title: 'Success',
            text: `${check === "0" ? 'Subcontractor' : 'Inspector'} created successfully`,
            icon: 'success',
            confirmButtonText: 'Ok',
          })
          setShow(false)
          geteam()
        } else {
          ModelStateMessage(response)
          setLoading(false)
        }
      })
    }
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: createUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log()
    },
  })

  const [checkedValues, setCheckedValues] = useState<string[]>([])
  const [items, setItems] = useState<{ id: string; isInspectors: boolean }[]>([])
  var itemlist: Array<any> = []

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, isInspectors: boolean) => {
    itemlist.push(items)
    const id = event.target.value
    const isChecked = event.target.checked

    if (isChecked) {
      setCheckedValues((prevValues) => [...prevValues, id])
    } else {
      setCheckedValues((prevValues) => prevValues.filter((v) => v !== id))
      const tt = items.filter((item: any) => item.id !== id)
      const bb = DetailCheck.filter((item: any) => item.id !== id)
      setItems(tt)
      setDetailCheck(bb)
      console.log(tt)
    }
    console.log(items)
    const newItem = { id, isInspectors: isInspectors }
    const itemIndex = items.findIndex((item) => item.id === id)
    if (itemIndex === -1) {
      setItems([...items, newItem])
    }
  }
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowPassword2, setShowPassword2] = useState(false)
  const [initialValues, setinitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    phoneNumber: '',
    Site: '',
    role: '0',
    fromProject: true,
  })
  useEffect(() => {
    if (!show) {
      setinitialValues({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        phoneNumber: '',
        Site: '',
        role: '0',
        fromProject: true,
      })
      formikR.resetForm()
    }
  }, [show])

  const formikR = useFormik({
    initialValues,
    validationSchema: check === '0' ? registrationSchema : registrationSchemains,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      registration(values)
    },
  })
  const [searchTerm, setSearchTerm] = useState<string>('')
  useEffect(() => {
    geteam()
  }, [searchTerm])
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-grid gap-2 d-md-flex justify-content-between'>
          <div className='card-title'>
            {/* begin::Search */}

            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14 h-100'
                placeholder='Search Project'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          <div>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              onClick={() => setIsOpen(true)}
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              Import
            </button>
            <button
              type='button'
              className='btn btn-primary w-15 ms-100'
              onClick={() => {
                setshow(true)
                setShow(true)
              }}
            >
              <span className='indicator-label'>{'Create new contact'}</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </div>
        <h3 className=' mb-2 mt-5 fs-3'>Select Team   </h3>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row w-100 h-300px '>
          
          { ListTeams?.inspectionCompanies.ispectorsTeam.length===0 && Scount === 0 ?
             <div className='text-center'>
             <div className='pt-10 pb-10'>
               <KTIcon iconName='search-list' className='fs-4x opacity-50' />
             </div>

             <div className='pb-15 fw-bold'>
               <h3 className='text-gray-600 fs-5 mb-2'>No matching records found</h3>
               <div className='text-muted fs-7'>
                 Please try again with a different key
               </div>
             </div>
           </div>
          :""}

         
            
            <div className='col w-50'>
               {ListTeams?.inspectionCompanies.ispectorsTeam.length >0 ?<><div className='d-flex space-between-center'>
                <div className='form-check'>
                  <label className='cursor-pointer'  onClick={() => {
                    console.log(ListId)

                    if (ids?.includes(ListTeams?.inspectionCompanies.id)) {
                      for (var i = 0; i < ids.length; i++) {
                        setids(
                          ids.filter((item: any) => item !== ListTeams?.inspectionCompanies.id)
                        )
                      }
                      setids(ListId)
                    } else {
                      ListId.push(ListTeams?.inspectionCompanies?.id)
                      setids(ListId)
                    }
                    console.log(ListId?.includes(ListTeams?.inspectionCompanies.id))
                    console.log(ids)
                  }}>
                    {ListTeams?.inspectionCompanies.inspectionCompanyName}</label>
                </div>
                <span
                  className='ant-tree-switcher ant-tree-switcher_close'
                  onClick={() => {
                    console.log(ListId)

                    if (ids?.includes(ListTeams?.inspectionCompanies.id)) {
                      for (var i = 0; i < ids.length; i++) {
                        setids(
                          ids.filter((item: any) => item !== ListTeams?.inspectionCompanies.id)
                        )
                      }
                      setids(ListId)
                    } else {
                      ListId.push(ListTeams?.inspectionCompanies?.id)
                      setids(ListId)
                    }
                    console.log(ListId?.includes(ListTeams?.inspectionCompanies.id))
                    console.log(ids)
                  }}
                >
                  {' '}
                  {ids?.includes(ListTeams?.inspectionCompanies.id) ? (
                    <div className='pt-2 ms-6'>
                      <img
                        alt='Plan'
                        src={toAbsoluteUrl('/media/Plan/down (1).png')}
                        className='w-10px cursor-pointer'
                      />
                    </div>
                  ) : (
                    <div className='pt-2 ms-6'>
                      <img
                        alt='Plan'
                        src={toAbsoluteUrl('/media/Plan/upup.png')}
                        className='w-10px cursor-pointer'
                      />
                    </div>
                  )}
                </span>
              </div>
              {ids?.includes(ListTeams?.inspectionCompanies.id) ? (
                <>
                  {ListTeams?.inspectionCompanies.ispectorsTeam.map((SC: any) => {
                    return (
                      <div className='ms-18 mt-3'>
                        <div className='d-flex space-between-center'>
                          <div className='form-check'>
                            <input
                            id={SC.id} 
                              className='form-check-input'
                              type='checkbox'
                              value={SC.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setDetailCheck((prevValues) => [...prevValues, SC])
                                } else {
                                }
                                handleInputChange(e, SC.isInspectors)
                              }}
                              checked={checkedValues?.includes(SC.id) ? true : false}
                            />
                            <label className='fs-6 cursor-pointer' htmlFor={SC.id} >
                              {SC?.firstName} {SC?.lastName}
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                ''
              )}</>:""}
              

              {ListTeams?.contractingCompanies.map((team: any) => {
                return (
                  <>
                    {team.subContractor.length > 0 ? <>  <div className='d-flex space-between-center'>
                      <div className='form-check'>
                        <label className='cursor-pointer'   onClick={() => {
                          console.log(ListId)

                          if (ids?.includes(team.id)) {
                            for (var i = 0; i < ids.length; i++) {
                              setids(ids.filter((item: any) => item !== team.id))
                            }
                            setids(ListId)
                          } else {
                            ListId.push(team?.id)
                            setids(ListId)
                          }
                          console.log(ListId?.includes(team.id))
                          console.log(ids)
                        }}
                      >{team.name}</label>
                      </div>
                      <span
                        className='ant-tree-switcher ant-tree-switcher_close'
                        onClick={() => {
                          console.log(ListId)

                          if (ids?.includes(team.id)) {
                            for (var i = 0; i < ids.length; i++) {
                              setids(ids.filter((item: any) => item !== team.id))
                            }
                            setids(ListId)
                          } else {
                            ListId.push(team?.id)
                            setids(ListId)
                          }
                          console.log(ListId?.includes(team.id))
                          console.log(ids)
                        }}
                      >
                        {' '}
                        {ids?.includes(team.id) ? (
                          <div className='pt-2 ms-6'>
                            <img
                              alt='Plan'
                              src={toAbsoluteUrl('/media/Plan/down (1).png')}
                              className='w-10px cursor-pointer'
                            />
                          </div>
                        ) : (
                          <div className='pt-2 ms-6'>
                            <img
                              alt='Plan'
                              src={toAbsoluteUrl('/media/Plan/upup.png')}
                              className='w-10px cursor-pointer'
                            />
                          </div>
                        )}
                      </span>
                    </div>
                      {ids?.includes(team.id) ? (
                        <>
                          {team.subContractor.map((SC: any) => {
                            return (
                              <div className='ms-18 mt-5'>
                                <div className='d-flex space-between-center'>
                                  <div className='form-check'>
                                    <input
                                    id={SC.id} 
                                      className='form-check-input'
                                      type='checkbox'
                                      value={SC.id}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setDetailCheck((prevValues) => [...prevValues, SC])
                                        } else {
                                        }
                                        handleInputChange(e, SC.isInspectors)
                                      }}
                                      checked={checkedValues?.includes(SC.id) ? true : false}
                                    />
                                    <label className='fs-6 cursor-pointer' htmlFor={SC.id} >
                                      {SC?.firstName} {SC?.lastName}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        ''
                      )}</> : ""}

                  </>
                )
              })}
            </div>
            <div className='col w-50'>
              {DetailCheck?.map((e) => {
                return (
                  <>
                    <div className='card border border-grey border-2 rounded-3 mt-3'>
                      <div className='card-body'>
                        <h4>
                          {e.firstName} {e.lastName}
                        </h4>
                        <h4 className='mt-1'>{e.email}</h4>
                        <h4 className='mt-1'>{e.isInspectors ? 'Inspector' : 'subContractor'}</h4>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <Modal show={show} onHide={handleClose} aria-labelledby='example-modal-sizes-title-lg'>
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-lg'>Create new contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formikR.handleSubmit}
              >
                <div className='scroll h-600px px-5 mt-5'>
                  {/* begin::Form group Firstname */}
                  <div className='fv-row mb-5'>
                    <label className='required form-label fw-bolder text-dark fs-6'>First name</label>
                    <input
                      placeholder='First name'
                      type='text'
                      autoComplete='off'
                      {...formikR.getFieldProps('firstName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formikR.touched.firstName && formikR.errors.firstName,
                        },
                        {
                          'is-valid': formikR.touched.firstName && !formikR.errors.firstName,
                        }
                      )}
                    />
                    {formikR.touched.firstName && formikR.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikR.errors.firstName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}
                  <div className='fv-row mb-5'>
                    {/* begin::Form group Lastname */}
                    <label className='required form-label fw-bolder text-dark fs-6'>Last name</label>
                    <input
                      placeholder='Last name'
                      type='text'
                      autoComplete='off'
                      {...formikR.getFieldProps('lastName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formikR.touched.lastName && formikR.errors.lastName,
                        },
                        {
                          'is-valid': formikR.touched.lastName && !formikR.errors.lastName,
                        }
                      )}
                    />
                    {formikR.touched.lastName && formikR.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikR.errors.lastName}</span>
                        </div>
                      </div>
                    )}
                    {/* end::Form group */}
                  </div>
                  <div className='fv-row mb-5'>
                    {/* begin::Form group Lastname */}
                    <label className='required form-label fw-bolder text-dark fs-6'>Phone</label>
                    <PhoneInput
                      preferredCountries={['us', 'ca', 'ma']}
                      inputStyle={{
                        width: '100%',
                        padding: '1.7rem',
                        paddingLeft: '4rem',
                        backgroundColor: '#f3f6f9',
                        borderColor: '#f3f6f9',
                      }}
                      country={'ca'}
                      onChange={(value) => formikR.setFieldValue('phoneNumber', value)}
                    />
                    {/* <input
                placeholder='Phone'
                type='text'
                autoComplete='off'
                {...formikR.getFieldProps('phoneNumber')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formikR.touched.phoneNumber && formikR.errors.phoneNumber,
                  },
                  {
                    'is-valid': formikR.touched.phoneNumber && !formikR.errors.phoneNumber,
                  }
                )}
              /> */}
                    {formikR.touched.phoneNumber && formikR.errors.phoneNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikR.errors.phoneNumber}</span>
                        </div>
                      </div>
                    )}
                    {/* end::Form group */}
                  </div>
                  <div className='fv-row mb-4 mt-4'>
                    {/* begin::Form group Lastname */}
                    <div className='mb-5'>
                      <label className='required form-label fw-bolder text-dark fs-6'>Role</label>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...formikR.getFieldProps('role')}
                          value='1'
                          id='flexRadioChecked'
                          onChange={(e) => {
                            setcheck(e.target.value)
                          }}
                        />

                        <label className='form-check-label'>Inspector</label>
                        <div className='ms-20'>
                          <input
                            className='form-check-input'
                            {...formikR.getFieldProps('role')}
                            type='radio'
                            value='0'
                            id='flexRadioChecked'
                            checked={check === '0' ? true : false}
                            onChange={(e) => {
                              setcheck(e.target.value)
                            }}
                          />
                          <label className='form-check-label'>Subcontractor</label>
                        </div>
                      </div>
                    </div>
                    {/* end::Form group */}
                  </div>
                  {check === '0' ? (
                    <>
                      <div className='fv-row mb-5'>
                        {/* begin::Form group Lastname */}
                        <label className='form-label fw-bolder text-dark fs-6'>Company website</label>
                        <input
                          placeholder='Company website'
                          type='text'
                          autoComplete='off'
                          {...formikR.getFieldProps('Site')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formikR.touched.Site && formikR.errors.Site,
                            },
                            {
                              'is-valid': formikR.touched.Site && !formikR.errors.Site,
                            }
                          )}
                        />

                        {/* end::Form group */}
                      </div>
                      <div className='fv-row mb-5'>
                        {/* begin::Form group Lastname */}
                        <label className=' required form-label fw-bolder text-dark fs-6'>Company name</label>
                        <input
                          placeholder='Company name'
                          type='text'
                          autoComplete='off'
                          {...formikR.getFieldProps('companyName')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                formikR.touched.companyName && formikR.errors.companyName,
                            },
                            {
                              'is-valid':
                                formikR.touched.companyName && !formikR.errors.companyName,
                            }
                          )}
                        />
                        {formikR.touched.companyName && formikR.errors.companyName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formikR.errors.companyName}</span>
                            </div>
                          </div>
                        )}
                        {/* end::Form group */}
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {/* begin::Form group Email */}
                  <div className='fv-row mb-5'>
                    <label className='required form-label fw-bolder text-dark fs-6'>Email</label>
                    <input
                      placeholder='Email'
                      type='text'
                      autoComplete='off'
                      {...formikR.getFieldProps('email')}
                      value={formikR.values.email}
                      className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formikR.touched.email && formikR.errors.email },
                        {
                          'is-valid': formikR.touched.email && !formikR.errors.email,
                        }
                      )}
                    />
                    {formikR.touched.email && formikR.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikR.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group Password */}
                  <div className='fv-row mb-5' data-kt-password-meter='true'>
                    <div className='mb-1'>
                      <label className='required form-label fw-bolder text-dark fs-6'>Password</label>
                      <div className='input-group input-group-solid mb-5'>
                        <input
                          type={isShowPassword ? 'text' : 'password'}
                          placeholder={'New Password'}
                          className={`form-control h-auto py-3 px-6`}
                          {...formikR.getFieldProps('password')}
                        />
                        <span
                          className='input-group-text align-self-center cursor-pointer'
                          onClick={(e) => setShowPassword(!isShowPassword)}
                        >
                          {!isShowPassword ? (
                            <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                          ) : (
                            <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                          )}
                        </span>
                      </div>
                      {formikR.touched.password && formikR.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formikR.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* end::Form group */}

                  {/* begin::Form group Confirm password */}
                  <div className='fv-row mb-5'>
                    <label className='required form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                    <div className='input-group input-group-solid mb-5'>
                      <input
                        type={isShowPassword2 ? 'text' : 'password'}
                        placeholder={'Confirm Password'}
                        className={`form-control h-auto py-3 px-6`}
                        {...formikR.getFieldProps('confirmPassword')}
                      />
                      <span
                        className='input-group-text align-self-center cursor-pointer'
                        onClick={(e) => setShowPassword2(!isShowPassword2)}
                      >
                        {!isShowPassword2 ? (
                          <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                        ) : (
                          <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                        )}
                      </span>
                    </div>
                    {formikR.touched.confirmPassword && formikR.errors.confirmPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikR.errors.confirmPassword}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}
                </div>

                {/* begin::Form group */}
                <div className='d-grid gap-2 d-md-flex justify-content-md-end mt-10'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary w-20 me-1'
                    disabled={formikR.isSubmitting || !formikR.isValid}
                  >
                    {!loading && <span className='indicator-label'>Create</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    type='reset'
                    onClick={handleClose}
                    className='btn btn-light'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                </div>
                {/* end::Form group */}
              </form>
            </Modal.Body>
            {/* <Modal.Footer> */}
            {/* <div className='text-centre'> */}
            {/* <button
              type='reset'
              onClick={handleClose}
              className='btn btn-light'
              data-kt-users-modal-action='cancel'
            >
              Discard
            </button> */}
            {/* <button type='submit' className='btn btn-primary ms-10' >
              {!loading && <span className='indicator-label'>Create</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
               
                </span>
              )}
            </button>
          </div> */}
            {/* </Modal.Footer> */}
          </Modal>
        </div>
        <div className='text-center pt-15 w-100'>
          <button
            type='button'
            className='btn btn-primary w-100'
            disabled={DetailCheck.length === 0 || loadingteam === true ? true : false}
            onClick={() => {
              setloadingteam(true)
              createteams()
            }}
          >
            <span className='indicator-label'>{'Add to project team'}</span>
            {loadingteam === true ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              : ""}
          </button>
        </div>
      </form>

      <Import
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        fields={fields}
        allowInvalidSubmit={false}
      />
      {importing && <Loading />}
    </>
  )
}

export { TeamsEditModalForm }
