import { FC, useEffect } from 'react'
import { ActivitiesModalHeader } from './ActivitiesModalHeader'
import { TableActivites } from './TableActivites'
import { ID } from '../../../../_metronic/helpers'

type Props = {
  activitiesId: ID
  setActivitiesId: any
  url: string
}
const ActivitiesModal: FC<Props> = ({activitiesId,setActivitiesId, url}) => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <ActivitiesModalHeader setActivitiesId={setActivitiesId} />
            <div className='modal-body scroll-y mx-5 mx-xl-5 my-7'>
              <TableActivites id={activitiesId} url={url} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { ActivitiesModal }
