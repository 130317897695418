import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { User, UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URLL
const TASK_URL = `${API_URL}/Tasks`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${TASK_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getTaskViewModel = (): Promise<any> => {
  return axios
    .get(`${TASK_URL}/GetViewModel`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getTags = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${TASK_URL}/Tags`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getProjects = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${TASK_URL}/Projects`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getSubContractors = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${TASK_URL}/SubContractorInTeam`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getProjectsLocations = (id: any): Promise<any> => {
  return axios
    .get(`${API_URL}/Projects/Locations/${id}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${TASK_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(TASK_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${TASK_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${TASK_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${TASK_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const handleRemoveImage = (Id: ID): Promise<void> => {
  return axios.delete(`${TASK_URL}/Attachments/${Id}`).then(() => { })
}

const handleDuplicate = (Id: any): Promise<User | undefined> => {
  return axios
    .post(`${TASK_URL}/Duplicate/${Id}`)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: any) => response.data)
}

const postAttachments = (formData: FormData, id:any): Promise<any> => {
  return axios
    .post(`${TASK_URL}/Attachments/${id}`, formData)
    .then((response: AxiosResponse<any>) => response.data)
}

const getLocationPlan = (id: ID): Promise<any | undefined> => {
  return axios
    .get(`${API_URL}/Projects/Locations/Plan/${id}`)
    .then((response: AxiosResponse<any>) => response)
    .then((response: any) => response)
}

export { getUsers, deleteUser,getSubContractors, deleteSelectedUsers,handleDuplicate,getLocationPlan, getUserById,handleRemoveImage, createUser, updateUser,getTaskViewModel,postAttachments,getProjects , getTags,getProjectsLocations}
