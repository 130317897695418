/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import clsx from 'clsx'
import {useFormik} from 'formik'
// import * as Yup from 'yup'
import {CountryStateGlobal} from '../CountryState'
import {
  ModelStateMessage,
  QueryFetchPost,
  TimeConversion,
  convertToGMT,
} from '../../../../modules/global'
import Swal from 'sweetalert2'
import {useLocation} from 'react-router-dom'
import axios from 'axios'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Select, {ActionMeta, SingleValue} from 'react-select'
import moment from 'moment'
const AUTH_LOCAL_STORAGE_KEY = 'User'

// const registrationSchema = Yup.object().shape({
//   name: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('name is required'),
//   address1: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Address is required'),
//   city: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('city is required'),
//   description: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('description is required'),
// })
interface Model {
  description: string | undefined
  name: string | undefined
  address1: string | undefined
  address2: string | undefined
  city: string | undefined
  state: string | undefined
  zipCode: string | undefined
  country: string | undefined
}
type OptionType = {value: string; label: string}

export const initialUser: Model = {
  description: '',
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
}
const BASE_URL = process.env.REACT_APP_BASE_URL
const Step1 = (props: any) => {
  const [loading, setLoading] = useState(false)
  const [pictur, setpictur] = useState('')
  const [PName, setPName] = useState('')
  const [Paddress1, setaddress1] = useState('')
  const [Paddress2, setaddress2] = useState('')
  const [city, setcity] = useState('')
  const [Pdescription, setdescription] = useState('')
  const [PzipCode, setzipCode] = useState('')
  const [countryy, setcountryy] = useState('')
  const [date, setdate] = useState<string | null>()
  const [statee, setstatee] = useState('')
  const [Company, setCompany] = useState<any>()
  const user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')

  // const [project, setproject] = useState<Model>()
  const [Companies, setCompanies] = useState<OptionType[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [image, setImage] = useState<string | undefined>()

  const location = useLocation()
  const removePic = () => {
    setImage(undefined)
  }
  //   const handleContryChange = (selectedOption: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
  //     setCompany(selectedOption?.value);
  // };
  const handleGetCompanies = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/Projects/GetViewModel`,
      data: undefined,
      method: 'GET',
      key: 'handleGetCompanies',
      signal: undefined,
      isFormData: false,
    })
      .then((data) => {
        if (data[0] === 200) {
          const CompaniesList = data[1]?.data?.inspectionCompanies.map((country: any) => {
            return {value: country?.inspectionCompanyId, label: country?.name}
          })
          setCompanies(CompaniesList || [])
          data[1]?.data?.inspectionCompanies?.map((e: any) => {
            console.log(e)
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedImage = event.target.files?.[0]
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
    if (selectedImage) {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result?.toString())
      }
      reader.readAsDataURL(selectedImage)
    }
  }
  const handleChangeProfilePic = async (idd: number) => {
    if (!selectedFile) {
      return
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    if (idd) {
      await axios.post(`${BASE_URL}/api/Projects/ProjectPicture/${idd}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } else {
      await axios.post(`${BASE_URL}/api/Projects/ProjectPicture/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    }
  }
  function extractIdFromUrl(url: string) {
    const regex = /id=(\d+)/
    const match = url.match(regex)
    if (match) {
      return parseInt(match[1])
    }
    return null
  }
  function extractEditFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('edit') === 'true'
  }
  function extractDetailsFromUrl(url: string) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.get('details') === 'true'
  }
  const isDetails = window.location.href && extractDetailsFromUrl(window.location.href)
  const isEdit = window.location.href && extractEditFromUrl(window.location.href)
  const id = window.location.href && extractIdFromUrl(window.location.href)
  const ChangePassword = async (values: any) => {
    console.log(typeof Company)
    console.log(Company)
    var dataa: any
    if (user.roles[0] === 'Building Owner') {
      dataa = {
        description: Pdescription,
        name: PName,
        address1: Paddress1,
        address2: Paddress2,
        city: city,
        state: statee,
        zipCode: PzipCode,
        country: countryy,
        inspectionCompanyId: Company,
        dueDate: date,
      }
    } else {
      dataa = {
        description: Pdescription,
        name: PName,
        address1: Paddress1,
        address2: Paddress2,
        city: city,
        state: statee,
        zipCode: PzipCode,
        country: countryy,
        dueDate: date,
      }
    }
    setLoading(true)
    var myLink: string = ''
    if (id === null) {
      myLink = `${BASE_URL}/api/Projects`
    } else {
      myLink = `${BASE_URL}/api/Projects/Base/${id}`
    }
    console.log('country', countryy, 'state', statee)
    console.log(isEdit, id)
    await QueryFetchPost({
      link: myLink,
      data: dataa,
      method: `${id === null ? 'POST' : 'PUT'}`,
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        Swal.fire({
          title: 'Success',
          text: `Project ${id === null ? 'created' : 'updated'} successfully`,
          icon: 'success',
          confirmButtonText: 'Ok',
        })

        if (id === null && isEdit === false) {
          const searchParams = new URLSearchParams(location.search)
          searchParams.set('id', response[1])
          const newUrl = `${location.pathname}/${searchParams.toString()}`
          window.history.replaceState(null, '', newUrl)
        }

        setLoading(false)
        handleChangeProfilePic(response[1])
        props.setId(response[1])
      } else {
        ModelStateMessage(response)
        setLoading(false)
      }
    })
  }
  const [companyas, setcompanyas] = useState('')

  async function getProject() {
    try {
      const response = await axios.get(`${BASE_URL}/api/Projects/${id}`)
      if (response.status === 200) {
        props.setproject(response.data)
        console.log(response.data.created)
        setPName(response.data.name)
        setdescription(response.data.description)
        setaddress1(response.data.address1)
        setaddress2(response.data.address2)
        setcity(response.data.city)
        setzipCode(response.data.zipCode)
        setpictur(response.data.picture)
        setcountryy(response.data.country)
        setstatee(response.data.state)
        setCompany(response.data.inspectionCompanyId)
        console.log(response.data.created)
        setdate(response.data.dueDate)

        Companies.map((e) => {
          if (e.value === response.data.inspectionCompanyId) {
            setcompanyas(e.label)
          }
        })
      }
    } catch (error) {}
  }
  useEffect(() => {
    handleGetCompanies()
    getProject()
  }, [])
  useEffect(() => {
    getProject()
  }, [props.Step])

  const [initialValues] = useState<Model>({
    ...props.project,
    description: props?.project?.description,
    name: props?.project?.name,
    address1: props?.project?.address1,
    address2: props?.project?.address2,
    city: props?.project?.city,
    state: props?.project?.state,
    zipCode: props?.project?.zipCode,
    country: props?.project?.country,
  })

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      ChangePassword(values)
    },
  })
  return (
    <div className='w-100'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* {isEdit === true ? (
          <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
            <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary'>
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='indicator-label'>Save changes</span>
                {loading && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </span>
            </button>
          </div>
        ) : (
          ''
        )} */}
        {id ? (
          <>
            {isDetails === false ? (
              <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary'
                  disabled={PName && date ? false : true}
                >
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='indicator-label'>Save changes</span>
                    {loading && (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    )}
                  </span>
                </button>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        <div className='mb-6' style={{display:'inline-grid'}}>
          <label className='fw-bold fs-5 mb-6'>Project picture</label>
          <div
            className='image-input image-input-outline'
            data-kt-image-input='true'
            style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
          >
            {/*begin::Image preview wrapper*/}
            {image ? (
              <>
                <div
                  className='image-input-wrapper w-125px h-125px rounded'
                  style={{backgroundImage: `url(${image})`}}
                />
              </>
            ) : (
              <>
                {pictur !== '' && pictur !== null ? (
                  <>
                    <img
                      className='image-input-wrapper w-125px h-125px rounded'
                      src={toAbsoluteUrl(`${pictur}`)}
                      alt=''
                    />
                  </>
                ) : (
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: 'url(/assets/media/avatars/300-1.jpg)'}}
                  />
                )}
              </>
            )}

            {/*end::Image preview wrapper*/}
            {/*begin::Edit button*/}
            {isDetails === true ? (
              ''
            ) : (
              <label
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Change avatar'
              >
                <i className='ki-duotone ki-pencil fs-6'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
                {/*begin::Inputs*/}
                <input
                  type='file'
                  id='profile_avatar'
                  name='profile_avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={handleImageUpload}
                />
                <input type='hidden' name='avatar_remove' />
                {/*end::Inputs*/}
              </label>
            )}

            {/*end::Edit button*/}
            {/*begin::Cancel button*/}
            <span
              className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              title='Cancel avatar'
            >
              <i className='ki-outline ki-cross fs-3' />
            </span>
            {/*end::Cancel button*/}
            {/*begin::Remove button*/}
            {isDetails === true ? (
              ''
            ) : (
              <span
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Remove avatar'
                onClick={removePic}
              >
                <i className='ki-outline ki-cross fs-3' />
              </span>
            )}

            {/*end::Remove button*/}
          </div>
        </div>
        {isDetails === true ? (
          <div className='table-responsive custom-scrollbar'>
            {/*begin::Table*/}
            <div className='d-flex align-items-start flex-column my-8'>
              {/* Project Name */}
              {PName && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Project Name: </span>
                  <span className='fw-normal mt-3 '>{PName}</span>
                </div>
              )}

              {/* Project Description */}
              {Pdescription && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Project Description: </span>
                  <span className='fw-normal mt-3 '>{Pdescription}</span>
                </div>
              )}

              {/* Inspection Companies (only for Building Owner) */}
              {user.roles[0] === 'Building Owner' && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Inspection Companies: </span>
                  <span className='fw-normal mt-3 '>{companyas}</span>
                </div>
              )}

              {/* Due Date */}
              {date && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Due Date: </span>
                  <span className='fw-normal mt-3 '>{moment(date).format('MM-DD-YYYY')}</span>
                </div>
              )}

              {/* Address 1 */}
              {Paddress1 && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Address 1: </span>
                  <span className='fw-normal mt-3 '>{Paddress1}</span>
                </div>
              )}

              {/* Address 2 */}
              {Paddress2 && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Address 2: </span>
                  <span className='fw-normal mt-3 '>{Paddress2}</span>
                </div>
              )}

              {/* City */}
              {city && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>City: </span>
                  <span className='fw-normal mt-3 '>{city}</span>
                </div>
              )}

              {/* State */}
              {statee && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>State: </span>
                  <span className='fw-normal mt-3 '>{statee}</span>
                </div>
              )}

              {/* Zip Code */}
              {PzipCode && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Zip Code: </span>
                  <span className='fw-normal mt-3 '>{PzipCode}</span>
                </div>
              )}

              {/* Country */}
              {countryy && (
                <div className='d-flex flex-column mb-6'>
                  <span className='fs-4 fw-bold'>Country: </span>
                  <span className='fw-normal mt-3 '>{countryy}</span>
                </div>
              )}
            </div>
            {/*end::Table*/}
          </div>
        ) : (
          <>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Project Name</label>
              <span className='text-danger'> *</span>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Project Name'
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                disabled={isDetails ? true : false}
                value={PName}
                onChange={(e) => {
                  setPName(e.target.value)
                }}
              />

              {/* end::Input */}
            </div>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Project Description</label> {/* end::Label */}
              {/* begin::Input */}
              <textarea
                placeholder='Project Description'
                {...formik.getFieldProps('description')}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                disabled={isDetails ? true : false}
                value={Pdescription}
                onChange={(e) => {
                  setdescription(e.target.value)
                }}
              />
              {/* end::Input */}
            </div>

            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
            {user.roles[0] === 'Building Owner' ? (
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Inspection Companies</label>
                <span className='text-danger'> *</span>
                {/* end::Label */}

                {/* begin::Input */}
                <Select
                  value={Companies.find((option) => option.value === Company)}
                  options={Companies}
                  onChange={(selectedOption: any) => {
                    if (selectedOption) {
                      console.log(typeof selectedOption.value)
                      setCompany(selectedOption.value)
                    }
                  }}
                  isDisabled={isDetails ? true : false}
                  isClearable
                  isSearchable
                />

                {/* end::Input */}
              </div>
            ) : (
              ''
            )}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Due Date</label>
           
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Due Date'
                type='date'
                name='dueDate'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={date?.split('T')[0]}
                onChange={(e) => {
                  setdate(moment(e.target.value).format('YYYY-MM-DD'))
                }}
              />

              {/* end::Input */}
            </div>

            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Address 1</label> {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder='Address 1'
                type='text'
                {...formik.getFieldProps('address1')}
                value={Paddress1}
                onChange={(e) => {
                  setaddress1(e.target.value)
                }}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                disabled={isDetails ? true : false}
              />
              {formik.touched.address1 && formik.errors.address1 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.address1}</span>
                  </div>
                </div>
              )}
              <label className='fw-bold fs-6 mb-2'>Address 2</label> {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder='Address 2'
                type='text'
                {...formik.getFieldProps('address1')}
                value={Paddress2}
                onChange={(e) => {
                  setaddress2(e.target.value)
                }}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                disabled={isDetails ? true : false}
              />
              {/* end::Input */}
              <div className='row mt-5'>
                <div className='col-6'>
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>City</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='City'
                      type='text'
                      {...formik.getFieldProps('city')}
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value)
                      }}
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      disabled={isDetails ? true : false}
                    />
                    {/* end::Input */}
                  </div>
                </div>
                <div className='col-6'>
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                    <input
                      placeholder='Zip Code'
                      type='text'
                      {...formik.getFieldProps('zipCode')}
                      value={PzipCode}
                      onChange={(e) => {
                        setzipCode(e.target.value)
                      }}
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      disabled={isDetails ? true : false}
                    />
                    {/* end::Input */}
                  </div>
                </div>
              </div>
              <div className='row mt-5'>
                <CountryStateGlobal
                  setstatee={setstatee}
                  statee={statee}
                  setcountryy={setcountryy}
                  countryy={countryy}
                />
              </div>
            </div>
          </>
        )}

        <div className='d-grid gap-2 d-md-flex justify-content-md-end mt-5'>
          {isEdit === false ? (
            <>
              {id === null ? (
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary'
                  disabled={PName  ? false : true}
                >
                  {!loading && <span className='indicator-label'>Create</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='indicator-label'>Save changes</span>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              ) : (
                <button
                  type='button'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary'
                  onClick={() => props.setSteps(props.Step + 1)}
                >
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='indicator-label'>Continue</span>
                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    {loading && (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    )}
                  </span>
                </button>
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  )
}

export {Step1}
