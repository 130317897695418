import {useListView} from '../../core/ListViewProvider'
import { TagListToolbar } from './TagListToolbar'
import { TagsListGrouping } from './TagsListGrouping'
import { TagsListSearchComponent } from './TagsListSearchComponent'

const TagsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TagsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TagsListGrouping /> : <TagListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TagsListHeader}
