// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
// import {TimeConversion} from '../../../../../global'
const cuurentUser: any = localStorage.getItem('User')
const role: any = JSON.parse(cuurentUser)?.roles
type Props = {
  row: Row<User>
}
console.log(localStorage.getItem('TimeZone'))

const CustomRow: FC<Props> = ({ row }) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell, index) => {

      return (
        <>{(role[0] === 'Contractor' || role[0] === 'Contractor Admin') && (cell.column.id === 'selection') ? "" : <td
          {...cell.getCellProps()}
          className={
            clsx({ 'text-end min-w-100px ': cell.column.id === 'actions' }) +
            `${index === 2 ? 'link-primary text-primary cursor-pointer' : ''}`
          }
          id={index === 2 ? 'modal1_toggle' : undefined}
        >
          {/* {cell?.column?.id === 'dueDate' ? (
          <TimeConversion gmtDate={cell?.row?.original?.dueDate} timeZone={localStorage.getItem('TimeZone')} />
        ) : (
          cell.render('Cell')
        )} */}
          {cell.render('Cell')}

        </td>}</>

      )
    })}
  </tr>
)

export { CustomRow }
