import axios from 'axios'
import { BillingModal } from './billing-edit-modal/BillingModal'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'
import { BillingCardModal } from './card-edit-modal/BillingCardModal'
import { AddressModal } from './address-edit-modal/AddressModal'
import 'react-loading-skeleton/dist/skeleton.css'
import BillingTable from './BillingTable'
import PaymentMethods from './paymentMethods'
import BillingAdrress from './billingAdrress'
import BillingSummaryDetails from './billingSummaryDetails'
import Skeleton from 'react-loading-skeleton'

const API_URL = process.env.REACT_APP_API_URLL
const getBillingDetails = (): Promise<any> => {
    return axios.get(`${API_URL}/Billing/Get`)
}

export interface InitAdrressValues {
    address1: string,
    address2: string,
    zipCode: string,
    city: string,
    state: string,
    country: string,
    countryId: null | number
}

export function BillingWraper() {
    const [isOpen, setIsOpen] = useState(false)
    const [isCardOpen, setIsCardOpen] = useState(false)
    const [isAddressOpen, setIsAddressOpen] = useState(false)
    const [initialValues, setInitialValues] = useState<InitAdrressValues>(
        {
            address1: '',
            address2: '',
            zipCode: '',
            city: '',
            state: '',
            country: '',
            countryId: null
        }
    )

    const { data: details, refetch, isLoading } = useQuery('BillingDetails', getBillingDetails, {
        refetchOnWindowFocus: false,
    })


    useEffect(() => {
        if (details?.data?.subscription) {
            setInitialValues({
                address1: details?.data?.subscription?.addressLine1,
                address2: details?.data?.subscription?.addressLine2,
                zipCode: details?.data?.subscription?.addressZip,
                city: details?.data?.subscription?.addressCity,
                state: details?.data?.subscription?.addressState,
                country: details?.data?.subscription?.addressCountry,
                countryId: details?.data?.subscription?.countryId
            })
        }
    }, [details?.data?.subscription])


    const handleCancelSubscription = async () => {
        try {
            await axios.delete(`${API_URL}/Billing/CancelSubscription`).then((res) => {
                refetch()
                Swal.fire({
                    text: `Subscription has been cancelled`,
                    icon: 'success',
                    showConfirmButton: true,
                    timer: 2000,
                    width: '25rem',
                })
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {isLoading && <Skeleton count={1} height={120} style={{ marginBottom: '23px' }} />}
            {isLoading && <Skeleton count={1} height={390}  />}
            {details && <BillingSummaryDetails data={details?.data} cancelSubscription={handleCancelSubscription} setIsOpen={setIsOpen} />}
            {details && details?.data?.isSubscribed && <div className='row'>
                <div className='col-xl-6 col-md-12 mb-10'>
                    <PaymentMethods setIsOpen={setIsCardOpen} data={details?.data} />
                </div>
                <div className='col-xl-6 col-md-12 mb-10' >
                    <BillingAdrress setIsOpen={setIsAddressOpen} data={details?.data}  />
                </div>
            </div>}
            {details && <BillingTable data={details?.data?.invoices} />}
            {isOpen && <BillingModal setIsOpen={setIsOpen} refetch={refetch} />}
            {isCardOpen && <BillingCardModal setIsOpen={setIsCardOpen} />}
            {isAddressOpen && <AddressModal setIsOpen={setIsAddressOpen} initialValues={initialValues} />}
        </>
    )
}
