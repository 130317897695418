import {FC, useState} from 'react'
import {Form, Formik} from 'formik'
import {BillingPlanForm} from '../BillingPlanForm'
import {BillingAddressForm} from '../BillingAddressForm'
import {BillingSummary} from '../BillingSummary'

type Props = {
  formData: any
  setFormData: (formData: any) => void
  setIsOpen: (isOpen: boolean) => void
  discount: any
  setDiscount: (discount: any) => void
  currentSelectedPlan: any
  setCurrentSelectedPlan: (currentSelectedPlan: any) => void
  refetch: () => void
  sameAddress: boolean
  setSameAddress: (sameAddress: boolean) => void
  cardToken: string
  setCrdToken: (cardToken: string) => void
  cardNumber: any
  setCardNumber: (cardNumber: any) => void
}
const BillingModalForm: FC<Props> = ({
  formData,
  setFormData,
  setIsOpen,
  refetch,
  discount,
  setDiscount,
  currentSelectedPlan,
  setCurrentSelectedPlan,
  sameAddress,
  setSameAddress,
  cardToken,
  setCrdToken,
  cardNumber,
  setCardNumber,
}) => {
  const [currentStep, setCurrentStep] = useState(1)

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }
  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-200px w-xxl-300px me-2'>
        {/* begin::Wrapper*/}
        <div className='card-body'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div
              className={`stepper-item ${currentStep === 1 && 'current'}`}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Plan details</h3>

                  <div className='stepper-desc fw-semibold'>Your business related info</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}
            {/* 
                        <div className={`stepper-item ${currentStep === 2 && 'current'}`} data-kt-stepper-element='nav'>
                            <div className='stepper-wrapper'>
                                <div className='stepper-icon w-40px h-40px'>
                                    <i className='stepper-check fas fa-check'></i>
                                    <span className='stepper-number'>2</span>
                                </div>
                                <div className='stepper-label'>
                                    <h3 className='stepper-title'>Payment method</h3>
                                    <div className='stepper-desc fw-semibold'>Set your payment method</div>
                                </div>
                            </div>
                            <div className='stepper-line h-40px'></div>
                        </div> */}
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div
              className={`stepper-item ${currentStep === 2 && 'current'}`}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Billing details</h3>
                  <div className='stepper-desc fw-semibold'>Set your billing</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div
              className={`stepper-item ${currentStep === 3 && 'current'}`}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Summary</h3>
                  <div className='stepper-desc fw-semibold'>Review your information</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 4*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik initialValues={{}} onSubmit={() => {}}>
          {() => (
            <Form className='w-100 w-xl-700px px-9'>
              {currentStep === 1 && (
                <div className='current'>
                  <BillingPlanForm
                    nextStep={nextStep}
                    formData={formData}
                    setFormData={setFormData}
                    discount={discount}
                    setDiscount={setDiscount}
                    setCurrentSelectedPlan={setCurrentSelectedPlan}
                    currentSelectedPlan={currentSelectedPlan}
                  />
                </div>
              )}
              {/* 
                            {currentStep === 2 && <div className='current'>
                                <BillingMethodForm nextStep={nextStep} prevStep={prevStep} setFormData={setFormData} formData={formData} />
                            </div>} */}

              {currentStep === 2 && (
                <div className='current'>
                  <BillingAddressForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formData={formData}
                    setFormData={setFormData}
                    sameAddress={sameAddress}
                    setSameAddress={setSameAddress}
                    setCrdToken={setCrdToken}
                    setCardNumber={setCardNumber}
                  />
                </div>
              )}

              {currentStep === 3 && (
                <div className='current'>
                  <BillingSummary
                    prevStep={prevStep}
                    formData={formData}
                    setIsOpen={setIsOpen}
                    refetch={refetch}
                    cardToken={cardToken}
                    cardNumber={cardNumber}
                    discount={discount}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {BillingModalForm}
